import React from 'react';
import { FormattedMessage } from 'react-intl';
import NavLink from '../../NavLink';
import { AUTHORIZED_MENU_ITEMS } from '../../../siteSettings/siteNavigation';

// type Props = {
//   onLogout: () => void;
// };

export const AuthorizedMenu = ({ onLogout }) => {
  return (
    <>
      {AUTHORIZED_MENU_ITEMS.map((item, idx) => (
        <NavLink
          key={idx}
          className='menu-item'
          href={item.href}
          label={item.defaultMessage}
          intlId={item.id}
        />
      ))}
      <div className='menu-item' onClick={onLogout}>
        <a>
          <span>
            <FormattedMessage id='nav.logout' defaultMessage='Logout' />
          </span>
        </a>
      </div>
    </>
  );
};
