import React from 'react';
export const LaptopBags = ({
  color = 'currentColor',
  width = '18px',
  height = '18px',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 18 18'
    >
      <g
        data-name='Group 6'
        fill={color}
        stroke='#fff'
        strokeMiterlimit='10'
        strokeWidth='.091'
      >
        <path
          data-name='Path 22'
          d='M6.224 1.741a.408.408 0 01-.292-.175l-.013-.018v-.022c-.08-.612.818-.747.856-.753l.4-.057-.334.222h0a.807.807 0 00-.128.338c-.105.42-.375.465-.489.465zm-.13-.263a.213.213 0 00.13.081c.106 0 .244-.056.305-.323a1.686 1.686 0 01.07-.237c-.214.064-.523.205-.505.479z'
        />
        <path
          data-name='Path 23'
          d='M9 6.496H.046v6.8a.833.833 0 00.833.833h16.243a.833.833 0 00.833-.833v-6.8z'
        />
        <path
          data-name='Path 24'
          d='M17.44 2.135H.561a.515.515 0 00-.515.515v6.285a.833.833 0 00.833.833h16.243a.833.833 0 00.833-.833V2.65a.515.515 0 00-.515-.515z'
        />
        <path
          data-name='Path 25'
          d='M11.776 1.741c-.113 0-.387-.045-.482-.464a.734.734 0 00-.134-.342l-.334-.222.4.061c.038.006.936.141.856.753v.022l-.013.018a.408.408 0 01-.293.174zM11.4.999a1.682 1.682 0 01.071.238c.061.267.2.323.305.323a.216.216 0 00.13-.081c.019-.276-.291-.416-.506-.48z'
        />
        <path
          data-name='Path 26'
          d='M11.295.752A3.333 3.333 0 009 .045a3.333 3.333 0 00-2.295.707.136.136 0 00.158.222A3.036 3.036 0 018.999.318a3.037 3.037 0 012.136.656.136.136 0 10.158-.222z'
        />
        <path data-name='Path 27' d='M9 9.419h-.958v.751h1.917v-.751z' />
        <path
          data-name='Path 28'
          d='M8.698 8.214h0a.9.9 0 00-.657 1.24h1.917a.9.9 0 00-.657-1.24h0a1.656 1.656 0 00-.6 0z'
        />
        <path data-name='Path 29' d='M9 10.17h-.958v1.009h1.917V10.17z' />
        <path data-name='Path 30' d='M9 10.473h-.807v.706h1.615v-.706z' />
        <path
          data-name='Path 31'
          d='M4.725 2.135h1.136a1.287 1.287 0 00.648-.83.118.118 0 00-.117-.139h0a.116.116 0 00-.095.048 1.873 1.873 0 01-1.38.721.118.118 0 00-.088.05z'
        />
        <path
          data-name='Path 32'
          d='M13.275 2.135H12.14a1.287 1.287 0 01-.648-.83.118.118 0 01.116-.139h0a.116.116 0 01.095.048 1.873 1.873 0 001.38.721.118.118 0 01.088.05z'
        />
      </g>
    </svg>
  );
};
