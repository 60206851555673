import React from 'react';
import { useLocation } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { LayoutWrapper } from './layout.style';
import { useAppState } from '../../contexts/app/app.provider';
import { AuthContext } from '../../contexts/auth/auth.context';
import Header from '../Header';
import MobileHeader from '../Header/MobileHeader';
import { isCategoryPage } from './isHomePage';

const AppLayout = (props) => {
    const isSticky = useAppState('isSticky');
    const { pathname } = useLocation();
    // const pathname = '/';
    // console.log('pathname:', pathname)
    const query = { type: 'category' };
    const type = (pathname === '/restaurant' ? 'restaurant' : query.type);
    ;
    // const isHomePage = isCategoryPage(type);
    const isHomePage = true;
    return (
        <>
            <LayoutWrapper className={`layoutWrapper ${props.className}`}>
                <Sticky enabled={isSticky} innerZ={1001}>
                    <MobileHeader
                        className={`${isSticky ? 'sticky' : 'unSticky'} ${isHomePage ? 'home' : ''
                            } desktop`}
                    />

                    <Header
                        className={`${isSticky && isHomePage ? 'sticky' : 'unSticky'} ${isHomePage ? 'home' : ''
                            }`}
                    />
                </Sticky>
                {props.children}
            </LayoutWrapper>
        </>
    )
}

export default AppLayout;