import React from 'react';
export const ShoulderBags = ({
  color = 'currentColor',
  width = '18px',
  height = '18px',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 18 18'
    >
      <g
        data-name='Group 3'
        transform='translate(-100.548 -66.187)'
        fill={color}
        stroke='#fff'
        strokeMiterlimit='10'
        strokeWidth='.076'
      >
        <path
          data-name='Path 6'
          d='M105.083 69.531l-.917.243-.848-3.195a.149.149 0 01.068-.166h0a1.343 1.343 0 01.708-.188h0a.149.149 0 01.141.111z'
        />
        <path
          data-name='Path 7'
          d='M104.167 69.774l-.917.243-.848-3.195a.149.149 0 01.068-.166h0a1.342 1.342 0 01.708-.188h0a.149.149 0 01.141.111z'
        />
        <path
          data-name='Path 8'
          d='M106.5 69.531l.917.243.848-3.195a.149.149 0 00-.068-.166h0a1.343 1.343 0 00-.708-.188h0a.149.149 0 00-.141.111z'
        />
        <path
          data-name='Path 9'
          d='M107.421 69.774l.917.243.848-3.195a.149.149 0 00-.068-.166h0a1.342 1.342 0 00-.708-.188h0a.149.149 0 00-.141.111z'
        />
        <path
          data-name='Path 10'
          d='M110.987 80.15l-.645-7.545a3.781 3.781 0 00-3.768-3.459h-.78v1.23h0v-1.23h-.78a3.781 3.781 0 00-3.768 3.459l-.646 7.545a3.407 3.407 0 003.395 3.7h3.598a3.407 3.407 0 003.395-3.7z'
        />
        <path
          data-name='Path 11'
          d='M108.494 69.668c-1.336-.669-1.426-1.652-2.7-1.654h0c-1.272 0-1.362.985-2.7 1.654l.294-.046c1.191-.519 1.271-1.282 2.406-1.283s1.215.764 2.406 1.283z'
        />
        <path
          data-name='Path 12'
          d='M105.795 77.868l.132-.006 4.385-.192.239 2.792h0a16.8 16.8 0 01-4.579.636h-.177'
        />
        <path
          data-name='Path 13'
          d='M105.788 77.868l-.132-.006-4.385-.192-.239 2.792h0a16.8 16.8 0 004.579.636h.177'
        />
        <path
          data-name='Path 14'
          d='M110.625 80.455l-.591-6.916a3.54 3.54 0 00-3.527-3.239h-1.427a3.54 3.54 0 00-3.527 3.238l-.591 6.916a3.2 3.2 0 001.393 2.92l.083-.123a3.056 3.056 0 01-1.328-2.785l.591-6.916a3.372 3.372 0 013.38-3.1h1.426a3.372 3.372 0 013.379 3.1l.591 6.916a3.056 3.056 0 01-1.328 2.785l.083.123a3.2 3.2 0 001.392-2.921z'
        />
        <path
          data-name='Path 15'
          d='M105.792 84.149v-3.008h0a25.049 25.049 0 01-4.756-.676l-.447-.039A3.568 3.568 0 00104 84.149z'
        />
        <path
          data-name='Path 16'
          d='M105.795 81.141v3.008h1.8A3.568 3.568 0 00111 80.426l-.447.039a25.083 25.083 0 01-4.758.676z'
        />
        <path
          data-name='Path 17'
          d='M105.792 81.083v2.767h1.647a3.123 3.123 0 003.112-3.389 27.2 27.2 0 01-4.757.622z'
        />
        <path
          data-name='Path 18'
          d='M105.795 83.85v-2.765h0a27.157 27.157 0 01-4.759-.622h0a3.123 3.123 0 003.112 3.389z'
        />
        <rect
          data-name='Rectangle 34'
          width='2.177'
          height='1.086'
          rx='.281'
          transform='rotate(-3.21 1460.138 -1873.834)'
        />
        <path
          data-name='Path 19'
          d='M103.251 80.12h0a.158.158 0 01-.158-.158v-6.679a.158.158 0 01.158-.158h0a.158.158 0 01.158.158v6.679a.158.158 0 01-.158.158z'
        />
        <path data-name='Rectangle 35' d='M103.093 74.874h.316v1.036h-.316z' />
      </g>
    </svg>
  );
};
