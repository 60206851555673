export const products = [
    {
        id: 8,
        title: 'lime',
        slug: 'lime',
        unit: '12 pc(s)',
        price: 1.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The lemon/lime, Citrus limon Osbeck, is a species of small evergreen tree in the flowering plant family Rutaceae, native to South Asia, primarily North eastern India.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/GreenLimes_jrodle.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/GreenLimes_jrodle.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/GreenLimes_jrodle.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/GreenLimes_jrodle.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/GreenLimes_jrodle.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 9,
        title: 'Lemon',
        slug: 'lemon',
        unit: '12 pc(s)',
        price: 1.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The lemon/lime, Citrus limon Osbeck, is a species of small evergreen tree in the flowering plant family Rutaceae, native to South Asia, primarily North eastern India.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/Yellow_Limes_y0lbyo.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Yellow_Limes_y0lbyo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Yellow_Limes_y0lbyo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Yellow_Limes_y0lbyo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Yellow_Limes_y0lbyo.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 10,
        title: 'Cherry',
        slug: 'cherry',
        unit: '0.5 lb',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A cherry is the fruit of many plants of the genus Prunus, and is a fleshy drupe. Commercial cherries are obtained from cultivars of several species, such as the sweet Prunus avium and the sour Prunus cerasus',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614569/pickbazar/grocery/RedCherries_zylnoo.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/RedCherries_zylnoo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/RedCherries_zylnoo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/RedCherries_zylnoo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/RedCherries_zylnoo.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 2,
                title: 'Fruits',
                slug: 'fruits',
            },
        ],
    },
    {
        id: 11,
        title: 'Celery Stick',
        slug: 'celerystick',
        unit: '1 lb',
        price: 0.7,
        salePrice: 0,
        discountInPercent: 0,
        description: 'celery stick - celery stalks cut into small sticks. crudites - raw vegetables cut into bite-sized strips and served with a dip. celery - stalks eaten raw or cooked or used as seasoning.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/CelerySticks_ulljfz.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/CelerySticks_ulljfz.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/CelerySticks_ulljfz.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/CelerySticks_ulljfz.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/CelerySticks_ulljfz.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 12,
        title: 'Baby Spinach',
        slug: 'babyspinach',
        unit: '0.2 lb',
        price: 0.6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Spinach (Spinacia oleracea) is a leafy green flowering plant native to central and western Asia. It is of the order Caryophyllales, family Amaranthaceae, subfamily Chenopodioideae. Its leaves are a common edible vegetable consumed either fresh.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/BabySpinach_xronqz.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/BabySpinach_xronqz.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/BabySpinach_xronqz.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/BabySpinach_xronqz.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/BabySpinach_xronqz.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 13,
        title: 'Mix Vegetable Platter',
        slug: 'mixveggieplatter',
        unit: '0.4 lb',
        price: 1.6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Spinach (Spinacia oleracea) is a leafy green flowering plant native to central and western Asia. It is of the order Caryophyllales, family Amaranthaceae, subfamily Chenopodioideae. Its leaves are a common edible vegetable consumed either fresh.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/VeggiePlatter_ztcg0m.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/VeggiePlatter_ztcg0m.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/VeggiePlatter_ztcg0m.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/VeggiePlatter_ztcg0m.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/VeggiePlatter_ztcg0m.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 14,
        title: 'French Green Beans',
        slug: 'frenchgreenbeans',
        unit: '.6 lb',
        price: 2,
        salePrice: 1.8,
        discountInPercent: 10,
        description: 'Green beans are the unripe, young fruit and protective pods of various cultivars of the common bean. Immature or young pods of the runner bean, yardlong bean, and hyacinth bean are used in a similar way.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/FrenchGreenBeans_azivow.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/FrenchGreenBeans_azivow.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/FrenchGreenBeans_azivow.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/FrenchGreenBeans_azivow.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/FrenchGreenBeans_azivow.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 15,
        title: 'Sweet Corn',
        slug: 'sweetcorn',
        unit: '2.5 lb',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Maize, also known as corn, is a cereal grain first domesticated by indigenous peoples in southern Mexico about 10,000 years ago. The leafy stalk of the plant produces pollen inflorescences and separate ovuliferous inflorescences called ears that yield kernels or seeds, which are fruits.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/Corn_dlrtbv.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Corn_dlrtbv.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Corn_dlrtbv.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Corn_dlrtbv.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Corn_dlrtbv.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 16,
        title: 'Cucumber',
        slug: 'cucumber',
        unit: '4 lb',
        price: 2.2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cucumber is a widely cultivated plant in the gourd family, Cucurbitaceae. It is a creeping vine that bears cucumiform fruits that are used as vegetables. There are three main varieties of cucumber: slicing, pickling, and seedless. Within these varieties, several cultivars have been created.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/Cucumber_w6hlxr.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/Cucumber_w6hlxr.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/Cucumber_w6hlxr.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/Cucumber_w6hlxr.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/Cucumber_w6hlxr.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 17,
        title: 'Pepper',
        slug: 'pepper',
        unit: '1.5 lb',
        price: 10,
        salePrice: 8,
        discountInPercent: 20,
        description: 'Black pepper is a flowering vine in the family Piperaceae, cultivated for its fruit, known as a peppercorn, which is usually dried and used as a spice and seasoning. When fresh and fully mature, it is about 5 mm in diameter and dark red, and contains a single seed, like all drupes',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/MiniPeppers_iydh8m.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/MiniPeppers_iydh8m.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/MiniPeppers_iydh8m.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/MiniPeppers_iydh8m.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/MiniPeppers_iydh8m.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 18,
        title: ' Green Beans',
        slug: 'greenbeans',
        unit: '.6 lb',
        price: 1.5,
        salePrice: 1.2,
        discountInPercent: 20,
        description: 'Green beans are the unripe, young fruit and protective pods of various cultivars of the common bean. Immature or young pods of the runner bean, yardlong bean, and hyacinth bean are used in a similar way.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/GreenBeans_fwddsr.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/GreenBeans_fwddsr.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/GreenBeans_fwddsr.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/GreenBeans_fwddsr.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/GreenBeans_fwddsr.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 19,
        title: ' Brussels Sprout',
        slug: 'brusselssprouts',
        unit: '1 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The Brussels sprout is a member of the Gemmifera Group of cabbages, grown for its edible buds. The leaf vegetables are typically 1.5–4.0 cm in diameter and look like miniature cabbages. The Brussels sprout has long been popular in Brussels, Belgium, and may have gained its name there.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/BrusselsSprouts_adwhet.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/BrusselsSprouts_adwhet.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/BrusselsSprouts_adwhet.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/BrusselsSprouts_adwhet.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/BrusselsSprouts_adwhet.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 20,
        title: 'Peeled Baby Carrot',
        slug: 'babycarrot',
        unit: '2 lb',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The carrot is a root vegetable, usually orange in colour, though purple, black, red, white, and yellow cultivars exist. They are a domesticated form of the wild carrot, Daucus carota, native to Europe and Southwestern Asia. The plant probably originated in Persia and was originally cultivated for its leaves and seeds.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/Peeled_Carrots_xx7mjo.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Peeled_Carrots_xx7mjo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Peeled_Carrots_xx7mjo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Peeled_Carrots_xx7mjo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Peeled_Carrots_xx7mjo.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 3,
                title: 'Vegetables',
                slug: 'vegetables',
            },
        ],
    },
    {
        id: 1,
        title: 'Strawberry',
        slug: 'strawbery',
        unit: '1 lb',
        price: 5,
        salePrice: 2.5,
        discountInPercent: 50,
        description: 'The garden strawberry is a widely grown hybrid species of the genus Fragaria, collectively known as the strawberries, which are cultivated worldwide for their fruit. The fruit is widely appreciated for its characteristic aroma, bright red color, juicy texture, and sweetness.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614569/pickbazar/grocery/strawberry_d4lzfz.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/strawberry_d4lzfz.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/strawberry_d4lzfz.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/strawberry_d4lzfz.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/strawberry_d4lzfz.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 2,
                title: 'Fruits',
                slug: 'fruits',
            },
        ],
    },
    {
        id: 2,
        title: 'Blueberries',
        slug: 'blueberry',
        unit: '1 lb',
        price: 8,
        salePrice: 7.2,
        discountInPercent: 10,
        description: 'Blueberries are perennial flowering plants with blue or purple berries. They are classified in the section Cyanococcus within the genus Vaccinium. Vaccinium also includes cranberries, bilberries, huckleberries and Madeira blueberries. Commercial blueberries—both wild and cultivated —are all native to North America.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614570/pickbazar/grocery/blueberries_relyfn.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/blueberries_relyfn.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/blueberries_relyfn.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/blueberries_relyfn.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614570/pickbazar/grocery/blueberries_relyfn.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 2,
                title: 'Fruits',
                slug: 'fruits',
            },
        ],
    },
    {
        id: 3,
        title: 'Clementines',
        slug: 'clementines',
        unit: '2 lb',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: ' clementine is a tangor, a citrus fruit hybrid between a willowleaf mandarin orange and a sweet orange, named for its late 19th-century discoverer. The exterior is a deep orange colour with a smooth, glossy appearance. ',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614568/pickbazar/grocery/clementines_h74qrp.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/clementines_h74qrp.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/clementines_h74qrp.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/clementines_h74qrp.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614568/pickbazar/grocery/clementines_h74qrp.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 2,
                title: 'Fruits',
                slug: 'fruits',
            },
        ],
    },
    {
        id: 4,
        title: 'Apples',
        slug: 'apples',
        unit: '2 lb',
        price: 2,
        salePrice: 1.6,
        discountInPercent: 20,
        description: 'An apple is a sweet, edible fruit produced by an apple tree (Malus domestica). Apple trees are ... The skin of ripe apples is generally red, yellow, green, pink, or russetted, though many bi- or tri-colored cultivars may be found. ',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614569/pickbazar/grocery/Apples_dmwvrq.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Apples_dmwvrq.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Apples_dmwvrq.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Apples_dmwvrq.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Apples_dmwvrq.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 2,
                title: 'Fruits',
                slug: 'fruits',
            },
        ],
    },
    {
        id: 5,
        title: 'Pears',
        slug: 'pear',
        unit: '1 lb',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The pear tree and shrub are a species of genus Pyrus, in the family Rosaceae, bearing the pomaceous fruit of the same name. Several species of pear are valued for their edible fruit and juices while others are cultivated as trees.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614569/pickbazar/grocery/pears_ukyxdh.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/pears_ukyxdh.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/pears_ukyxdh.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/pears_ukyxdh.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/pears_ukyxdh.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 2,
                title: 'Fruits',
                slug: 'fruits',
            },
        ],
    },
    {
        id: 6,
        title: 'Mango',
        slug: 'mango',
        unit: '2 lb',
        price: 2.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A mango is a juicy stone fruit produced from numerous species of tropical trees belonging to the flowering plant genus Mangifera, cultivated mostly for their edible fruit. Most of these species are found in nature as wild mangoes. The genus belongs to the cashew family Anacardiaceae.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614569/pickbazar/grocery/Mangoes_ruaapa.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Mangoes_ruaapa.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Mangoes_ruaapa.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Mangoes_ruaapa.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Mangoes_ruaapa.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 2,
                title: 'Fruits',
                slug: 'fruits',
            },
        ],
    },
    {
        id: 7,
        title: 'Dates',
        slug: 'dates',
        unit: '2 lb',
        price: 15,
        salePrice: 12,
        discountInPercent: 20,
        description: 'Phoenix dactylifera, commonly known as date or date palm, is a flowering plant species in the palm family, Arecaceae, cultivated for its edible sweet fruit.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589614569/pickbazar/grocery/Dates_pq4oad.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Dates_pq4oad.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Dates_pq4oad.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Dates_pq4oad.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589614569/pickbazar/grocery/Dates_pq4oad.jpg',
            },
        ],
        categories: [
            {
                id: 1,
                title: 'Fruits & Vegetables',
                slug: 'fruits-and-vegetables',
            },
            {
                id: 2,
                title: 'Fruits',
                slug: 'fruits',
            },
        ],
    },
    {
        id: 21,
        title: 'Signature Salmon',
        slug: 'signaturesalmon',
        unit: '1 lb',
        price: 4.95,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Salmon is a common food classified as an oily fish with a rich content of protein and omega-3 fatty acids',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589736058/pickbazar/grocery/SignatureSalmon_fstp4m.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/SignatureSalmon_fstp4m.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/SignatureSalmon_fstp4m.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/SignatureSalmon_fstp4m.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/SignatureSalmon_fstp4m.jpg',
            },
        ],
        categories: [
            {
                id: 4,
                title: 'Meat & Fish',
                slug: 'meat-and-fish',
            },
            {
                id: 5,
                title: 'Fresh Fish',
                slug: 'fresh-fish',
            },
        ],
    },
    {
        id: 22,
        title: 'Cod Fillet',
        slug: 'codfillet',
        unit: '1 lb',
        price: 10,
        salePrice: 7.5,
        discountInPercent: 25,
        description: 'Cod is the common name for the demersal fish genus Gadus, belonging to the family Gadidae. Cod is also used as part of the common name for a number of other fish species, and some species suggested to belong to genus Gadus are not called cod.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589736058/pickbazar/grocery/codfillet_u0mti1.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/codfillet_u0mti1.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/codfillet_u0mti1.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/codfillet_u0mti1.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/codfillet_u0mti1.jpg',
            },
        ],
        categories: [
            {
                id: 4,
                title: 'Meat & Fish',
                slug: 'meat-and-fish',
            },
            {
                id: 5,
                title: 'Fresh Fish',
                slug: 'fresh-fish',
            },
        ],
    },
    {
        id: 23,
        title: 'Swordfish Fillet',
        slug: 'swordfish',
        unit: '1.5 lb',
        price: 10,
        salePrice: 8.5,
        discountInPercent: 15,
        description: 'The swordfish meat has a very delicate flavour, meaty and mild. Swordfish, also known as broadbills, is an oily fish similar to tuna, chunky meat without bones.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589736058/pickbazar/grocery/swordfish_smniuv.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/swordfish_smniuv.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/swordfish_smniuv.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/swordfish_smniuv.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/swordfish_smniuv.jpg',
            },
        ],
        categories: [
            {
                id: 4,
                title: 'Meat & Fish',
                slug: 'meat-and-fish',
            },
            {
                id: 5,
                title: 'Fresh Fish',
                slug: 'fresh-fish',
            },
        ],
    },
    {
        id: 24,
        title: 'Halibut Fillet',
        slug: 'halibut',
        unit: '2 lb',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Hippoglossus stenolepis, the Pacific halibut, is a species of righteye flounder. This very large species of flatfish is native to the North Pacific and is fished by commercial fisheries, sport fishers, and subsistence fishers.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589736058/pickbazar/grocery/halibut_jaz7ju.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/halibut_jaz7ju.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/halibut_jaz7ju.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/halibut_jaz7ju.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736058/pickbazar/grocery/halibut_jaz7ju.jpg',
            },
        ],
        categories: [
            {
                id: 4,
                title: 'Meat & Fish',
                slug: 'meat-and-fish',
            },
            {
                id: 5,
                title: 'Fresh Fish',
                slug: 'fresh-fish',
            },
        ],
    },
    {
        id: 25,
        title: 'Tilapia Fillet',
        slug: 'tilapia',
        unit: '1 lb',
        price: 7.99,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Tilapia is the common name for nearly a hundred species of cichlid fish from the coelotilapine, coptodonine, heterotilapine, oreochromine, pelmatolapiine and tilapiine tribes, with the economically most important species placed in Coptodonini and Oreochromini.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589736058/pickbazar/grocery/TilapiaFillet_a2urhk.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/TilapiaFillet_a2urhk.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/TilapiaFillet_a2urhk.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/TilapiaFillet_a2urhk.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/TilapiaFillet_a2urhk.jpg',
            },
        ],
        categories: [
            {
                id: 4,
                title: 'Meat & Fish',
                slug: 'meat-and-fish',
            },
            {
                id: 5,
                title: 'Fresh Fish',
                slug: 'fresh-fish',
            },
        ],
    },
    {
        id: 26,
        title: 'Fresh Beef',
        slug: 'beef',
        unit: '1 lb',
        price: 6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Meat is animal flesh that is eaten as food. Humans have hunted and killed animals for meat since prehistoric times. The advent of civilization allowed the domestication of animals such as chickens, sheep, rabbits, pigs and cattle.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589736058/pickbazar/grocery/beef_xkxhnb.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/beef_xkxhnb.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/beef_xkxhnb.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/beef_xkxhnb.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/beef_xkxhnb.jpg',
            },
        ],
        categories: [
            {
                id: 4,
                title: 'Meat & Fish',
                slug: 'meat-and-fish',
            },
            {
                id: 6,
                title: 'Meat',
                slug: 'meat',
            },
        ],
    },
    {
        id: 27,
        title: 'Sliced Turkey Breast',
        slug: 'turkeybreast',
        unit: '2 lb ',
        price: 8,
        salePrice: 7.2,
        discountInPercent: 10,
        description: 'Meat is animal flesh that is eaten as food. Humans have hunted and killed animals for meat since prehistoric times. The advent of civilization allowed the domestication of animals such as chickens, sheep, rabbits, pigs and cattle.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589736058/pickbazar/grocery/sliced_turkey_breast_szb8jg.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/sliced_turkey_breast_szb8jg.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/sliced_turkey_breast_szb8jg.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/sliced_turkey_breast_szb8jg.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/sliced_turkey_breast_szb8jg.jpg',
            },
        ],
        categories: [
            {
                id: 4,
                title: 'Meat & Fish',
                slug: 'meat-and-fish',
            },
            {
                id: 6,
                title: 'Meat',
                slug: 'meat',
            },
        ],
    },
    {
        id: 28,
        title: 'Chicken Thighs',
        slug: 'chickenthigh',
        unit: '5 lb ',
        price: 10,
        salePrice: 7,
        discountInPercent: 30,
        description: 'Meat is animal flesh that is eaten as food. Humans have hunted and killed animals for meat since prehistoric times. The advent of civilization allowed the domestication of animals such as chickens, sheep, rabbits, pigs and cattle.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589736058/pickbazar/grocery/chicken_thigh_yrdfwh.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/chicken_thigh_yrdfwh.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/chicken_thigh_yrdfwh.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/chicken_thigh_yrdfwh.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/chicken_thigh_yrdfwh.jpg',
            },
        ],
        categories: [
            {
                id: 4,
                title: 'Meat & Fish',
                slug: 'meat-and-fish',
            },
            {
                id: 6,
                title: 'Meat',
                slug: 'meat',
            },
        ],
    },
    {
        id: 29,
        title: 'Chicken Breast',
        slug: 'chickenbreast',
        unit: '5 lb ',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Meat is animal flesh that is eaten as food. Humans have hunted and killed animals for meat since prehistoric times. The advent of civilization allowed the domestication of animals such as chickens, sheep, rabbits, pigs and cattle.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589736058/pickbazar/grocery/chicken_brest_ribcxo.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/chicken_brest_ribcxo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/chicken_brest_ribcxo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/chicken_brest_ribcxo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/chicken_brest_ribcxo.jpg',
            },
        ],
        categories: [
            {
                id: 4,
                title: 'Meat & Fish',
                slug: 'meat-and-fish',
            },
            {
                id: 6,
                title: 'Meat',
                slug: 'meat',
            },
        ],
    },
    {
        id: 30,
        title: 'Beef Steak',
        slug: 'beefsteak',
        unit: '3 lb ',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Meat is animal flesh that is eaten as food. Humans have hunted and killed animals for meat since prehistoric times. The advent of civilization allowed the domestication of animals such as chickens, sheep, rabbits, pigs and cattle.',
        type: 'grocery',
        image: 'https://res.cloudinary.com/redq-inc/image/upload/c_fit,q_auto:best,w_300/v1589736058/pickbazar/grocery/steak_okxpjo.jpg',
        gallery: [
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/steak_okxpjo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/steak_okxpjo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/steak_okxpjo.jpg',
            },
            {
                url: 'https://res.cloudinary.com/redq-inc/image/upload/v1589736057/pickbazar/grocery/steak_okxpjo.jpg',
            },
        ],
        categories: [
            {
                id: 4,
                title: 'Meat & Fish',
                slug: 'meat-and-fish',
            },
            {
                id: 6,
                title: 'Meat',
                slug: 'meat',
            },
        ],
    },
    {
        id: 104,
        title: 'Belmont Custard Cream',
        slug: 'belmont-custard-cream',
        unit: '.50 lb',
        price: 5,
        salePrice: 4.5,
        discountInPercent: 10,
        description: 'A biscuit is a flour-based baked food product. This article covers the type of biscuit found in Africa, Asia, and Europe, which is typically hard, flat, and unleavened. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/belmont_custard_cream.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/belmont_custard_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/belmont_custard_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/belmont_custard_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/belmont_custard_cream.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 49,
                title: 'Biscuits',
                slug: 'biscuits',
            },
        ],
    },
    {
        id: 105,
        title: 'Crawford Digestives',
        slug: 'crawford-digestives',
        unit: '.80 lb',
        price: 8,
        salePrice: 6.4,
        discountInPercent: 20,
        description: 'A biscuit is a flour-based baked food product. This article covers the type of biscuit found in Africa, Asia, and Europe, which is typically hard, flat, and unleavened. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crawford_digestives.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crawford_digestives.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crawford_digestives.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crawford_digestives.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crawford_digestives.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 49,
                title: 'Biscuits',
                slug: 'biscuits',
            },
        ],
    },
    {
        id: 106,
        title: 'Crawford Shortie',
        slug: 'crawford-shortie',
        unit: '.60 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A biscuit is a flour-based baked food product. This article covers the type of biscuit found in Africa, Asia, and Europe, which is typically hard, flat, and unleavened. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crawford_shortie.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crawford_shortie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crawford_shortie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crawford_shortie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crawford_shortie.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 49,
                title: 'Biscuits',
                slug: 'biscuits',
            },
        ],
    },
    {
        id: 107,
        title: 'Hill Mini Pack Mix',
        slug: 'hill-mini-pack-mix',
        unit: '1 pc(s)',
        price: 10,
        salePrice: 9.5,
        discountInPercent: 5,
        description: 'A biscuit is a flour-based baked food product. This article covers the type of biscuit found in Africa, Asia, and Europe, which is typically hard, flat, and unleavened. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Hill_Biscuits_Mini_Pack_Mix_.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Hill_Biscuits_Mini_Pack_Mix_.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Hill_Biscuits_Mini_Pack_Mix_.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Hill_Biscuits_Mini_Pack_Mix_.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Hill_Biscuits_Mini_Pack_Mix_.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 49,
                title: 'Biscuits',
                slug: 'biscuits',
            },
        ],
    },
    {
        id: 108,
        title: 'Jammie Dodgers Raspberry',
        slug: 'jammie-dodgers-raspberry',
        unit: ' .6 lb',
        price: 8,
        salePrice: 7.2,
        discountInPercent: 10,
        description: 'A biscuit is a flour-based baked food product. This article covers the type of biscuit found in Africa, Asia, and Europe, which is typically hard, flat, and unleavened. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Jammie_Dodgers.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Jammie_Dodgers.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Jammie_Dodgers.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Jammie_Dodgers.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Jammie_Dodgers.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 49,
                title: 'Biscuits',
                slug: 'biscuits',
            },
        ],
    },
    {
        id: 110,
        title: 'Juli S Cheese Crackers',
        slug: 'juli-cheese-crackers',
        unit: ' .6 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A biscuit is a flour-based baked food product. This article covers the type of biscuit found in Africa, Asia, and Europe, which is typically hard, flat, and unleavened. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/julis_cheese_crackers.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/julis_cheese_crackers.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/julis_cheese_crackers.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/julis_cheese_crackers.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/julis_cheese_crackers.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 49,
                title: 'Biscuits',
                slug: 'biscuits',
            },
        ],
    },
    {
        id: 111,
        title: 'Khong Guan Orange Cream',
        slug: 'khong-guan-orange-cream',
        unit: ' 0.5 lb',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A biscuit is a flour-based baked food product. This article covers the type of biscuit found in Africa, Asia, and Europe, which is typically hard, flat, and unleavened. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/khong_guan_orange_cream.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/khong_guan_orange_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/khong_guan_orange_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/khong_guan_orange_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/khong_guan_orange_cream.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 49,
                title: 'Biscuits',
                slug: 'biscuits',
            },
        ],
    },
    {
        id: 112,
        title: 'Khong Guan Premium Marie',
        slug: 'khong-guan-premium-marie',
        unit: ' 1 pc(s)',
        price: 9,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A biscuit is a flour-based baked food product. This article covers the type of biscuit found in Africa, Asia, and Europe, which is typically hard, flat, and unleavened. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/khong_guan_premium_marie.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/khong_guan_premium_marie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/khong_guan_premium_marie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/khong_guan_premium_marie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/khong_guan_orange_cream.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 49,
                title: 'Biscuits',
                slug: 'biscuits',
            },
        ],
    },
    {
        id: 113,
        title: 'Lotus Biscoff',
        slug: 'lotus-biscoff',
        unit: ' 0.5 lb',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A biscuit is a flour-based baked food product. This article covers the type of biscuit found in Africa, Asia, and Europe, which is typically hard, flat, and unleavened. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lotus_biscoff.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lotus_biscoff.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lotus_biscoff.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lotus_biscoff.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lotus_biscoff.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 49,
                title: 'Biscuits',
                slug: 'biscuits',
            },
        ],
    },
    {
        id: 114,
        title: 'Filipinos',
        slug: 'aritiach-filipino',
        unit: ' 0.5 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/artiach_filipino.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/artiach_filipino.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/artiach_filipino.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/artiach_filipino.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/artiach_filipino.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 115,
        title: 'Dairy Milk Crispello',
        slug: 'cadbury_dairy_milk_crispello',
        unit: ' 0.2 lb',
        price: 2.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury_dairy_milk_crispello.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury_dairy_milk_crispello.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury_dairy_milk_crispello.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury_dairy_milk_crispello.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury_dairy_milk_crispello.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 116,
        title: 'Cadbury Zip',
        slug: 'cadbury_zip',
        unit: ' 0.1 lb',
        price: 3,
        salePrice: 2.7,
        discountInPercent: 10,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury_zip.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury_zip.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury_zip.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury_zip.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury_zip.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 117,
        title: 'Dairy Milk Reclose',
        slug: 'cadbury-dairy-milk-reclose',
        unit: ' 0.2 lb',
        price: 3.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury-dairy-milk-reclose.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury-dairy-milk-reclose.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury-dairy-milk-reclose.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury-dairy-milk-reclose.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cadbury-dairy-milk-reclose.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 118,
        title: 'Cloetta Chocowoffle Crispy',
        slug: 'cloetta_chocowoffle',
        unit: ' 0.2 lb',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cloetta.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cloetta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cloetta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cloetta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cloetta.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 119,
        title: 'Cloetta Sprinkle',
        slug: 'cloetta_sprinkle',
        unit: ' 0.1 lb',
        price: 3,
        salePrice: 2.7,
        discountInPercent: 10,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cloetta_sprinkle.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cloetta_sprinkle.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cloetta_sprinkle.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cloetta_sprinkle.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cloetta_sprinkle.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 120,
        title: 'Hershey Symphony',
        slug: 'hershey_symphony',
        unit: ' 0.1 lb',
        price: 4,
        salePrice: 3.2,
        discountInPercent: 20,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hershey_symphony.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hershey_symphony.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hershey_symphony.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hershey_symphony.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hershey_symphony.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 121,
        title: 'Hersheys Kisses',
        slug: 'hersheys_kisses',
        unit: '0.1 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hersheys_kisses.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hersheys_kisses.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hersheys_kisses.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hersheys_kisses.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hersheys_kisses.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 122,
        title: 'M M Funsize',
        slug: 'm&m_funsize',
        unit: ' 0.1 lb',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/m&m_funsize.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/m&m_funsize.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/m&m_funsize.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/m&m_funsize.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/m&m_funsize.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 123,
        title: 'Nestle Butterfinger',
        slug: 'nestle_butterfinger',
        unit: ' 0.1 lb',
        price: 2.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_butterfinger.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_butterfinger.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_butterfinger.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_butterfinger.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_butterfinger.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 124,
        title: 'Nestle Kitkat',
        slug: 'nestle_kitkat',
        unit: ' 0.1 lb',
        price: 2.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_kitkat.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_kitkat.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_kitkat.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_kitkat.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_kitkat.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 125,
        title: 'Snikers Slice',
        slug: 'snikers_slice',
        unit: ' 0.1 lb',
        price: 3,
        salePrice: 2.4,
        discountInPercent: 20,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snikers_slice.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snikers_slice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snikers_slice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snikers_slice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snikers_slice.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 126,
        title: 'Snikers Snack Size',
        slug: 'snikers_snacksize',
        unit: ' 0.1 lb',
        price: 3,
        salePrice: 2.4,
        discountInPercent: 20,
        description: 'Chocolate is a usually sweet, brown food preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, or used as a flavoring ingredient in other foods. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snikers_snacksize.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snikers_snacksize.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snikers_snacksize.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snikers_snacksize.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snikers_snacksize.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 50,
                title: 'Chocolates',
                slug: 'chocolates',
            },
        ],
    },
    {
        id: 127,
        title: 'Wise Cottage Fires Bbq',
        slug: 'cottage_bbq',
        unit: ' 1 pc(s)',
        price: 0.6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cottage_bbq.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cottage_bbq.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cottage_bbq.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cottage_bbq.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cottage_bbq.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 128,
        title: 'Puzzles Crips Ready Salted',
        slug: 'crisps-puzzle',
        unit: ' 1 pc(s)',
        price: 0.6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crisps-puzzle.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crisps-puzzle.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crisps-puzzle.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crisps-puzzle.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crisps-puzzle.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 129,
        title: 'Doritos Tangy Cheese',
        slug: 'doritos',
        unit: ' 1 pc(s)',
        price: 1,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/doritos.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/doritos.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/doritos.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/doritos.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/doritos.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 130,
        title: 'Lays Baked',
        slug: 'lays_baked',
        unit: ' 1 pc(s)',
        price: 1.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays_baked.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays_baked.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays_baked.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays_baked.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays_baked.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 131,
        title: 'Lays Sea Salted',
        slug: 'lays_sea_salted',
        unit: ' 1 pc(s)',
        price: 1.2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays_sea_salted.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays_sea_salted.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays_sea_salted.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays_sea_salted.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays_sea_salted.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 132,
        title: 'Lays Kettle Cooked',
        slug: 'lays-kettle-cooked',
        unit: ' 1 pc(s)',
        price: 1.8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays-kettle-cooked.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays-kettle-cooked.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays-kettle-cooked.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays-kettle-cooked.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays-kettle-cooked.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 133,
        title: 'Lays Sour Cream',
        slug: 'lays-sour-cream',
        unit: ' 1 pc(s)',
        price: 1.3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays-sour-cream.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays-sour-cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays-sour-cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays-sour-cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/lays-sour-cream.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 134,
        title: 'Nims Apple Crisp',
        slug: 'nims_apple_fruit',
        unit: ' 1 pc(s)',
        price: 1,
        salePrice: 85,
        discountInPercent: 15,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nims_apple_fruit.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nims_apple_fruit.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nims_apple_fruit.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nims_apple_fruit.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nims_apple_fruit.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 135,
        title: 'Snack Rite Ready Saled',
        slug: 'Ready-Salted-Crisps',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Ready-Salted-Crisps.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Ready-Salted-Crisps.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Ready-Salted-Crisps.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Ready-Salted-Crisps.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Ready-Salted-Crisps.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 136,
        title: 'Roysters Bubbled Crips',
        slug: 'Roysters_6pk',
        unit: '1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Roysters_6pk.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Roysters_6pk.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Roysters_6pk.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Roysters_6pk.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Roysters_6pk.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 137,
        title: 'Snackrite Cheese Onion',
        slug: 'snackrite_bags',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snackrite_bags.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snackrite_bags.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snackrite_bags.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snackrite_bags.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/snackrite_bags.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 138,
        title: 'Tayto Cheese Onion',
        slug: 'tayto_cheese_onion',
        unit: ' 1 pc(s)',
        price: 0.8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tayto_cheese_onion.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tayto_cheese_onion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tayto_cheese_onion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tayto_cheese_onion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tayto_cheese_onion.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 139,
        title: 'Tayto Pickled Onion',
        slug: 'tayto_pickled_onion',
        unit: ' 1 pc(s)',
        price: 0.8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tayto_pickled_onion.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tayto_pickled_onion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tayto_pickled_onion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tayto_pickled_onion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tayto_pickled_onion.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 140,
        title: 'Walkers Cheese Onion',
        slug: 'walkers_cheese_onion',
        unit: ' 1 pc(s)',
        price: 0.8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Potato chips (often just chips), or crisps, are thin slices of potato that have been deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer. The basic chips are cooked and salted; additional varieties are manufactured using various flavorings and ingredients including herbs, spices, cheeses, other natural flavors, artificial flavors, and additives.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/walkers_cheese_onion.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/walkers_cheese_onion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/walkers_cheese_onion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/walkers_cheese_onion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/walkers_cheese_onion.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 51,
                title: 'Crisps',
                slug: 'crisps',
            },
        ],
    },
    {
        id: 141,
        title: 'Cintan Chicken Noodles',
        slug: 'cintan_chicken',
        unit: ' .5 lb',
        price: 5,
        salePrice: 4.5,
        discountInPercent: 10,
        description: 'Noodles are a type of food made from unleavened dough which is rolled flat and cut, or extruded, into long strips or strings. Noodles can be refrigerated for short-term storage or dried and stored for future use.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cintan_chicken.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cintan_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cintan_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cintan_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cintan_chicken.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 52,
                title: 'Noodles',
                slug: 'noodles',
            },
        ],
    },
    {
        id: 142,
        title: 'Indomie Chicken Noodles',
        slug: 'indomie_special_chicken',
        unit: ' .5 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Noodles are a type of food made from unleavened dough which is rolled flat and cut, or extruded, into long strips or strings. Noodles can be refrigerated for short-term storage or dried and stored for future use.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/indomie_special_chicken.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/indomie_special_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/indomie_special_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/indomie_special_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/indomie_special_chicken.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 52,
                title: 'Noodles',
                slug: 'noodles',
            },
        ],
    },
    {
        id: 143,
        title: 'Kame H Okkein Stir Fry Noodles',
        slug: 'kame_stir_fry',
        unit: ' .5 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Noodles are a type of food made from unleavened dough which is rolled flat and cut, or extruded, into long strips or strings. Noodles can be refrigerated for short-term storage or dried and stored for future use.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kame_stir_fry.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kame_stir_fry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kame_stir_fry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kame_stir_fry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kame_stir_fry.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 52,
                title: 'Noodles',
                slug: 'noodles',
            },
        ],
    },
    {
        id: 144,
        title: 'Koka Curry Noodles',
        slug: 'koka_curry',
        unit: ' .5 lb',
        price: 6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Noodles are a type of food made from unleavened dough which is rolled flat and cut, or extruded, into long strips or strings. Noodles can be refrigerated for short-term storage or dried and stored for future use..',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/koka_curry.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/koka_curry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/koka_curry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/koka_curry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/koka_curry.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 52,
                title: 'Noodles',
                slug: 'noodles',
            },
        ],
    },
    {
        id: 145,
        title: 'Maggi Bbq Noodles',
        slug: 'maggi_bbq',
        unit: ' .5 lb',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Noodles are a type of food made from unleavened dough which is rolled flat and cut, or extruded, into long strips or strings. Noodles can be refrigerated for short-term storage or dried and stored for future use..',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_bbq.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_bbq.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_bbq.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_bbq.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_bbq.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 52,
                title: 'Noodles',
                slug: 'noodles',
            },
        ],
    },
    {
        id: 146,
        title: 'Maggi Beef Noodles',
        slug: 'maggi_beef',
        unit: ' .5 lb',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Noodles are a type of food made from unleavened dough which is rolled flat and cut, or extruded, into long strips or strings. Noodles can be refrigerated for short-term storage or dried and stored for future use..',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_beef.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_beef.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_beef.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_beef.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_beef.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 52,
                title: 'Noodles',
                slug: 'noodles',
            },
        ],
    },
    {
        id: 147,
        title: 'Maggi Biriyani Noodles',
        slug: 'maggi_biriyani',
        unit: ' ..5 lb',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Noodles are a type of food made from unleavened dough which is rolled flat and cut, or extruded, into long strips or strings. Noodles can be refrigerated for short-term storage or dried and stored for future use..',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_biriyani.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_biriyani.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_biriyani.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_biriyani.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_biriyani.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 52,
                title: 'Noodles',
                slug: 'noodles',
            },
        ],
    },
    {
        id: 148,
        title: 'Maggi Chicken Noodles',
        slug: 'maggi_chicken',
        unit: ' .20 lb',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Noodles are a type of food made from unleavened dough which is rolled flat and cut, or extruded, into long strips or strings. Noodles can be refrigerated for short-term storage or dried and stored for future use..',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_chicken.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/maggi_chicken.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 52,
                title: 'Noodles',
                slug: 'noodles',
            },
        ],
    },
    {
        id: 150,
        title: 'Badia Pine Nuts',
        slug: 'badia_pinenuts',
        unit: ' .30 lb',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/badia_pinenuts.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/badia_pinenuts.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/badia_pinenuts.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/badia_pinenuts.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/badia_pinenuts.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 151,
        title: 'Cacao Cashew Nuts',
        slug: 'cacao_cashew',
        unit: ' .20 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cacao_cashew.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cacao_cashew.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cacao_cashew.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cacao_cashew.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cacao_cashew.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 152,
        title: 'Fisher Peanuts Ready Salted',
        slug: 'fisher_peanut',
        unit: ' 2 lb',
        price: 15,
        salePrice: 12,
        discountInPercent: 20,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/fisher_peanut.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/fisher_peanut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/fisher_peanut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/fisher_peanut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/fisher_peanut.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 153,
        title: 'Simply Natural Fruits And Nuts',
        slug: 'Fruits-And-Nuts-Full-Meal-Bottle-Front',
        unit: ' .5 lb',
        price: 15,
        salePrice: 12,
        discountInPercent: 20,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fruits-And-Nuts-Full-Meal-Bottle-Front.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fruits-And-Nuts-Full-Meal-Bottle-Front.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fruits-And-Nuts-Full-Meal-Bottle-Front.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fruits-And-Nuts-Full-Meal-Bottle-Front.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fruits-And-Nuts-Full-Meal-Bottle-Front.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 154,
        title: 'Simply Natural Fruits And Nuts Meal Pack',
        slug: 'Fruits-And-Nuts-Full-Meal-Packet',
        unit: ' .5 lb',
        price: 7,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fruits-And-Nuts-Full-Meal-Packet.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fruits-And-Nuts-Full-Meal-Packet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fruits-And-Nuts-Full-Meal-Packet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fruits-And-Nuts-Full-Meal-Packet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fruits-And-Nuts-Full-Meal-Packet.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 155,
        title: 'Ivory Cashew Nuts',
        slug: 'ivory_cashewnut',
        unit: ' .5 lb',
        price: 6.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/ivory_cashewnut.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/ivory_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/ivory_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/ivory_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/ivory_cashewnut.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 156,
        title: 'Jaybees Whole Cashew Nuts',
        slug: 'jaybee_whole_cashewnut',
        unit: ' .5 lb',
        price: 6.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/jaybee_whole_cashewnut.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/jaybee_whole_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/jaybee_whole_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/jaybee_whole_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/jaybee_whole_cashewnut.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 157,
        title: 'Kirkland Mixed Nuts',
        slug: 'kirkland_mixed_nuts',
        unit: ' 1 pc(s)',
        price: 20,
        salePrice: 17,
        discountInPercent: 15,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kirkland_mixed_nuts.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kirkland_mixed_nuts.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kirkland_mixed_nuts.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kirkland_mixed_nuts.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kirkland_mixed_nuts.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 158,
        title: 'Natural Nuts',
        slug: 'natural_cashewnut',
        unit: ' 5 lb',
        price: 10,
        salePrice: 8,
        discountInPercent: 20,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/natural_cashewnut.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/natural_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/natural_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/natural_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/natural_cashewnut.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 159,
        title: 'Planter Cashewnut',
        slug: 'planter_cashewnut',
        unit: ' 5 lb',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planter_cashewnut.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planter_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planter_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planter_cashewnut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planter_cashewnut.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 160,
        title: 'Planter Dry Roasted',
        slug: 'planter_dryroasted',
        unit: ' 5 lb',
        price: 10,
        salePrice: 8,
        discountInPercent: 20,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planters_dry_roasted.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planters_dry_roasted.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planters_dry_roasted.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planters_dry_roasted.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planters_dry_roasted.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 161,
        title: 'Planter Honey Roasted',
        slug: 'planters_honey_roasted',
        unit: ' 5 lb',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A nut is a fruit composed of an inedible hard shell and a seed, which is generally edible. In general usage, a wide variety of dried seeds are called nuts, but in a botanical context nut implies that the shell does not open to release the seed.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planters_honey_roasted.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planters_honey_roasted.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planters_honey_roasted.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planters_honey_roasted.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/planters_honey_roasted.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 53,
                title: 'Nuts',
                slug: 'nuts',
            },
        ],
    },
    {
        id: 162,
        title: 'Bambino Macaroni',
        slug: 'bambino_macaroni',
        unit: ' 8 lb',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Pasta is a type of food typically made from an unleavened dough of durum wheat flour mixed with water or eggs, and formed into sheets or various shapes, then cooked by boiling or baking',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bambino_macaroni.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bambino_macaroni.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bambino_macaroni.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bambino_macaroni.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bambino_macaroni.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 54,
                title: 'Pasta',
                slug: 'pasta',
            },
        ],
    },
    {
        id: 163,
        title: 'Bambino Premium Pasta',
        slug: 'bambino_premium_pasta',
        unit: ' 6 lb',
        price: 18,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Pasta is a type of food typically made from an unleavened dough of durum wheat flour mixed with water or eggs, and formed into sheets or various shapes, then cooked by boiling or baking',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bambino_premium_pasta.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bambino_premium_pasta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bambino_premium_pasta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bambino_premium_pasta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bambino_premium_pasta.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 54,
                title: 'Pasta',
                slug: 'pasta',
            },
        ],
    },
    {
        id: 164,
        title: 'Batchelors Pasta N Sauce Cheese And Broccoli',
        slug: 'batchelors_pasta_sauce',
        unit: ' 2 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Pasta is a type of food typically made from an unleavened dough of durum wheat flour mixed with water or eggs, and formed into sheets or various shapes, then cooked by boiling or baking',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/batchelors_pasta_sauce.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/batchelors_pasta_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/batchelors_pasta_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/batchelors_pasta_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/batchelors_pasta_sauce.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 54,
                title: 'Pasta',
                slug: 'pasta',
            },
        ],
    },
    {
        id: 165,
        title: 'Borges Pasta',
        slug: 'borges-pasta',
        unit: ' 6 lb',
        price: 12,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Pasta is a type of food typically made from an unleavened dough of durum wheat flour mixed with water or eggs, and formed into sheets or various shapes, then cooked by boiling or baking',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/borges-pasta.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/borges-pasta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/borges-pasta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/borges-pasta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/borges-pasta.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 54,
                title: 'Pasta',
                slug: 'pasta',
            },
        ],
    },
    {
        id: 166,
        title: 'Pasta Sauce',
        slug: 'Cheese-&-Broccoli-Pasta-&-Sauce',
        unit: ' 3 lb',
        price: 15,
        salePrice: 12,
        discountInPercent: 20,
        description: 'Pasta is a type of food typically made from an unleavened dough of durum wheat flour mixed with water or eggs, and formed into sheets or various shapes, then cooked by boiling or baking',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Cheese-&-Broccoli-Pasta-&-Sauce.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Cheese-&-Broccoli-Pasta-&-Sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Cheese-&-Broccoli-Pasta-&-Sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Cheese-&-Broccoli-Pasta-&-Sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Cheese-&-Broccoli-Pasta-&-Sauce.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 54,
                title: 'Pasta',
                slug: 'pasta',
            },
        ],
    },
    {
        id: 167,
        title: 'Sun Remo Chiken Flavour Pasta',
        slug: 'sunremo_chicken_curry_pasta',
        unit: ' 3 lb',
        price: 15,
        salePrice: 12,
        discountInPercent: 20,
        description: 'Pasta is a type of food typically made from an unleavened dough of durum wheat flour mixed with water or eggs, and formed into sheets or various shapes, then cooked by boiling or baking',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/sunremo_chicken_curry_pasta.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/sunremo_chicken_curry_pasta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/sunremo_chicken_curry_pasta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/sunremo_chicken_curry_pasta.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/sunremo_chicken_curry_pasta.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 54,
                title: 'Pasta',
                slug: 'pasta',
            },
        ],
    },
    {
        id: 168,
        title: 'Knorr Pastaria',
        slug: 'knorr_pastaria',
        unit: ' 3 lb',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Pasta is a type of food typically made from an unleavened dough of durum wheat flour mixed with water or eggs, and formed into sheets or various shapes, then cooked by boiling or baking',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/knorr_pastaria.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/knorr_pastaria.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/knorr_pastaria.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/knorr_pastaria.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/knorr_pastaria.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 54,
                title: 'Pasta',
                slug: 'pasta',
            },
        ],
    },
    {
        id: 169,
        title: 'Allegro Bbq Sauce',
        slug: 'allegro_bbq_sauce',
        unit: ' 6 lb',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'In cooking, a sauce is a liquid, cream, or semi-solid food, served on or used in preparing other foods. Most sauces are not normally consumed by themselves; they add flavor, moisture, and visual appeal to a dish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/allegro_bbq_sauce.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/allegro_bbq_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/allegro_bbq_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/allegro_bbq_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/allegro_bbq_sauce.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 55,
                title: 'Sauce',
                slug: 'sauce',
            },
        ],
    },
    {
        id: 170,
        title: 'Asin Zing Chili Sauce',
        slug: 'asain_zing_chili',
        unit: ' 6 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'In cooking, a sauce is a liquid, cream, or semi-solid food, served on or used in preparing other foods. Most sauces are not normally consumed by themselves; they add flavor, moisture, and visual appeal to a dish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/asain_zing_chili.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/asain_zing_chili.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/asain_zing_chili.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/asain_zing_chili.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/asain_zing_chili.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 55,
                title: 'Sauce',
                slug: 'sauce',
            },
        ],
    },
    {
        id: 171,
        title: 'Crunch Hot Chili Sauce',
        slug: 'crunch_hot_chili_sauce',
        unit: ' 6 lb',
        price: 6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'In cooking, a sauce is a liquid, cream, or semi-solid food, served on or used in preparing other foods. Most sauces are not normally consumed by themselves; they add flavor, moisture, and visual appeal to a dish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crunch_hot_chili_sauce.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crunch_hot_chili_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crunch_hot_chili_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crunch_hot_chili_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crunch_hot_chili_sauce.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 55,
                title: 'Sauce',
                slug: 'sauce',
            },
        ],
    },
    {
        id: 172,
        title: 'Felix Chili Sauce',
        slug: 'felix_chili_sauce',
        unit: ' 6 lb',
        price: 6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'In cooking, a sauce is a liquid, cream, or semi-solid food, served on or used in preparing other foods. Most sauces are not normally consumed by themselves; they add flavor, moisture, and visual appeal to a dish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/felix_chili_sauce.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/felix_chili_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/felix_chili_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/felix_chili_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/felix_chili_sauce.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 55,
                title: 'Sauce',
                slug: 'sauce',
            },
        ],
    },
    {
        id: 173,
        title: 'Heinz Chili Sauce',
        slug: 'heinz_chili_sauce',
        unit: ' 7 lb',
        price: 8,
        salePrice: 7.2,
        discountInPercent: 10,
        description: 'In cooking, a sauce is a liquid, cream, or semi-solid food, served on or used in preparing other foods. Most sauces are not normally consumed by themselves; they add flavor, moisture, and visual appeal to a dish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/heinz_chili_sauce.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/heinz_chili_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/heinz_chili_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/heinz_chili_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/heinz_chili_sauce.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 55,
                title: 'Sauce',
                slug: 'sauce',
            },
        ],
    },
    {
        id: 174,
        title: 'Kosmos Bbq Sauce',
        slug: 'kosmos_bbq',
        unit: ' 7 lb',
        price: 8,
        salePrice: 7.2,
        discountInPercent: 10,
        description: 'In cooking, a sauce is a liquid, cream, or semi-solid food, served on or used in preparing other foods. Most sauces are not normally consumed by themselves; they add flavor, moisture, and visual appeal to a dish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kosmos_bbq.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kosmos_bbq.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kosmos_bbq.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kosmos_bbq.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kosmos_bbq.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 55,
                title: 'Sauce',
                slug: 'sauce',
            },
        ],
    },
    {
        id: 175,
        title: 'Taco Bell Diablo Sauce',
        slug: 'taco_bell_diablo_sauce',
        unit: ' 7 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'In cooking, a sauce is a liquid, cream, or semi-solid food, served on or used in preparing other foods. Most sauces are not normally consumed by themselves; they add flavor, moisture, and visual appeal to a dish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taco_bell_diablo_sauce.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taco_bell_diablo_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taco_bell_diablo_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taco_bell_diablo_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taco_bell_diablo_sauce.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 55,
                title: 'Sauce',
                slug: 'sauce',
            },
        ],
    },
    {
        id: 176,
        title: 'Taco Bell Fire Sauce',
        slug: 'taco_bell_fire_sauce',
        unit: ' 7 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'In cooking, a sauce is a liquid, cream, or semi-solid food, served on or used in preparing other foods. Most sauces are not normally consumed by themselves; they add flavor, moisture, and visual appeal to a dish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taco_bell_fire_sauce.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taco_bell_fire_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taco_bell_fire_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taco_bell_fire_sauce.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taco_bell_fire_sauce.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 55,
                title: 'Sauce',
                slug: 'sauce',
            },
        ],
    },
    {
        id: 177,
        title: 'Traditional Bak Kut Teh Soup',
        slug: 'bak_kuh_teh',
        unit: ' 2 lb',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Soup is a primarily liquid food, generally served warm or hot (but may be cool or cold), that is made by combining ingredients of meat or vegetables with stock, or water. Hot soups are additionally characterized by boiling solid ingredients in liquids in a pot until the flavors are extracted, forming a broth.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bak_kuh_teh.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bak_kuh_teh.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bak_kuh_teh.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bak_kuh_teh.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bak_kuh_teh.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 56,
                title: 'Soup',
                slug: 'soup',
            },
        ],
    },
    {
        id: 178,
        title: 'Chings Mix Vegetable Soup',
        slug: 'chings_mixvegsoup',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Soup is a primarily liquid food, generally served warm or hot (but may be cool or cold), that is made by combining ingredients of meat or vegetables with stock, or water. Hot soups are additionally characterized by boiling solid ingredients in liquids in a pot until the flavors are extracted, forming a broth.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chings_mixvegsoup.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chings_mixvegsoup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chings_mixvegsoup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chings_mixvegsoup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chings_mixvegsoup.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 56,
                title: 'Soup',
                slug: 'soup',
            },
        ],
    },
    {
        id: 179,
        title: 'Dashi Chicken Corn Soup',
        slug: 'dashi_chicken_corn',
        unit: ' 2 lb',
        price: 1,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Soup is a primarily liquid food, generally served warm or hot (but may be cool or cold), that is made by combining ingredients of meat or vegetables with stock, or water. Hot soups are additionally characterized by boiling solid ingredients in liquids in a pot until the flavors are extracted, forming a broth.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dashi_chicken_corn.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dashi_chicken_corn.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dashi_chicken_corn.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dashi_chicken_corn.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dashi_chicken_corn.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 56,
                title: 'Soup',
                slug: 'soup',
            },
        ],
    },
    {
        id: 180,
        title: 'Knorr Thai Vegetable Soup',
        slug: 'knorr_thai_vegetable',
        unit: ' 2 lb',
        price: 1.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Soup is a primarily liquid food, generally served warm or hot (but may be cool or cold), that is made by combining ingredients of meat or vegetables with stock, or water. Hot soups are additionally characterized by boiling solid ingredients in liquids in a pot until the flavors are extracted, forming a broth.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/knorr_thai_vegetable.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/knorr_thai_vegetable.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/knorr_thai_vegetable.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/knorr_thai_vegetable.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/knorr_thai_vegetable.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 56,
                title: 'Soup',
                slug: 'soup',
            },
        ],
    },
    {
        id: 181,
        title: 'Rempah Sup Soup Mix',
        slug: 'rempah_sup',
        unit: ' 2 lb',
        price: 3,
        salePrice: 2.7,
        discountInPercent: 10,
        description: 'Soup is a primarily liquid food, generally served warm or hot (but may be cool or cold), that is made by combining ingredients of meat or vegetables with stock, or water. Hot soups are additionally characterized by boiling solid ingredients in liquids in a pot until the flavors are extracted, forming a broth.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/rempah_sup.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/rempah_sup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/rempah_sup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/rempah_sup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/rempah_sup.jpg',
            },
        ],
        categories: [
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
            },
            {
                id: 56,
                title: 'Soup',
                slug: 'soup',
            },
        ],
    },
    {
        id: 182,
        title: 'Avo Derm Grain Free',
        slug: 'grain_free',
        unit: ' 5 lb',
        price: 30,
        salePrice: 27,
        discountInPercent: 10,
        description: 'Cat food is food specifically formulated and intended for consumption by cats and other related canines. Like all carnivores, cats have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/grain_free.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/grain_free.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/grain_free.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/grain_free.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/grain_free.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 58,
                title: 'Cat Food',
                slug: 'catfood',
            },
        ],
    },
    {
        id: 183,
        title: 'Hills Science Diet',
        slug: 'hills_science_diet',
        unit: ' 5 lb',
        price: 25,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cat food is food specifically formulated and intended for consumption by cats and other related canines. Like all carnivores, cats have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hills_science_diet.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hills_science_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hills_science_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hills_science_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hills_science_diet.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 58,
                title: 'Cat Food',
                slug: 'catfood',
            },
        ],
    },
    {
        id: 184,
        title: 'Instinct Ultimate Protien',
        slug: 'instinct_the_raw',
        unit: ' 4 lb',
        price: 20,
        salePrice: 16,
        discountInPercent: 20,
        description: 'Cat food is food specifically formulated and intended for consumption by cats and other related canines. Like all carnivores, cats have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/instinct_the_raw.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/instinct_the_raw.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/instinct_the_raw.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/instinct_the_raw.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/instinct_the_raw.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 58,
                title: 'Cat Food',
                slug: 'catfood',
            },
        ],
    },
    {
        id: 185,
        title: 'Natural Balance L I D',
        slug: 'natural_balance',
        unit: ' 4 lb',
        price: 20,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cat food is food specifically formulated and intended for consumption by cats and other related canines. Like all carnivores, cats have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/natural_balance.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/natural_balance.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/natural_balance.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/natural_balance.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/natural_balance.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 58,
                title: 'Cat Food',
                slug: 'catfood',
            },
        ],
    },
    {
        id: 186,
        title: 'Hills Urinary Care',
        slug: 'prescription_diet',
        unit: ' 4 lb',
        price: 20,
        salePrice: 15,
        discountInPercent: 25,
        description: 'Cat food is food specifically formulated and intended for consumption by cats and other related canines. Like all carnivores, cats have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/prescription_diet.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/prescription_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/prescription_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/prescription_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/prescription_diet.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 58,
                title: 'Cat Food',
                slug: 'catfood',
            },
        ],
    },
    {
        id: 187,
        title: 'Pro Diet Complete Balance',
        slug: 'pro_diet',
        unit: ' 6 lb',
        price: 25,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cat food is food specifically formulated and intended for consumption by cats and other related canines. Like all carnivores, cats have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pro_diet.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pro_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pro_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pro_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pro_diet.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 58,
                title: 'Cat Food',
                slug: 'catfood',
            },
        ],
    },
    {
        id: 188,
        title: 'Purina Pro Plan Veterinary Diets',
        slug: 'purina_pro_plan',
        unit: ' 6 lb',
        price: 25,
        salePrice: 22.5,
        discountInPercent: 10,
        description: 'Cat food is food specifically formulated and intended for consumption by cats and other related canines. Like all carnivores, cats have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/purina_pro_plan.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/purina_pro_plan.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/purina_pro_plan.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/purina_pro_plan.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/purina_pro_plan.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 58,
                title: 'Cat Food',
                slug: 'catfood',
            },
        ],
    },
    {
        id: 189,
        title: 'Special Kitty Gourmet',
        slug: 'special_kitty',
        unit: ' 5 lb',
        price: 30,
        salePrice: 24,
        discountInPercent: 20,
        description: 'Cat food is food specifically formulated and intended for consumption by cats and other related canines. Like all carnivores, cats have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/special_kitty.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/special_kitty.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/special_kitty.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/special_kitty.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/special_kitty.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 58,
                title: 'Cat Food',
                slug: 'catfood',
            },
        ],
    },
    {
        id: 190,
        title: 'Black Hawk Adult Lamb Rice',
        slug: 'dog_food_black_hawk_adult_all_breeds_lamb&rice',
        unit: ' 5 lb',
        price: 30,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Dog food is food specifically formulated and intended for consumption by dogs and other related canines. Like all carnivores, dogs have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_adult_all_breeds_lamb&rice.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_adult_all_breeds_lamb&rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_adult_all_breeds_lamb&rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_adult_all_breeds_lamb&rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_adult_all_breeds_lamb&rice.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 59,
                title: 'Dog Food',
                slug: 'dogfood',
            },
        ],
    },
    {
        id: 191,
        title: 'Black Hawk Puppy Lamb Rice',
        slug: 'dog_food_black_hawk_puppy_all_breeds_lamb&rice',
        unit: ' 5 lb',
        price: 30,
        salePrice: 27,
        discountInPercent: 10,
        description: 'Dog food is food specifically formulated and intended for consumption by dogs and other related canines. Like all carnivores, dogs have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_puppy_all_breeds_lamb&rice.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_puppy_all_breeds_lamb&rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_puppy_all_breeds_lamb&rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_puppy_all_breeds_lamb&rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_puppy_all_breeds_lamb&rice.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 59,
                title: 'Dog Food',
                slug: 'dogfood',
            },
        ],
    },
    {
        id: 192,
        title: 'Black Hawk Puppy Chicken Rice',
        slug: 'dog_food_black_hawk_puppy_large_breedchicken&rice',
        unit: ' 5 lb',
        price: 30,
        salePrice: 27,
        discountInPercent: 10,
        description: 'Dog food is food specifically formulated and intended for consumption by dogs and other related canines. Like all carnivores, dogs have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_puppy_large_breedchicken&rice.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_puppy_large_breedchicken&rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_puppy_large_breedchicken&rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_puppy_large_breedchicken&rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_black_hawk_puppy_large_breedchicken&rice.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 59,
                title: 'Dog Food',
                slug: 'dogfood',
            },
        ],
    },
    {
        id: 193,
        title: 'Blain S Farm Fleet',
        slug: "dog_food_blain's_farm&fleet",
        unit: ' 5 lb',
        price: 25,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Dog food is food specifically formulated and intended for consumption by dogs and other related canines. Like all carnivores, dogs have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: "https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_blain's_farm&fleet.jpg",
        gallery: [
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_blain's_farm&fleet.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_blain's_farm&fleet.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_blain's_farm&fleet.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_blain's_farm&fleet.jpg",
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 59,
                title: 'Dog Food',
                slug: 'dogfood',
            },
        ],
    },
    {
        id: 194,
        title: 'Blue Wilderness Chicken Recepie',
        slug: 'dog_food_blue_wilderness',
        unit: ' 5 lb',
        price: 25,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Dog food is food specifically formulated and intended for consumption by dogs and other related canines. Like all carnivores, dogs have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_blue_wilderness.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_blue_wilderness.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_blue_wilderness.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_blue_wilderness.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_blue_wilderness.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 59,
                title: 'Dog Food',
                slug: 'dogfood',
            },
        ],
    },
    {
        id: 195,
        title: 'Whole Hearted Beef Pea Recepie',
        slug: 'dog_food_whole_hearted_beef&pea',
        unit: ' 8 lb',
        price: 40,
        salePrice: 32,
        discountInPercent: 20,
        description: 'Dog food is food specifically formulated and intended for consumption by dogs and other related canines. Like all carnivores, dogs have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_whole_hearted_beef&pea.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_whole_hearted_beef&pea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_whole_hearted_beef&pea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_whole_hearted_beef&pea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_whole_hearted_beef&pea.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 59,
                title: 'Dog Food',
                slug: 'dogfood',
            },
        ],
    },
    {
        id: 196,
        title: 'Whole Hearted Salmon Pea Recepie',
        slug: 'dog_food_whole_hearted_salmon&pea',
        unit: ' 8 lb',
        price: 40,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Dog food is food specifically formulated and intended for consumption by dogs and other related canines. Like all carnivores, dogs have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_whole_hearted_salmon&pea.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_whole_hearted_salmon&pea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_whole_hearted_salmon&pea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_whole_hearted_salmon&pea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_whole_hearted_salmon&pea.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 59,
                title: 'Dog Food',
                slug: 'dogfood',
            },
        ],
    },
    {
        id: 197,
        title: 'Zenith Lamb Brown Rice',
        slug: 'dog_food_zenith',
        unit: ' 8 lb',
        price: 40,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Dog food is food specifically formulated and intended for consumption by dogs and other related canines. Like all carnivores, dogs have sharp, pointed teeth, and have short gastrointestinal tracts better suited for the consumption of meat than of vegetable substances.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_zenith.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_zenith.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_zenith.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_zenith.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dog_food_zenith.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 59,
                title: 'Dog Food',
                slug: 'dogfood',
            },
        ],
    },
    {
        id: 198,
        title: 'Applaws Grain Free',
        slug: 'kitten_food_applaws_adult_cat_chicken',
        unit: ' 8 lb',
        price: 40,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_applaws_adult_cat_chicken.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_applaws_adult_cat_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_applaws_adult_cat_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_applaws_adult_cat_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_applaws_adult_cat_chicken.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 199,
        title: 'Authority Chicken Rice Formula',
        slug: 'kitten_food_authority_real_chicken',
        unit: ' 5 lb',
        price: 30,
        salePrice: 27,
        discountInPercent: 10,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_authority_real_chicken.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_authority_real_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_authority_real_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_authority_real_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_authority_real_chicken.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 199,
        title: 'Blue Wilderness Chicken Recepie',
        slug: 'kitten_food_blue_wilderness_chicken',
        unit: ' 5 lb',
        price: 30,
        salePrice: 27,
        discountInPercent: 10,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_blue_wilderness_chicken.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_blue_wilderness_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_blue_wilderness_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_blue_wilderness_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_blue_wilderness_chicken.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 200,
        title: 'Hills Ideal Balance',
        slug: "kitten_food_hill's_ideal_balance_over1year_kitten",
        unit: ' 5 lb',
        price: 30,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: "https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_hill's_ideal_balance_over1year_kitten.jpg",
        gallery: [
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_hill's_ideal_balance_over1year_kitten.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_hill's_ideal_balance_over1year_kitten.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_hill's_ideal_balance_over1year_kitten.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_hill's_ideal_balance_over1year_kitten.jpg",
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 201,
        title: 'Hills Science Diet',
        slug: "kitten_food_hill's_science_diet_upto1year_kitten",
        unit: ' 5 lb',
        price: 30,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: "https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_hill's_science_diet_upto1year_kitten.jpg",
        gallery: [
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_hill's_science_diet_upto1year_kitten.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_hill's_science_diet_upto1year_kitten.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_hill's_science_diet_upto1year_kitten.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_hill's_science_diet_upto1year_kitten.jpg",
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 202,
        title: 'Iams Proactive Health',
        slug: 'kitten_food_iamso_upto1year',
        unit: ' 5 lb',
        price: 20,
        salePrice: 16,
        discountInPercent: 20,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_iamso_upto1year.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_iamso_upto1year.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_iamso_upto1year.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_iamso_upto1year.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_iamso_upto1year.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 203,
        title: 'Instinct Original Grain Free',
        slug: 'kitten_food_instinct_chicken',
        unit: ' 5 lb',
        price: 20,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_instinct_chicken.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_instinct_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_instinct_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_instinct_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_instinct_chicken.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 204,
        title: 'Merrick Purrfect Bistro Grain Free',
        slug: 'kitten_food_merrick',
        unit: ' 5 lb',
        price: 25,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_merrick.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_merrick.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_merrick.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_merrick.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_merrick.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 204,
        title: 'Merrick Purrfect Bistro Grain Free',
        slug: 'kitten_food_merrick',
        unit: ' 5 lb',
        price: 25,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_merrick.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_merrick.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_merrick.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_merrick.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_merrick.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 205,
        title: 'Purina Healthy Kitten Formula',
        slug: 'kitten_food_purina_one',
        unit: ' 5 lb',
        price: 25,
        salePrice: 22.5,
        discountInPercent: 10,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_purina_one.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_purina_one.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_purina_one.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_purina_one.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_purina_one.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 206,
        title: 'Purina Pro Plan',
        slug: 'kitten_food_purina_proplan',
        unit: ' 4 lb',
        price: 35,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_purina_proplan.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_purina_proplan.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_purina_proplan.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_purina_proplan.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_purina_proplan.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 207,
        title: 'Wellness Complete Health',
        slug: 'kitten_food_wellness',
        unit: ' 6 lb',
        price: 35,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_wellness.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_wellness.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_wellness.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_wellness.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_wellness.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 208,
        title: 'Whiskas',
        slug: 'kitten_food_whiskas_real_chicken',
        unit: ' 4 lb',
        price: 35,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitten food is food for consumption by kitten. Kittens have specific requirements for their dietary nutrients. Certain nutrients, including many vitamins and amino acids, are degraded by the temperatures, pressures and chemical treatments used during manufacture, and hence must be added after manufacture to avoid nutritional deficiency.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_whiskas_real_chicken.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_whiskas_real_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_whiskas_real_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_whiskas_real_chicken.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitten_food_whiskas_real_chicken.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 60,
                title: 'Kitten Food',
                slug: 'kittenfood',
            },
        ],
    },
    {
        id: 209,
        title: 'Black Winter Cap',
        slug: 'pet_accessories_black_winter_cap',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_black_winter_cap.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_black_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_black_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_black_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_black_winter_cap.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 210,
        title: 'Brown White Winter Cap',
        slug: 'pet_accessories_brown&white_winter_cap',
        unit: ' 1 pc(s)',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_brown&white_winter_cap.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_brown&white_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_brown&white_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_brown&white_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_brown&white_winter_cap.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 211,
        title: 'Brown White Winter Cap',
        slug: 'pet_accessories_brown&white_winter_cap',
        unit: ' 1 pc(s)',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_brown&white_winter_cap.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_brown&white_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_brown&white_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_brown&white_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_brown&white_winter_cap.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 212,
        title: 'Batman Cap',
        slug: 'pet_accessories_costume1',
        unit: ' 1 pc(s)',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_costume1.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_costume1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_costume1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_costume1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_costume1.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 213,
        title: 'Skeleton Cap',
        slug: 'pet_accessories_costume2',
        unit: ' 1 pc(s)',
        price: 3,
        salePrice: 2.7,
        discountInPercent: 10,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_costume2.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_costume2.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_costume2.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_costume2.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_costume2.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 214,
        title: 'Ash Jacket',
        slug: 'pet_accessories_jacket_ash',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_jacket_ash.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_jacket_ash.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_jacket_ash.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_jacket_ash.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_jacket_ash.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 215,
        title: 'Red Jacket',
        slug: 'pet_accessories_jacket_red',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_jacket_red.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_jacket_red.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_jacket_red.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_jacket_red.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_jacket_red.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 216,
        title: 'Cat Bed Brown',
        slug: 'pet_accessories_pet_bed1',
        unit: ' 1 pc(s)',
        price: 15,
        salePrice: 12,
        discountInPercent: 20,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed1.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed1.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 217,
        title: 'Cat Bed Grey',
        slug: 'pet_accessories_pet_bed2',
        unit: ' 1 pc(s)',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed2.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed2.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed2.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed2.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed2.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 219,
        title: 'Dog Bed Brown Red',
        slug: 'pet_accessories_pet_bed4',
        unit: ' 1 pc(s)',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed4.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed4.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed4.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed4.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_pet_bed4.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 220,
        title: 'Red White Christmas Sweater',
        slug: 'pet_accessories_red&white_christmas_sweater',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red&white_christmas_sweater.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red&white_christmas_sweater.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red&white_christmas_sweater.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red&white_christmas_sweater.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red&white_christmas_sweater.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 221,
        title: 'Red White Jumper',
        slug: 'pet_accessories_red&white_jumper1',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red&white_jumper1.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red&white_jumper1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red&white_jumper1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red&white_jumper1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red&white_jumper1.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 224,
        title: 'Red Christmas Winter Cap',
        slug: 'pet_accessories_red_crhistmas_winter_cap',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red_crhistmas_winter_cap.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red_crhistmas_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red_crhistmas_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red_crhistmas_winter_cap.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_red_crhistmas_winter_cap.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 225,
        title: 'Rainbow Tie',
        slug: 'pet_accessories_tie',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 1.5,
        discountInPercent: 25,
        description: 'A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_tie.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_tie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_tie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_tie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pet_accessories_tie.jpg',
            },
        ],
        categories: [
            {
                id: 57,
                title: 'Pet Care',
                slug: 'petcare',
            },
            {
                id: 61,
                title: 'Pet Accessories',
                slug: 'petaccessories',
            },
        ],
    },
    {
        id: 226,
        title: 'Ozmo Air Fabric Freshner',
        slug: 'air_freshner_acana_ozmo',
        unit: ' 1 pc(s)',
        price: 20,
        salePrice: 15,
        discountInPercent: 25,
        description: 'Air fresheners are consumer products that typically emit fragrance and are used in homes or commercial interiors such as restrooms, foyers, hallways, vestibules and other smaller indoor areas, as well as larger areas such as hotel lobbies, auto dealerships, medical facilities, public arenas and other large interior spaces. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_acana_ozmo.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_acana_ozmo.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_acana_ozmo.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_acana_ozmo.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_acana_ozmo.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 63,
                title: 'Air Freshner',
                slug: 'air_freshner',
            },
        ],
    },
    {
        id: 227,
        title: 'Air Wick Essential Oils',
        slug: 'air_freshner_air_wick_essential_oils',
        unit: ' 1 pc(s)',
        price: 20,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Air fresheners are consumer products that typically emit fragrance and are used in homes or commercial interiors such as restrooms, foyers, hallways, vestibules and other smaller indoor areas, as well as larger areas such as hotel lobbies, auto dealerships, medical facilities, public arenas and other large interior spaces. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_air_wick_essential_oils.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_air_wick_essential_oils.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_air_wick_essential_oils.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_air_wick_essential_oils.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_air_wick_essential_oils.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 63,
                title: 'Air Freshner',
                slug: 'air_freshner',
            },
        ],
    },
    {
        id: 228,
        title: 'Ambi Pur Air',
        slug: 'air_freshner_ambipur_air_sweet_citrus&zest',
        unit: ' 1 pc(s)',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Air fresheners are consumer products that typically emit fragrance and are used in homes or commercial interiors such as restrooms, foyers, hallways, vestibules and other smaller indoor areas, as well as larger areas such as hotel lobbies, auto dealerships, medical facilities, public arenas and other large interior spaces. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_ambipur_air_sweet_citrus&zest.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_ambipur_air_sweet_citrus&zest.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_ambipur_air_sweet_citrus&zest.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_ambipur_air_sweet_citrus&zest.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_ambipur_air_sweet_citrus&zest.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 63,
                title: 'Air Freshner',
                slug: 'air_freshner',
            },
        ],
    },
    {
        id: 229,
        title: 'Broadwalk Air Freshner',
        slug: 'air_freshner_boardwalk_mango',
        unit: ' .5 lb',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Air fresheners are consumer products that typically emit fragrance and are used in homes or commercial interiors such as restrooms, foyers, hallways, vestibules and other smaller indoor areas, as well as larger areas such as hotel lobbies, auto dealerships, medical facilities, public arenas and other large interior spaces. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_boardwalk_mango.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_boardwalk_mango.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_boardwalk_mango.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_boardwalk_mango.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_boardwalk_mango.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 63,
                title: 'Air Freshner',
                slug: 'air_freshner',
            },
        ],
    },
    {
        id: 230,
        title: 'Febreze Air Bora Bora Waters',
        slug: 'air_freshner_febreze_air_bora_bora',
        unit: ' 1 pc(s)',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Air fresheners are consumer products that typically emit fragrance and are used in homes or commercial interiors such as restrooms, foyers, hallways, vestibules and other smaller indoor areas, as well as larger areas such as hotel lobbies, auto dealerships, medical facilities, public arenas and other large interior spaces. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_bora_bora.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_bora_bora.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_bora_bora.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_bora_bora.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_bora_bora.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 63,
                title: 'Air Freshner',
                slug: 'air_freshner',
            },
        ],
    },
    {
        id: 231,
        title: 'Febreze Air Effects',
        slug: 'air_freshner_febreze_air_effects',
        unit: '  1 pc(s)',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Air fresheners are consumer products that typically emit fragrance and are used in homes or commercial interiors such as restrooms, foyers, hallways, vestibules and other smaller indoor areas, as well as larger areas such as hotel lobbies, auto dealerships, medical facilities, public arenas and other large interior spaces. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_effects.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_effects.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_effects.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_effects.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_effects.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 63,
                title: 'Air Freshner',
                slug: 'air_freshner',
            },
        ],
    },
    {
        id: 232,
        title: 'Febreze Air Hawaiian',
        slug: 'air_freshner_febreze_air_hawaiian',
        unit: ' 1 pc(s)',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Air fresheners are consumer products that typically emit fragrance and are used in homes or commercial interiors such as restrooms, foyers, hallways, vestibules and other smaller indoor areas, as well as larger areas such as hotel lobbies, auto dealerships, medical facilities, public arenas and other large interior spaces. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_hawaiian.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_hawaiian.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_hawaiian.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_hawaiian.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_febreze_air_hawaiian.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 63,
                title: 'Air Freshner',
                slug: 'air_freshner',
            },
        ],
    },
    {
        id: 233,
        title: 'Glade Blue Odssey',
        slug: 'air_freshner_sc_johnson_glade_blue_odyssey',
        unit: '1 pc(s)',
        price: 18,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Air fresheners are consumer products that typically emit fragrance and are used in homes or commercial interiors such as restrooms, foyers, hallways, vestibules and other smaller indoor areas, as well as larger areas such as hotel lobbies, auto dealerships, medical facilities, public arenas and other large interior spaces. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_sc_johnson_glade_blue_odyssey.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_sc_johnson_glade_blue_odyssey.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_sc_johnson_glade_blue_odyssey.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_sc_johnson_glade_blue_odyssey.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_sc_johnson_glade_blue_odyssey.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 63,
                title: 'Air Freshner',
                slug: 'air_freshner',
            },
        ],
    },
    {
        id: 234,
        title: 'Glade Clean Linen',
        slug: 'air_freshner_sc_johnson_glade_clean_linen',
        unit: ' 1 pc(s)',
        price: 20,
        salePrice: 18,
        discountInPercent: 10,
        description: 'Air fresheners are consumer products that typically emit fragrance and are used in homes or commercial interiors such as restrooms, foyers, hallways, vestibules and other smaller indoor areas, as well as larger areas such as hotel lobbies, auto dealerships, medical facilities, public arenas and other large interior spaces. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_sc_johnson_glade_clean_linen.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_sc_johnson_glade_clean_linen.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_sc_johnson_glade_clean_linen.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_sc_johnson_glade_clean_linen.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_sc_johnson_glade_clean_linen.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 63,
                title: 'Air Freshner',
                slug: 'air_freshner',
            },
        ],
    },
    {
        id: 235,
        title: 'Vibrant Air Freshner',
        slug: 'air_freshner_vibrant_linen',
        unit: ' 1 pc(s)',
        price: 20,
        salePrice: 18,
        discountInPercent: 10,
        description: 'Air fresheners are consumer products that typically emit fragrance and are used in homes or commercial interiors such as restrooms, foyers, hallways, vestibules and other smaller indoor areas, as well as larger areas such as hotel lobbies, auto dealerships, medical facilities, public arenas and other large interior spaces. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_vibrant_linen.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_vibrant_linen.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_vibrant_linen.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_vibrant_linen.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/air_freshner_vibrant_linen.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 63,
                title: 'Air Freshner',
                slug: 'air_freshner',
            },
        ],
    },
    {
        id: 236,
        title: 'Ajax Floor Cleaner',
        slug: 'cleaning_supply_ajax',
        unit: ' 1 pc(s)',
        price: 20,
        salePrice: 18,
        discountInPercent: 10,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_ajax.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_ajax.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_ajax.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_ajax.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_ajax.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 64,
                title: 'Cleaning Products',
                slug: 'cleanin_products',
            },
        ],
    },
    {
        id: 237,
        title: 'Astonish Oven Power Spray',
        slug: 'cleaning_supply_astonish',
        unit: ' 1 pc(s)',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_astonish.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_astonish.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_astonish.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_astonish.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_astonish.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 64,
                title: 'Cleaning Products',
                slug: 'cleanin_products',
            },
        ],
    },
    {
        id: 238,
        title: 'Bio Toilet Cleaner',
        slug: 'cleaning_supply_bio',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 8,
        discountInPercent: 20,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_bio.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_bio.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_bio.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_bio.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_bio.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 64,
                title: 'Cleaning Products',
                slug: 'cleanin_products',
            },
        ],
    },
    {
        id: 239,
        title: 'Bona Hardwood Floor Cleaner',
        slug: 'cleaning_supply_bona',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_bona.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_bona.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_bona.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_bona.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_bona.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 64,
                title: 'Cleaning Products',
                slug: 'cleanin_products',
            },
        ],
    },
    {
        id: 240,
        title: 'Ever Spring Glass Cleaner',
        slug: 'cleaning_supply_ever_spring',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_ever_spring.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_ever_spring.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_ever_spring.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_ever_spring.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_ever_spring.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 64,
                title: 'Cleaning Products',
                slug: 'cleanin_products',
            },
        ],
    },
    {
        id: 241,
        title: 'Glitz Outdoor Glass Cleaner',
        slug: 'cleaning_supply_glitz_glass_cleaner',
        unit: ' 1 pc(s)',
        price: 40,
        salePrice: 36,
        discountInPercent: 10,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_glitz_glass_cleaner.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_glitz_glass_cleaner.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_glitz_glass_cleaner.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_glitz_glass_cleaner.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_glitz_glass_cleaner.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 64,
                title: 'Cleaning Products',
                slug: 'cleanin_products',
            },
        ],
    },
    {
        id: 242,
        title: 'Glitz Bbq Stainless Steel Cleaner',
        slug: 'cleaning_supply_glitz_steel_cleaner',
        unit: ' 1 pc(s)',
        price: 20,
        salePrice: 16,
        discountInPercent: 10,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_glitz_steel_cleaner.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_glitz_steel_cleaner.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_glitz_steel_cleaner.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_glitz_steel_cleaner.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_glitz_steel_cleaner.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 64,
                title: 'Cleaning Products',
                slug: 'cleanin_products',
            },
        ],
    },
    {
        id: 243,
        title: 'Method All Purpose Cleaner',
        slug: 'cleaning_supply_method',
        unit: ' 1 pc(s)',
        price: 20,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_method.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_method.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_method.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_method.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/cleaning_supply_method.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 64,
                title: 'Cleaning Products',
                slug: 'cleanin_products',
            },
        ],
    },
    {
        id: 244,
        title: 'Dawn',
        slug: 'dishwasher_dawn_la_faune',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_dawn_la_faune.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_dawn_la_faune.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_dawn_la_faune.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_dawn_la_faune.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_dawn_la_faune.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 65,
                title: 'Dishwasher',
                slug: 'dishwasher',
            },
        ],
    },
    {
        id: 245,
        title: 'Dawn Escapes',
        slug: 'dishwasher_dawn_new_zealand_spring_scent',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 4.5,
        discountInPercent: 10,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_dawn_new_zealand_spring_scent.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_dawn_new_zealand_spring_scent.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_dawn_new_zealand_spring_scent.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_dawn_new_zealand_spring_scent.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_dawn_new_zealand_spring_scent.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 65,
                title: 'Dishwasher',
                slug: 'dishwasher',
            },
        ],
    },
    {
        id: 246,
        title: 'Fairy Lemon',
        slug: 'dishwasher_fairy_lemon',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_fairy_lemon.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_fairy_lemon.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_fairy_lemon.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_fairy_lemon.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_fairy_lemon.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 65,
                title: 'Dishwasher',
                slug: 'dishwasher',
            },
        ],
    },
    {
        id: 247,
        title: 'Finish All In One',
        slug: 'dishwasher_finish',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_finish.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_finish.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_finish.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_finish.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_finish.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 65,
                title: 'Dishwasher',
                slug: 'dishwasher',
            },
        ],
    },
    {
        id: 248,
        title: 'Lemi Shine',
        slug: 'dishwasher_lemi_shine',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_lemi_shine.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_lemi_shine.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_lemi_shine.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_lemi_shine.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_lemi_shine.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 65,
                title: 'Dishwasher',
                slug: 'dishwasher',
            },
        ],
    },
    {
        id: 249,
        title: 'Seventh Generation',
        slug: 'dishwasher_seventh_generation_free&clear',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_seventh_generation_free&clear.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_seventh_generation_free&clear.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_seventh_generation_free&clear.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_seventh_generation_free&clear.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_seventh_generation_free&clear.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 65,
                title: 'Dishwasher',
                slug: 'dishwasher',
            },
        ],
    },
    {
        id: 251,
        title: 'Winni S Ammorbidente',
        slug: "dishwasher_winni's_fiori_bianchi",
        unit: ' 1 pc(s)',
        price: 6.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cleaning liquid (BrE: washing-up liquid), known as cleaning soap, cleaning detergent and dish soap, is a detergent or liquid used to assist in cleaning. It is usually a highly-foaming mixture of surfactants with low skin irritation ',
        type: 'grocery',
        image: "https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_winni's_fiori_bianchi.jpg",
        gallery: [
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_winni's_fiori_bianchi.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_winni's_fiori_bianchi.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_winni's_fiori_bianchi.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/dishwasher_winni's_fiori_bianchi.jpg",
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 65,
                title: 'Dishwasher',
                slug: 'dishwasher',
            },
        ],
    },
    {
        id: 252,
        title: 'Camp Cutlery',
        slug: 'kitchen_accessories_camp_cutlery',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 7.5,
        discountInPercent: 25,
        description: 'Kitchen accessories are the tools, utensils, appliances, dishes, and cookware used in food preparation, or the serving of food.Kitchen accessories can also be used to hold or store food before or after preparation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_camp_cutlery.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_camp_cutlery.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_camp_cutlery.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_camp_cutlery.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_camp_cutlery.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 66,
                title: 'Kitchen Accessories',
                slug: 'kitchenaccessories',
            },
        ],
    },
    {
        id: 253,
        title: 'Dinner Spoon',
        slug: 'kitchen_accessories_dinner_spoons',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 8,
        discountInPercent: 20,
        description: 'Kitchen accessories are the tools, utensils, appliances, dishes, and cookware used in food preparation, or the serving of food.Kitchen accessories can also be used to hold or store food before or after preparation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_dinner_spoons.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_dinner_spoons.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_dinner_spoons.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_dinner_spoons.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_dinner_spoons.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 66,
                title: 'Kitchen Accessories',
                slug: 'kitchenaccessories',
            },
        ],
    },
    {
        id: 254,
        title: 'Ice Cream Scoop',
        slug: 'kitchen_accessories_ice_cream_scoop',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitchen accessories are the tools, utensils, appliances, dishes, and cookware used in food preparation, or the serving of food.Kitchen accessories can also be used to hold or store food before or after preparation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_ice_cream_scoop.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_ice_cream_scoop.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_ice_cream_scoop.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_ice_cream_scoop.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_ice_cream_scoop.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 66,
                title: 'Kitchen Accessories',
                slug: 'kitchenaccessories',
            },
        ],
    },
    {
        id: 255,
        title: 'Kitchen Tools',
        slug: 'kitchen_accessories_kitchen_tools',
        unit: ' 1 pc(s)',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitchen accessories are the tools, utensils, appliances, dishes, and cookware used in food preparation, or the serving of food.Kitchen accessories can also be used to hold or store food before or after preparation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_kitchen_tools.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_kitchen_tools.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_kitchen_tools.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_kitchen_tools.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_kitchen_tools.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 66,
                title: 'Kitchen Accessories',
                slug: 'kitchenaccessories',
            },
        ],
    },
    {
        id: 256,
        title: 'Mesh Strainers',
        slug: 'kitchen_accessories_mash_strainers',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitchen accessories are the tools, utensils, appliances, dishes, and cookware used in food preparation, or the serving of food.Kitchen accessories can also be used to hold or store food before or after preparation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_mash_strainers.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_mash_strainers.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_mash_strainers.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_mash_strainers.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_mash_strainers.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 66,
                title: 'Kitchen Accessories',
                slug: 'kitchenaccessories',
            },
        ],
    },
    {
        id: 257,
        title: 'Skimmers',
        slug: 'kitchen_accessories_skimmer',
        unit: ' 1 pc(s)',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitchen accessories are the tools, utensils, appliances, dishes, and cookware used in food preparation, or the serving of food.Kitchen accessories can also be used to hold or store food before or after preparation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_skimmer.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_skimmer.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_skimmer.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_skimmer.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_skimmer.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 66,
                title: 'Kitchen Accessories',
                slug: 'kitchenaccessories',
            },
        ],
    },
    {
        id: 258,
        title: 'Solid Turner',
        slug: 'kitchen_accessories_solid_turner',
        unit: ' 1 pc(s)',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Kitchen accessories are the tools, utensils, appliances, dishes, and cookware used in food preparation, or the serving of food.Kitchen accessories can also be used to hold or store food before or after preparation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_solid_turner.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_solid_turner.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_solid_turner.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_solid_turner.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kitchen_accessories_solid_turner.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 66,
                title: 'Kitchen Accessories',
                slug: 'kitchenaccessories',
            },
        ],
    },
    {
        id: 259,
        title: 'Woolite Gentle Cycle',
        slug: 'laundry_products_woolite',
        unit: ' 1 pc(s)',
        price: 30,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_woolite.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_woolite.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_woolite.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_woolite.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_woolite.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 260,
        title: 'Tide Downy',
        slug: 'laundry_products_tide',
        unit: ' 4 lb',
        price: 30,
        salePrice: 21,
        discountInPercent: 30,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_tide.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_tide.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_tide.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_tide.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_tide.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 261,
        title: 'Seventh Generation Sensative Skin',
        slug: 'laundry_products_seventh_generation',
        unit: ' 4 lb',
        price: 30,
        salePrice: 24,
        discountInPercent: 20,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_seventh_generation.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_seventh_generation.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_seventh_generation.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_seventh_generation.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_seventh_generation.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 262,
        title: 'Safe Wash',
        slug: 'laundry_products_safe_wash',
        unit: ' 4 lb',
        price: 30,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_safe_wash.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_safe_wash.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_safe_wash.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_safe_wash.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_safe_wash.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 263,
        title: 'Purex Oxi',
        slug: 'laundry_products_purex',
        unit: ' 4 lb',
        price: 25,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_purex.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_purex.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_purex.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_purex.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_purex.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 264,
        title: 'Persil Power Gel',
        slug: 'laundry_products_persil',
        unit: ' 4 lb',
        price: 35,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_persil.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_persil.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_persil.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_persil.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_persil.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 265,
        title: 'Home Made Simple',
        slug: 'laundry_products_home_made_simple',
        unit: ' 4 lb',
        price: 18,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_home_made_simple.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_home_made_simple.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_home_made_simple.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_home_made_simple.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_home_made_simple.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 266,
        title: 'Ecos',
        slug: 'laundry_products_ecos',
        unit: ' 4 lb',
        price: 28,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_ecos.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_ecos.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_ecos.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_ecos.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_ecos.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 267,
        title: 'Daz',
        slug: 'laundry_products_daz',
        unit: ' 6 lb',
        price: 32,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_daz.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_daz.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_daz.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_daz.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_daz.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 268,
        title: 'Chomel Baby Laundry Detergent',
        slug: 'laundry_products_chomel',
        unit: ' 6 lb',
        price: 50,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_chomel.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_chomel.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_chomel.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_chomel.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_chomel.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 269,
        title: 'Chicco Baby Laundry Detergent',
        slug: 'laundry_products_chicco',
        unit: ' 6 lb',
        price: 48,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_chicco.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_chicco.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_chicco.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_chicco.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_chicco.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 270,
        title: 'Babyhug Baby Laundry Detergent',
        slug: 'laundry_products_babyhug',
        unit: ' 6 lb',
        price: 40,
        salePrice: 36,
        discountInPercent: 10,
        description: 'Laundry detergent, or washing powder, is a type of detergent (cleaning agent) that is added for cleaning laundry. While detergent is still sold in powdered form, liquid detergents have been taking major market shares in many countries since their introduction in the 1950s.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_babyhug.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_babyhug.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_babyhug.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_babyhug.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/laundry_products_babyhug.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 67,
                title: 'Laundry',
                slug: 'laundry',
            },
        ],
    },
    {
        id: 271,
        title: 'Doff Insect Killer',
        slug: 'pest_control_doff',
        unit: ' 2 lb',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Pest control is the regulation or management of a species defined as a pest, a member of the animal kingdom that impacts adversely on human activities. The human response depends on the importance of the damage done, and will range from tolerance, through deterrence and management, to attempts to completely eradicate the pest.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_doff.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_doff.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_doff.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_doff.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_doff.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 68,
                title: 'Pest Control',
                slug: 'Pest_control',
            },
        ],
    },
    {
        id: 272,
        title: 'Homepest Control',
        slug: 'pest_control_eco_deffence_home_pest_control',
        unit: ' 2 lb',
        price: 15,
        salePrice: 12,
        discountInPercent: 20,
        description: 'Pest control is the regulation or management of a species defined as a pest, a member of the animal kingdom that impacts adversely on human activities. The human response depends on the importance of the damage done, and will range from tolerance, through deterrence and management, to attempts to completely eradicate the pest.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_eco_deffence_home_pest_control.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_eco_deffence_home_pest_control.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_eco_deffence_home_pest_control.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_eco_deffence_home_pest_control.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_eco_deffence_home_pest_control.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 68,
                title: 'Pest Control',
                slug: 'Pest_control',
            },
        ],
    },
    {
        id: 274,
        title: 'Professional Moth Killer',
        slug: 'pest_control_kritterkill',
        unit: ' 2 lb',
        price: 12,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Pest control is the regulation or management of a species defined as a pest, a member of the animal kingdom that impacts adversely on human activities. The human response depends on the importance of the damage done, and will range from tolerance, through deterrence and management, to attempts to completely eradicate the pest.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_kritterkill.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_kritterkill.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_kritterkill.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_kritterkill.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_kritterkill.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 68,
                title: 'Pest Control',
                slug: 'Pest_control',
            },
        ],
    },
    {
        id: 275,
        title: 'Naturally Green No More Bugs',
        slug: 'pest_control_naturally_green',
        unit: ' 2 lb',
        price: 18,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Pest control is the regulation or management of a species defined as a pest, a member of the animal kingdom that impacts adversely on human activities. The human response depends on the importance of the damage done, and will range from tolerance, through deterrence and management, to attempts to completely eradicate the pest.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_naturally_green.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_naturally_green.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_naturally_green.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_naturally_green.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_naturally_green.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 68,
                title: 'Pest Control',
                slug: 'Pest_control',
            },
        ],
    },
    {
        id: 276,
        title: 'Raid Ant Roach Barrier',
        slug: 'pest_control_sc_johnson_raid',
        unit: ' 2 lb',
        price: 18,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Pest control is the regulation or management of a species defined as a pest, a member of the animal kingdom that impacts adversely on human activities. The human response depends on the importance of the damage done, and will range from tolerance, through deterrence and management, to attempts to completely eradicate the pest.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_sc_johnson_raid.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_sc_johnson_raid.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_sc_johnson_raid.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_sc_johnson_raid.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_sc_johnson_raid.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 68,
                title: 'Pest Control',
                slug: 'Pest_control',
            },
        ],
    },
    {
        id: 277,
        title: 'Wondercide Indoor Pest Control',
        slug: 'pest_control_wondercide_indoor_pest_control',
        unit: ' 2 lb',
        price: 20,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Pest control is the regulation or management of a species defined as a pest, a member of the animal kingdom that impacts adversely on human activities. The human response depends on the importance of the damage done, and will range from tolerance, through deterrence and management, to attempts to completely eradicate the pest.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_wondercide_indoor_pest_control.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_wondercide_indoor_pest_control.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_wondercide_indoor_pest_control.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_wondercide_indoor_pest_control.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pest_control_wondercide_indoor_pest_control.jpg',
            },
        ],
        categories: [
            {
                id: 62,
                title: 'Home Cleaning',
                slug: 'home-cleaning',
            },
            {
                id: 68,
                title: 'Pest Control',
                slug: 'Pest_control',
            },
        ],
    },
    {
        id: 278,
        title: 'Barney Butter',
        slug: 'butter_barney',
        unit: ' 1 pc(s)',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Butter is a dairy product with high butterfat content which is solid when chilled and at room temperature in some regions, and liquid when warmed. It is made by churning fresh or fermented cream or milk to separate the butterfat from the buttermilk.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_barney.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_barney.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_barney.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_barney.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_barney.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 70,
                title: 'Butter',
                slug: 'butter',
            },
        ],
    },
    {
        id: 280,
        title: 'Kirkland Signature',
        slug: 'butter_kirkland',
        unit: ' 1 pc(s)',
        price: 9,
        salePrice: 8.1,
        discountInPercent: 10,
        description: 'Butter is a dairy product with high butterfat content which is solid when chilled and at room temperature in some regions, and liquid when warmed. It is made by churning fresh or fermented cream or milk to separate the butterfat from the buttermilk.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_kirkland.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_kirkland.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_kirkland.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_kirkland.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_kirkland.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 70,
                title: 'Butter',
                slug: 'butter',
            },
        ],
    },
    {
        id: 281,
        title: 'Nature S Tattva Shea Butter',
        slug: "butter_nature's_tattva",
        unit: ' 1 pc(s)',
        price: 9,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Butter is a dairy product with high butterfat content which is solid when chilled and at room temperature in some regions, and liquid when warmed. It is made by churning fresh or fermented cream or milk to separate the butterfat from the buttermilk.',
        type: 'grocery',
        image: "https://s3.amazonaws.com/redqteam.com/pickbazar/butter_nature's_tattva.jpg",
        gallery: [
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/butter_nature's_tattva.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/butter_nature's_tattva.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/butter_nature's_tattva.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/butter_nature's_tattva.jpg",
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 70,
                title: 'Butter',
                slug: 'butter',
            },
        ],
    },
    {
        id: 282,
        title: 'Nikki S Coconut Butter',
        slug: "butter_nikki's",
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 7,
        discountInPercent: 30,
        description: 'Butter is a dairy product with high butterfat content which is solid when chilled and at room temperature in some regions, and liquid when warmed. It is made by churning fresh or fermented cream or milk to separate the butterfat from the buttermilk.',
        type: 'grocery',
        image: "https://s3.amazonaws.com/redqteam.com/pickbazar/butter_nikki's.jpg",
        gallery: [
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/butter_nikki's.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/butter_nikki's.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/butter_nikki's.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/butter_nikki's.jpg",
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 70,
                title: 'Butter',
                slug: 'butter',
            },
        ],
    },
    {
        id: 284,
        title: 'Tnuva Salted Butter',
        slug: 'butter_tnuva',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Butter is a dairy product with high butterfat content which is solid when chilled and at room temperature in some regions, and liquid when warmed. It is made by churning fresh or fermented cream or milk to separate the butterfat from the buttermilk.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_tnuva.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_tnuva.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_tnuva.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_tnuva.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_tnuva.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 70,
                title: 'Butter',
                slug: 'butter',
            },
        ],
    },
    {
        id: 285,
        title: 'Tru Nut Powdered Peanut Butter',
        slug: 'butter_tru_nut',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Butter is a dairy product with high butterfat content which is solid when chilled and at room temperature in some regions, and liquid when warmed. It is made by churning fresh or fermented cream or milk to separate the butterfat from the buttermilk.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_tru_nut.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_tru_nut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_tru_nut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_tru_nut.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_tru_nut.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 70,
                title: 'Butter',
                slug: 'butter',
            },
        ],
    },
    {
        id: 286,
        title: 'Whole Earth Crunchy Peanut Butter',
        slug: 'butter_whole_earth',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Butter is a dairy product with high butterfat content which is solid when chilled and at room temperature in some regions, and liquid when warmed. It is made by churning fresh or fermented cream or milk to separate the butterfat from the buttermilk.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_whole_earth.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_whole_earth.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_whole_earth.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_whole_earth.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/butter_whole_earth.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 70,
                title: 'Butter',
                slug: 'butter',
            },
        ],
    },
    {
        id: 287,
        title: 'Cavanagh Free Range Eggs',
        slug: 'egg_cavanagh',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The egg is the organic vessel containing the zygote in which an embryo develops until it can survive on its own, at which point the animal hatches. An egg results from fertilization of an egg cell. Most arthropods, vertebrates (excluding mammals), and mollusks lay eggs, although some, such as scorpions, do not.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_cavanagh.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_cavanagh.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_cavanagh.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_cavanagh.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_cavanagh.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 71,
                title: 'Egg',
                slug: 'egg',
            },
        ],
    },
    {
        id: 288,
        title: 'Clarence Court 6 Free Range Eggs',
        slug: 'egg_clarenece_court',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 1.8,
        discountInPercent: 10,
        description: 'The egg is the organic vessel containing the zygote in which an embryo develops until it can survive on its own, at which point the animal hatches. An egg results from fertilization of an egg cell. Most arthropods, vertebrates (excluding mammals), and mollusks lay eggs, although some, such as scorpions, do not.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_clarenece_court.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_clarenece_court.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_clarenece_court.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_clarenece_court.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_clarenece_court.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 71,
                title: 'Egg',
                slug: 'egg',
            },
        ],
    },
    {
        id: 289,
        title: 'Cp Omega',
        slug: 'egg_cp',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The egg is the organic vessel containing the zygote in which an embryo develops until it can survive on its own, at which point the animal hatches. An egg results from fertilization of an egg cell. Most arthropods, vertebrates (excluding mammals), and mollusks lay eggs, although some, such as scorpions, do not.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_cp.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_cp.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_cp.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_cp.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_cp.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 71,
                title: 'Egg',
                slug: 'egg',
            },
        ],
    },
    {
        id: 290,
        title: 'Freshlay Golden Yolks',
        slug: 'egg_freshlay_golden_yolks',
        unit: ' 1 pc(s)',
        price: 2.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The egg is the organic vessel containing the zygote in which an embryo develops until it can survive on its own, at which point the animal hatches. An egg results from fertilization of an egg cell. Most arthropods, vertebrates (excluding mammals), and mollusks lay eggs, although some, such as scorpions, do not.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_freshlay_golden_yolks.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_freshlay_golden_yolks.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_freshlay_golden_yolks.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_freshlay_golden_yolks.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_freshlay_golden_yolks.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 71,
                title: 'Egg',
                slug: 'egg',
            },
        ],
    },
    {
        id: 291,
        title: 'Golden Cage Free',
        slug: 'egg_gold_hen',
        unit: ' 1 pc(s)',
        price: 2.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The egg is the organic vessel containing the zygote in which an embryo develops until it can survive on its own, at which point the animal hatches. An egg results from fertilization of an egg cell. Most arthropods, vertebrates (excluding mammals), and mollusks lay eggs, although some, such as scorpions, do not.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_gold_hen.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_gold_hen.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_gold_hen.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_gold_hen.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_gold_hen.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 71,
                title: 'Egg',
                slug: 'egg',
            },
        ],
    },
    {
        id: 292,
        title: 'Happy Egg Organic',
        slug: 'egg_happy_egg_organic',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 3.6,
        discountInPercent: 10,
        description: 'The egg is the organic vessel containing the zygote in which an embryo develops until it can survive on its own, at which point the animal hatches. An egg results from fertilization of an egg cell. Most arthropods, vertebrates (excluding mammals), and mollusks lay eggs, although some, such as scorpions, do not.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_happy_egg_organic.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_happy_egg_organic.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_happy_egg_organic.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_happy_egg_organic.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_happy_egg_organic.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 71,
                title: 'Egg',
                slug: 'egg',
            },
        ],
    },
    {
        id: 293,
        title: 'Morrisons 6 Medium Free Range Golden Yolk Eggs',
        slug: 'egg_morrisons',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 3.6,
        discountInPercent: 10,
        description: 'The egg is the organic vessel containing the zygote in which an embryo develops until it can survive on its own, at which point the animal hatches. An egg results from fertilization of an egg cell. Most arthropods, vertebrates (excluding mammals), and mollusks lay eggs, although some, such as scorpions, do not.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_morrisons.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_morrisons.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_morrisons.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_morrisons.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_morrisons.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 71,
                title: 'Egg',
                slug: 'egg',
            },
        ],
    },
    {
        id: 294,
        title: 'Nutri Plus Telur Omega3',
        slug: 'egg_nutri_plus_telur_kampang_omega3',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The egg is the organic vessel containing the zygote in which an embryo develops until it can survive on its own, at which point the animal hatches. An egg results from fertilization of an egg cell. Most arthropods, vertebrates (excluding mammals), and mollusks lay eggs, although some, such as scorpions, do not.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_nutri_plus_telur_kampang_omega3.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_nutri_plus_telur_kampang_omega3.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_nutri_plus_telur_kampang_omega3.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_nutri_plus_telur_kampang_omega3.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/egg_nutri_plus_telur_kampang_omega3.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 71,
                title: 'Egg',
                slug: 'egg',
            },
        ],
    },
    {
        id: 295,
        title: 'Arla Lacto Free Semi Skimmed Milk',
        slug: 'milk_arla_lacto_free',
        unit: ' 2 lb',
        price: 4,
        salePrice: 3.6,
        discountInPercent: 10,
        description: 'Milk is a nutrient-rich, white liquid food produced by the mammary glands of mammals. It is the primary source of nutrition for infant mammals (including humans who are breastfed) before they are able to digest other types of food.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_arla_lacto_free.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_arla_lacto_free.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_arla_lacto_free.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_arla_lacto_free.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_arla_lacto_free.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 72,
                title: 'Milk',
                slug: 'milk',
            },
        ],
    },
    {
        id: 296,
        title: 'Arla All Natural Milk Goodness Low Fat',
        slug: 'milk_arla_low_fat1.5',
        unit: ' 2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Milk is a nutrient-rich, white liquid food produced by the mammary glands of mammals. It is the primary source of nutrition for infant mammals (including humans who are breastfed) before they are able to digest other types of food.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_arla_low_fat1.5.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_arla_low_fat1.5.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_arla_low_fat1.5.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_arla_low_fat1.5.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_arla_low_fat1.5.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 72,
                title: 'Milk',
                slug: 'milk',
            },
        ],
    },
    {
        id: 297,
        title: 'Magnolia Fresh Milk',
        slug: 'milk_f&n_magnolia.5',
        unit: ' 2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Milk is a nutrient-rich, white liquid food produced by the mammary glands of mammals. It is the primary source of nutrition for infant mammals (including humans who are breastfed) before they are able to digest other types of food.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_f&n_magnolia.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_f&n_magnolia.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_f&n_magnolia.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_f&n_magnolia.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_f&n_magnolia.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 72,
                title: 'Milk',
                slug: 'milk',
            },
        ],
    },
    {
        id: 298,
        title: 'Greenfields Chocolate Malt',
        slug: 'milk_greenfields_choco_malt_flavoured',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Milk is a nutrient-rich, white liquid food produced by the mammary glands of mammals. It is the primary source of nutrition for infant mammals (including humans who are breastfed) before they are able to digest other types of food.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_greenfields_choco_malt_flavoured.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_greenfields_choco_malt_flavoured.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_greenfields_choco_malt_flavoured.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_greenfields_choco_malt_flavoured.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_greenfields_choco_malt_flavoured.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 72,
                title: 'Milk',
                slug: 'milk',
            },
        ],
    },
    {
        id: 299,
        title: 'Heritage Special Long Life Milk',
        slug: 'milk_heritage',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Milk is a nutrient-rich, white liquid food produced by the mammary glands of mammals. It is the primary source of nutrition for infant mammals (including humans who are breastfed) before they are able to digest other types of food.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_heritage.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_heritage.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_heritage.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_heritage.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_heritage.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 72,
                title: 'Milk',
                slug: 'milk',
            },
        ],
    },
    {
        id: 300,
        title: 'Lactaid',
        slug: 'milk_lactaid',
        unit: ' 2 lb',
        price: 5,
        salePrice: 4.5,
        discountInPercent: 10,
        description: 'Milk is a nutrient-rich, white liquid food produced by the mammary glands of mammals. It is the primary source of nutrition for infant mammals (including humans who are breastfed) before they are able to digest other types of food.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_lactaid.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_lactaid.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_lactaid.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_lactaid.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_lactaid.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 72,
                title: 'Milk',
                slug: 'milk',
            },
        ],
    },
    {
        id: 301,
        title: 'Living Planet Organic Dairy Milk',
        slug: 'milk_living_planet',
        unit: ' 2 lb',
        price: 5,
        salePrice: 4.5,
        discountInPercent: 10,
        description: 'Milk is a nutrient-rich, white liquid food produced by the mammary glands of mammals. It is the primary source of nutrition for infant mammals (including humans who are breastfed) before they are able to digest other types of food.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_living_planet.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_living_planet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_living_planet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_living_planet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_living_planet.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 72,
                title: 'Milk',
                slug: 'milk',
            },
        ],
    },
    {
        id: 302,
        title: 'Betterhalf Original',
        slug: 'milk_cream_califia_farms',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Cream is a dairy product composed of the higher-fat layer skimmed from the top of milk before homogenization. In un-homogenized milk, the fat, which is less dense, eventually rises to the top.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_califia_farms.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_califia_farms.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_califia_farms.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_califia_farms.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_califia_farms.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 73,
                title: 'Milk Cream',
                slug: 'milk _cream',
            },
        ],
    },
    {
        id: 303,
        title: 'Clover Original Cream',
        slug: 'milk_cream_clover',
        unit: ' 2 lb',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'Cream is a dairy product composed of the higher-fat layer skimmed from the top of milk before homogenization. In un-homogenized milk, the fat, which is less dense, eventually rises to the top.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_clover.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_clover.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_clover.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_clover.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_clover.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 73,
                title: 'Milk Cream',
                slug: 'milk _cream',
            },
        ],
    },
    {
        id: 304,
        title: 'Fernleaf Full Cream',
        slug: 'milk_cream_fernleaf',
        unit: ' 2 lb',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'Cream is a dairy product composed of the higher-fat layer skimmed from the top of milk before homogenization. In un-homogenized milk, the fat, which is less dense, eventually rises to the top.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_fernleaf.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_fernleaf.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_fernleaf.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_fernleaf.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_fernleaf.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 73,
                title: 'Milk Cream',
                slug: 'milk _cream',
            },
        ],
    },
    {
        id: 305,
        title: 'Scape Milk Powder',
        slug: 'powder_milk_scape',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Powdered milk or dried milk is a manufactured dairy product made by evaporating milk to dryness. One purpose of drying milk is to preserve it; milk powder has a far longer shelf life than liquid milk and does not need to be refrigerated, due to its low moisture content. Another purpose is to reduce its bulk for economy of transportation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_scape.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_scape.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_scape.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_scape.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_scape.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 74,
                title: 'Powder Milk',
                slug: 'Powder_Milk',
            },
        ],
    },
    {
        id: 306,
        title: 'Natural Foods Organic Coconut Milk Powder',
        slug: 'powder_milk_z_natural_foods',
        unit: ' 2 lb',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'Powdered milk or dried milk is a manufactured dairy product made by evaporating milk to dryness. One purpose of drying milk is to preserve it; milk powder has a far longer shelf life than liquid milk and does not need to be refrigerated, due to its low moisture content. Another purpose is to reduce its bulk for economy of transportation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_z_natural_foods.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_z_natural_foods.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_z_natural_foods.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_z_natural_foods.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_z_natural_foods.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 74,
                title: 'Powder Milk',
                slug: 'Powder_Milk',
            },
        ],
    },
    {
        id: 307,
        title: 'Param Premium Milk Powder',
        slug: 'powder_milk_param',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Powdered milk or dried milk is a manufactured dairy product made by evaporating milk to dryness. One purpose of drying milk is to preserve it; milk powder has a far longer shelf life than liquid milk and does not need to be refrigerated, due to its low moisture content. Another purpose is to reduce its bulk for economy of transportation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_param.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_param.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_param.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_param.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_param.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 74,
                title: 'Powder Milk',
                slug: 'Powder_Milk',
            },
        ],
    },
    {
        id: 308,
        title: 'Nestle Nido',
        slug: 'powder_milk_nestle_nido_900gram',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Powdered milk or dried milk is a manufactured dairy product made by evaporating milk to dryness. One purpose of drying milk is to preserve it; milk powder has a far longer shelf life than liquid milk and does not need to be refrigerated, due to its low moisture content. Another purpose is to reduce its bulk for economy of transportation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_nestle_nido_900gram.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_nestle_nido_900gram.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_nestle_nido_900gram.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_nestle_nido_900gram.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_nestle_nido_900gram.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 74,
                title: 'Powder Milk',
                slug: 'Powder_Milk',
            },
        ],
    },
    {
        id: 309,
        title: 'Milgro',
        slug: 'powder_milk_milgro',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Powdered milk or dried milk is a manufactured dairy product made by evaporating milk to dryness. One purpose of drying milk is to preserve it; milk powder has a far longer shelf life than liquid milk and does not need to be refrigerated, due to its low moisture content. Another purpose is to reduce its bulk for economy of transportation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_milgro.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_milgro.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_milgro.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_milgro.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_milgro.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 74,
                title: 'Powder Milk',
                slug: 'Powder_Milk',
            },
        ],
    },
    {
        id: 310,
        title: 'Lifestyle Soya Milk Powder',
        slug: 'powder_milk_lifestyle_food',
        unit: ' 2 lb',
        price: 5,
        salePrice: 4.5,
        discountInPercent: 10,
        description: 'Powdered milk or dried milk is a manufactured dairy product made by evaporating milk to dryness. One purpose of drying milk is to preserve it; milk powder has a far longer shelf life than liquid milk and does not need to be refrigerated, due to its low moisture content. Another purpose is to reduce its bulk for economy of transportation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_lifestyle_food.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_lifestyle_food.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_lifestyle_food.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_lifestyle_food.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_lifestyle_food.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 74,
                title: 'Powder Milk',
                slug: 'Powder_Milk',
            },
        ],
    },
    {
        id: 311,
        title: 'Goldmilys Instant Milk Powder',
        slug: 'powder_milk_goldmilys',
        unit: ' 2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Powdered milk or dried milk is a manufactured dairy product made by evaporating milk to dryness. One purpose of drying milk is to preserve it; milk powder has a far longer shelf life than liquid milk and does not need to be refrigerated, due to its low moisture content. Another purpose is to reduce its bulk for economy of transportation.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_goldmilys.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_goldmilys.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_goldmilys.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_goldmilys.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/powder_milk_goldmilys.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 74,
                title: 'Powder Milk',
                slug: 'Powder_Milk',
            },
        ],
    },
    {
        id: 312,
        title: 'Nammilk Greek Style',
        slug: 'milk_cream_nammilk',
        unit: ' 2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'yoghurt, yogourt or yoghourt, is a food produced by bacterial fermentation of milk.[1] The bacteria used to make yogurt are known as yogurt cultures.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_nammilk.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_nammilk.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_nammilk.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_nammilk.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/milk_cream_nammilk.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 75,
                title: 'Yogourt',
                slug: 'yogourt',
            },
        ],
    },
    {
        id: 313,
        title: 'Astro Original',
        slug: 'yogourt_astro_balkan',
        unit: ' 2 lb',
        price: 4,
        salePrice: 3.2,
        discountInPercent: 20,
        description: 'yoghurt, yogourt or yoghourt, is a food produced by bacterial fermentation of milk.[1] The bacteria used to make yogurt are known as yogurt cultures.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_astro_balkan.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_astro_balkan.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_astro_balkan.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_astro_balkan.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_astro_balkan.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 75,
                title: 'Yogourt',
                slug: 'yogourt',
            },
        ],
    },
    {
        id: 314,
        title: 'Astro Bio Best',
        slug: 'yogourt_astro_bio_best',
        unit: ' 2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'yoghurt, yogourt or yoghourt, is a food produced by bacterial fermentation of milk.[1] The bacteria used to make yogurt are known as yogurt cultures.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_astro_bio_best.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_astro_bio_best.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_astro_bio_best.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_astro_bio_best.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_astro_bio_best.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 75,
                title: 'Yogourt',
                slug: 'yogourt',
            },
        ],
    },
    {
        id: 315,
        title: 'Chonani',
        slug: 'yogourt_chobani_plain_non_fat',
        unit: ' 2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'yoghurt, yogourt or yoghourt, is a food produced by bacterial fermentation of milk.[1] The bacteria used to make yogurt are known as yogurt cultures.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_chobani_plain_non_fat.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_chobani_plain_non_fat.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_chobani_plain_non_fat.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_chobani_plain_non_fat.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_chobani_plain_non_fat.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 75,
                title: 'Yogourt',
                slug: 'yogourt',
            },
        ],
    },
    {
        id: 316,
        title: 'Chonani Vanilla Blended',
        slug: 'yogourt_chobani_playogourt_chobani_vanillain_non_fat',
        unit: ' 2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'yoghurt, yogourt or yoghourt, is a food produced by bacterial fermentation of milk.[1] The bacteria used to make yogurt are known as yogurt cultures.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_chobani_vanilla.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_chobani_vanilla.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_chobani_vanilla.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_chobani_vanilla.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_chobani_vanilla.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 75,
                title: 'Yogourt',
                slug: 'yogourt',
            },
        ],
    },
    {
        id: 317,
        title: 'Dannon Strawberry',
        slug: 'yogourt_dannon_strawberry',
        unit: ' 2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'yoghurt, yogourt or yoghourt, is a food produced by bacterial fermentation of milk.[1] The bacteria used to make yogurt are known as yogurt cultures.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_dannon_strawberry.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_dannon_strawberry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_dannon_strawberry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_dannon_strawberry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_dannon_strawberry.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 75,
                title: 'Yogourt',
                slug: 'yogourt',
            },
        ],
    },
    {
        id: 318,
        title: 'Dannon Vanilla',
        slug: 'yogourt_dannon_vanilla',
        unit: '2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'yoghurt, yogourt or yoghourt, is a food produced by bacterial fermentation of milk.[1] The bacteria used to make yogurt are known as yogurt cultures.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_dannon_vanilla.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_dannon_vanilla.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_dannon_vanilla.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_dannon_vanilla.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_dannon_vanilla.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 75,
                title: 'Yogourt',
                slug: 'yogourt',
            },
        ],
    },
    {
        id: 319,
        title: 'Marigold Yogourt',
        slug: 'yogourt_marigold',
        unit: '2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'yoghurt, yogourt or yoghourt, is a food produced by bacterial fermentation of milk.[1] The bacteria used to make yogurt are known as yogurt cultures.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_marigold.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_marigold.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_marigold.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_marigold.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_marigold.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 75,
                title: 'Yogourt',
                slug: 'yogourt',
            },
        ],
    },
    {
        id: 320,
        title: 'Stony Field Vanilla',
        slug: 'yogourt_stony_field_vanilla_fudge_swirl',
        unit: ' 2 lb',
        price: 5,
        salePrice: 4.5,
        discountInPercent: 10,
        description: 'yoghurt, yogourt or yoghourt, is a food produced by bacterial fermentation of milk.[1] The bacteria used to make yogurt are known as yogurt cultures.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_stony_field_vanilla_fudge_swirl.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_stony_field_vanilla_fudge_swirl.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_stony_field_vanilla_fudge_swirl.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_stony_field_vanilla_fudge_swirl.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/yogourt_stony_field_vanilla_fudge_swirl.jpg',
            },
        ],
        categories: [
            {
                id: 69,
                title: 'Dairy',
                slug: 'dairy',
            },
            {
                id: 75,
                title: 'Yogourt',
                slug: 'yogourt',
            },
        ],
    },
    {
        id: 321,
        title: '1 2 3 Vegetable Oil',
        slug: 'oil_1_2_3_vegitable',
        unit: ' 2 lb',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'An oil is any nonpolar chemical substance that is a viscous liquid at ambient temperatures and is both hydrophobic (does not mix with water, literally water fearing) and lipophilic (mixes with other oils, literally fat loving). Oils have a high carbon and hydrogen content and are usually flammable and surface active.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_1_2_3_vegitable.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_1_2_3_vegitable.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_1_2_3_vegitable.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_1_2_3_vegitable.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_1_2_3_vegitable.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 77,
                title: 'Oil',
                slug: 'oil',
            },
        ],
    },
    {
        id: 322,
        title: 'Clover Valley Vegetable Oil',
        slug: 'oil_clover_valley',
        unit: ' 4 lb',
        price: 8,
        salePrice: 6.4,
        discountInPercent: 20,
        description: 'An oil is any nonpolar chemical substance that is a viscous liquid at ambient temperatures and is both hydrophobic (does not mix with water, literally water fearing) and lipophilic (mixes with other oils, literally fat loving). Oils have a high carbon and hydrogen content and are usually flammable and surface active.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_clover_valley.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_clover_valley.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_clover_valley.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_clover_valley.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_clover_valley.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 77,
                title: 'Oil',
                slug: 'oil',
            },
        ],
    },
    {
        id: 323,
        title: 'Daisy Corn Oil',
        slug: 'oil_daisy',
        unit: ' 3 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'An oil is any nonpolar chemical substance that is a viscous liquid at ambient temperatures and is both hydrophobic (does not mix with water, literally water fearing) and lipophilic (mixes with other oils, literally fat loving). Oils have a high carbon and hydrogen content and are usually flammable and surface active.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_daisy.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_daisy.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_daisy.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_daisy.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_daisy.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 77,
                title: 'Oil',
                slug: 'oil',
            },
        ],
    },
    {
        id: 324,
        title: 'Eva Corn Oil',
        slug: 'oil_eva',
        unit: ' 3 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'An oil is any nonpolar chemical substance that is a viscous liquid at ambient temperatures and is both hydrophobic (does not mix with water, literally water fearing) and lipophilic (mixes with other oils, literally fat loving). Oils have a high carbon and hydrogen content and are usually flammable and surface active.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_eva.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_eva.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_eva.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_eva.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_eva.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 77,
                title: 'Oil',
                slug: 'oil',
            },
        ],
    },
    {
        id: 325,
        title: 'Goya Extra Virgin Olive Oil',
        slug: 'oil_goya',
        unit: ' 2 lb',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'An oil is any nonpolar chemical substance that is a viscous liquid at ambient temperatures and is both hydrophobic (does not mix with water, literally water fearing) and lipophilic (mixes with other oils, literally fat loving). Oils have a high carbon and hydrogen content and are usually flammable and surface active.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_goya.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_goya.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_goya.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_goya.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_goya.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 77,
                title: 'Oil',
                slug: 'oil',
            },
        ],
    },
    {
        id: 326,
        title: 'Naturel Premium Sunflower Oil',
        slug: 'oil_natural',
        unit: ' 2 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'An oil is any nonpolar chemical substance that is a viscous liquid at ambient temperatures and is both hydrophobic (does not mix with water, literally water fearing) and lipophilic (mixes with other oils, literally fat loving). Oils have a high carbon and hydrogen content and are usually flammable and surface active.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_natural.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_natural.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_natural.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_natural.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_natural.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 77,
                title: 'Oil',
                slug: 'oil',
            },
        ],
    },
    {
        id: 327,
        title: 'San Lucas Corn Oil',
        slug: 'oil_san_lucas',
        unit: ' 2 lb',
        price: 7,
        salePrice: 0,
        discountInPercent: 0,
        description: 'An oil is any nonpolar chemical substance that is a viscous liquid at ambient temperatures and is both hydrophobic (does not mix with water, literally water fearing) and lipophilic (mixes with other oils, literally fat loving). Oils have a high carbon and hydrogen content and are usually flammable and surface active.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_san_lucas.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_san_lucas.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_san_lucas.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_san_lucas.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_san_lucas.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 77,
                title: 'Oil',
                slug: 'oil',
            },
        ],
    },
    {
        id: 328,
        title: 'Sunfoil Sunflower Oil',
        slug: 'oil_sunfoil',
        unit: ' 2 lb',
        price: 9,
        salePrice: 0,
        discountInPercent: 0,
        description: 'An oil is any nonpolar chemical substance that is a viscous liquid at ambient temperatures and is both hydrophobic (does not mix with water, literally water fearing) and lipophilic (mixes with other oils, literally fat loving). Oils have a high carbon and hydrogen content and are usually flammable and surface active.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_sunfoil.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_sunfoil.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_sunfoil.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_sunfoil.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/oil_sunfoil.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 77,
                title: 'Oil',
                slug: 'oil',
            },
        ],
    },
    {
        id: 329,
        title: 'Chef Choice Bamboo Rice',
        slug: 'chefa_choice_bamboo_rice',
        unit: ' 2 lb',
        price: 9,
        salePrice: 0,
        discountInPercent: 0,
        description: "Rice is the seed of the grass species Oryza sativa (Asian rice) or Oryza glaberrima (African rice). As a cereal grain, it is the most widely consumed staple food for a large part of the world's human population, especially in Asia.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chefa_choice_bamboo_rice.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chefa_choice_bamboo_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chefa_choice_bamboo_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chefa_choice_bamboo_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chefa_choice_bamboo_rice.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 78,
                title: 'Rice',
                slug: 'rice',
            },
        ],
    },
    {
        id: 330,
        title: 'Everyday Essentials Golden Vegetable Savoury Rice',
        slug: 'everyday_essentials_Golden_Vegetable_Savoury_Rice',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: "Rice is the seed of the grass species Oryza sativa (Asian rice) or Oryza glaberrima (African rice). As a cereal grain, it is the most widely consumed staple food for a large part of the world's human population, especially in Asia.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/everyday_essentials_Golden_Vegetable_Savoury_Rice.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/everyday_essentials_Golden_Vegetable_Savoury_Rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/everyday_essentials_Golden_Vegetable_Savoury_Rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/everyday_essentials_Golden_Vegetable_Savoury_Rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/everyday_essentials_Golden_Vegetable_Savoury_Rice.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 78,
                title: 'Rice',
                slug: 'rice',
            },
        ],
    },
    {
        id: 331,
        title: 'Hulas Premium Basmati Rice',
        slug: 'hulas_premium_basmati_rice',
        unit: ' 2 lb',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: "Rice is the seed of the grass species Oryza sativa (Asian rice) or Oryza glaberrima (African rice). As a cereal grain, it is the most widely consumed staple food for a large part of the world's human population, especially in Asia.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hulas_premium_basmati_rice.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hulas_premium_basmati_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hulas_premium_basmati_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hulas_premium_basmati_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/hulas_premium_basmati_rice.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 78,
                title: 'Rice',
                slug: 'rice',
            },
        ],
    },
    {
        id: 332,
        title: 'Jasmine Long Grain Fragrant Rice',
        slug: 'Iberia_Long_Grain_Enriched_Fragrant_Rice_18_lbs',
        unit: ' 20 lb',
        price: 40,
        salePrice: 36,
        discountInPercent: 20,
        description: "Rice is the seed of the grass species Oryza sativa (Asian rice) or Oryza glaberrima (African rice). As a cereal grain, it is the most widely consumed staple food for a large part of the world's human population, especially in Asia.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Iberia_Long_Grain_Enriched_Fragrant_Rice_18_lbs.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Iberia_Long_Grain_Enriched_Fragrant_Rice_18_lbs.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Iberia_Long_Grain_Enriched_Fragrant_Rice_18_lbs.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Iberia_Long_Grain_Enriched_Fragrant_Rice_18_lbs.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Iberia_Long_Grain_Enriched_Fragrant_Rice_18_lbs.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 78,
                title: 'Rice',
                slug: 'rice',
            },
        ],
    },
    {
        id: 333,
        title: 'India Gate Basmati Rice',
        slug: 'india_gate_basmati_rice',
        unit: ' 20 lb',
        price: 40,
        salePrice: 0,
        discountInPercent: 0,
        description: "Rice is the seed of the grass species Oryza sativa (Asian rice) or Oryza glaberrima (African rice). As a cereal grain, it is the most widely consumed staple food for a large part of the world's human population, especially in Asia.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/india_gate_basmati_rice.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/india_gate_basmati_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/india_gate_basmati_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/india_gate_basmati_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/india_gate_basmati_rice.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 78,
                title: 'Rice',
                slug: 'rice',
            },
        ],
    },
    {
        id: 334,
        title: 'Natures Gift Basmati Rice',
        slug: "nature's_gift_basmati_rice",
        unit: ' 10 lb',
        price: 20,
        salePrice: 16,
        discountInPercent: 20,
        description: "Rice is the seed of the grass species Oryza sativa (Asian rice) or Oryza glaberrima (African rice). As a cereal grain, it is the most widely consumed staple food for a large part of the world's human population, especially in Asia.",
        type: 'grocery',
        image: "https://s3.amazonaws.com/redqteam.com/pickbazar/nature's_gift_basmati_rice.jpg",
        gallery: [
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/nature's_gift_basmati_rice.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/nature's_gift_basmati_rice.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/nature's_gift_basmati_rice.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/nature's_gift_basmati_rice.jpg",
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 78,
                title: 'Rice',
                slug: 'rice',
            },
        ],
    },
    {
        id: 335,
        title: 'Seeds Of Change Brown Basmati Rice',
        slug: 'seeds_of_change_brown_basmati_rice',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: "Rice is the seed of the grass species Oryza sativa (Asian rice) or Oryza glaberrima (African rice). As a cereal grain, it is the most widely consumed staple food for a large part of the world's human population, especially in Asia.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/seeds_of_change_brown_basmati_rice.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/seeds_of_change_brown_basmati_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/seeds_of_change_brown_basmati_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/seeds_of_change_brown_basmati_rice.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/seeds_of_change_brown_basmati_rice.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 78,
                title: 'Rice',
                slug: 'rice',
            },
        ],
    },
    {
        id: 336,
        title: 'Tai Ping Sushi Rice',
        slug: 'tai_ping_Sushi_Rice_1kg',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: "Rice is the seed of the grass species Oryza sativa (Asian rice) or Oryza glaberrima (African rice). As a cereal grain, it is the most widely consumed staple food for a large part of the world's human population, especially in Asia.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tai_ping_Sushi_Rice_1kg.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tai_ping_Sushi_Rice_1kg.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tai_ping_Sushi_Rice_1kg.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tai_ping_Sushi_Rice_1kg.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tai_ping_Sushi_Rice_1kg.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 78,
                title: 'Rice',
                slug: 'rice',
            },
        ],
    },
    {
        id: 337,
        title: 'Clover Valley Sea Salt',
        slug: 'clover_valley_sea_salt_grinder',
        unit: ' 1 pc(s)',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Salt is a mineral composed primarily of sodium chloride (NaCl), a chemical compound belonging to the larger class of salts; salt in its natural form as a crystalline mineral is known as rock salt .',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/clover_valley_sea_salt_grinder.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/clover_valley_sea_salt_grinder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/clover_valley_sea_salt_grinder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/clover_valley_sea_salt_grinder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/clover_valley_sea_salt_grinder.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 338,
        title: 'Gathering Place Sea Salt',
        slug: 'gathering_place_sea_salt_100g_grinder',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 1.8,
        discountInPercent: 10,
        description: 'Salt is a mineral composed primarily of sodium chloride (NaCl), a chemical compound belonging to the larger class of salts; salt in its natural form as a crystalline mineral is known as rock salt .',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gathering_place_sea_salt_100g_grinder.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gathering_place_sea_salt_100g_grinder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gathering_place_sea_salt_100g_grinder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gathering_place_sea_salt_100g_grinder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gathering_place_sea_salt_100g_grinder.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 339,
        title: 'Herbivore Calm Dead Sea Soaking Salt',
        slug: 'herbivore_Calm_deadsea_soaking_salt',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Salt is a mineral composed primarily of sodium chloride (NaCl), a chemical compound belonging to the larger class of salts; salt in its natural form as a crystalline mineral is known as rock salt .',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/herbivore_Calm_deadsea_soaking_salt.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/herbivore_Calm_deadsea_soaking_salt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/herbivore_Calm_deadsea_soaking_salt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/herbivore_Calm_deadsea_soaking_salt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/herbivore_Calm_deadsea_soaking_salt.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 340,
        title: 'Nomu Sea Salt',
        slug: 'nomu_atlantic_Sea_Salt_Grinder',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 3.6,
        discountInPercent: 10,
        description: 'Salt is a mineral composed primarily of sodium chloride (NaCl), a chemical compound belonging to the larger class of salts; salt in its natural form as a crystalline mineral is known as rock salt .',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nomu_atlantic_Sea_Salt_Grinder.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nomu_atlantic_Sea_Salt_Grinder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nomu_atlantic_Sea_Salt_Grinder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nomu_atlantic_Sea_Salt_Grinder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nomu_atlantic_Sea_Salt_Grinder.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 341,
        title: 'Tasteology Chili Salt',
        slug: 'tasteology_chili_salt',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Salt is a mineral composed primarily of sodium chloride (NaCl), a chemical compound belonging to the larger class of salts; salt in its natural form as a crystalline mineral is known as rock salt .',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tasteology_chili_salt.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tasteology_chili_salt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tasteology_chili_salt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tasteology_chili_salt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tasteology_chili_salt.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 342,
        title: 'The Spice House White Truffle Salt',
        slug: 'the_spice_house_white_Truffle_Salt',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Salt is a mineral composed primarily of sodium chloride (NaCl), a chemical compound belonging to the larger class of salts; salt in its natural form as a crystalline mineral is known as rock salt .',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_spice_house_white_Truffle_Salt.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_spice_house_white_Truffle_Salt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_spice_house_white_Truffle_Salt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_spice_house_white_Truffle_Salt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_spice_house_white_Truffle_Salt.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 343,
        title: 'Gula Aromatic Brown Sugar',
        slug: 'gula_aromatic_brown_sugar',
        unit: ' 2 lb',
        price: 5,
        salePrice: 4.5,
        discountInPercent: 10,
        description: 'Sugar is the generic name for sweet-tasting, soluble carbohydrates, many of which are used in food. Simple sugars, also called monosaccharides, include glucose, fructose, and galactose.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gula_aromatic_brown_sugar.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gula_aromatic_brown_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gula_aromatic_brown_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gula_aromatic_brown_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gula_aromatic_brown_sugar.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 344,
        title: 'Sis White Sugar',
        slug: 'sis_white_sugar',
        unit: ' 2 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Sugar is the generic name for sweet-tasting, soluble carbohydrates, many of which are used in food. Simple sugars, also called monosaccharides, include glucose, fructose, and galactose.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/sis_white_sugar.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/sis_white_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/sis_white_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/sis_white_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/sis_white_sugar.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 345,
        title: 'Tate Lyle White Sugar',
        slug: 'tate_sugar',
        unit: ' 2 lb',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'Sugar is the generic name for sweet-tasting, soluble carbohydrates, many of which are used in food. Simple sugars, also called monosaccharides, include glucose, fructose, and galactose.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tate_sugar.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tate_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tate_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tate_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tate_sugar.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 346,
        title: 'The Pantry Icing Sugar',
        slug: 'the_pantry_icing_Sugar',
        unit: ' 2 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Sugar is the generic name for sweet-tasting, soluble carbohydrates, many of which are used in food. Simple sugars, also called monosaccharides, include glucose, fructose, and galactose.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_pantry_icing_Sugar.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_pantry_icing_Sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_pantry_icing_Sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_pantry_icing_Sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_pantry_icing_Sugar.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 347,
        title: 'Whitworths White Sugar',
        slug: 'whitworths_sugar',
        unit: ' 2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Sugar is the generic name for sweet-tasting, soluble carbohydrates, many of which are used in food. Simple sugars, also called monosaccharides, include glucose, fructose, and galactose.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/whitworths_sugar.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/whitworths_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/whitworths_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/whitworths_sugar.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/whitworths_sugar.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 348,
        title: 'Wholesome Organic Cane Sugar',
        slug: 'Wholesome_Organic_Cane_Sugar_2LB',
        unit: ' 2 lb',
        price: 6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Sugar is the generic name for sweet-tasting, soluble carbohydrates, many of which are used in food. Simple sugars, also called monosaccharides, include glucose, fructose, and galactose.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Wholesome_Organic_Cane_Sugar_2LB.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Wholesome_Organic_Cane_Sugar_2LB.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Wholesome_Organic_Cane_Sugar_2LB.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Wholesome_Organic_Cane_Sugar_2LB.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Wholesome_Organic_Cane_Sugar_2LB.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 79,
                title: 'Salt Sugar',
                slug: 'Salt_sugar',
            },
        ],
    },
    {
        id: 349,
        title: 'Club House La Grille Seasoning',
        slug: 'club_house_la_grille_smouldering_smoked_applewood_seasoning',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A spice is a seed, fruit, root, bark, or other plant substance primarily used for flavoring, coloring or preserving food. Spices are distinguished from herbs, which are the leaves, flowers, or stems of plants used for flavoring or as a garnish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/club_house_la_grille_smouldering_smoked_applewood_seasoning.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/club_house_la_grille_smouldering_smoked_applewood_seasoning.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/club_house_la_grille_smouldering_smoked_applewood_seasoning.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/club_house_la_grille_smouldering_smoked_applewood_seasoning.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/club_house_la_grille_smouldering_smoked_applewood_seasoning.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 80,
                title: 'Spices',
                slug: 'milr_cream',
            },
        ],
    },
    {
        id: 350,
        title: 'Crispy Chili Flakes',
        slug: 'crispy_chill_flakes',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 3.8,
        discountInPercent: 5,
        description: 'A spice is a seed, fruit, root, bark, or other plant substance primarily used for flavoring, coloring or preserving food. Spices are distinguished from herbs, which are the leaves, flowers, or stems of plants used for flavoring or as a garnish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crispy_chill_flakes.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crispy_chill_flakes.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crispy_chill_flakes.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crispy_chill_flakes.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crispy_chill_flakes.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 80,
                title: 'Spices',
                slug: 'milr_cream',
            },
        ],
    },
    {
        id: 351,
        title: 'Dell Clove Spicy Chipotle Seasoning',
        slug: 'Dell_Cove_Spices_Spicy_Chipotle_Seasoning',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 3.8,
        discountInPercent: 5,
        description: 'A spice is a seed, fruit, root, bark, or other plant substance primarily used for flavoring, coloring or preserving food. Spices are distinguished from herbs, which are the leaves, flowers, or stems of plants used for flavoring or as a garnish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Dell_Cove_Spices_Spicy_Chipotle_Seasoning.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Dell_Cove_Spices_Spicy_Chipotle_Seasoning.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Dell_Cove_Spices_Spicy_Chipotle_Seasoning.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Dell_Cove_Spices_Spicy_Chipotle_Seasoning.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Dell_Cove_Spices_Spicy_Chipotle_Seasoning.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 80,
                title: 'Spices',
                slug: 'milr_cream',
            },
        ],
    },
    {
        id: 352,
        title: 'Frontier Curry Powder',
        slug: 'Frontier_Curry_Powder',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A spice is a seed, fruit, root, bark, or other plant substance primarily used for flavoring, coloring or preserving food. Spices are distinguished from herbs, which are the leaves, flowers, or stems of plants used for flavoring or as a garnish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Frontier_Curry_Powder.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Frontier_Curry_Powder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Frontier_Curry_Powder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Frontier_Curry_Powder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Frontier_Curry_Powder.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 80,
                title: 'Spices',
                slug: 'milr_cream',
            },
        ],
    },
    {
        id: 353,
        title: 'Mc Cormic Chilli Flakes',
        slug: 'mccormic_chilli_flakes',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A spice is a seed, fruit, root, bark, or other plant substance primarily used for flavoring, coloring or preserving food. Spices are distinguished from herbs, which are the leaves, flowers, or stems of plants used for flavoring or as a garnish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mccormic_chilli_flakes.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mccormic_chilli_flakes.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mccormic_chilli_flakes.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mccormic_chilli_flakes.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mccormic_chilli_flakes.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 80,
                title: 'Spices',
                slug: 'milr_cream',
            },
        ],
    },
    {
        id: 354,
        title: 'Redman Red Chilli Powder',
        slug: 'redman_red_chilli_powder',
        unit: ' 1 pc(s)',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A spice is a seed, fruit, root, bark, or other plant substance primarily used for flavoring, coloring or preserving food. Spices are distinguished from herbs, which are the leaves, flowers, or stems of plants used for flavoring or as a garnish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/redman_red_chilli_powder.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/redman_red_chilli_powder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/redman_red_chilli_powder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/redman_red_chilli_powder.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/redman_red_chilli_powder.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 80,
                title: 'Spices',
                slug: 'milr_cream',
            },
        ],
    },
    {
        id: 355,
        title: 'Tajin Clasico Seasoning',
        slug: 'tajin_clasico_seasoning',
        unit: ' 1 pc(s)',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A spice is a seed, fruit, root, bark, or other plant substance primarily used for flavoring, coloring or preserving food. Spices are distinguished from herbs, which are the leaves, flowers, or stems of plants used for flavoring or as a garnish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tajin_clasico_seasoning.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tajin_clasico_seasoning.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tajin_clasico_seasoning.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tajin_clasico_seasoning.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tajin_clasico_seasoning.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 80,
                title: 'Spices',
                slug: 'milr_cream',
            },
        ],
    },
    {
        id: 356,
        title: 'Weber Bold N Smoky Chipotle Seasoning',
        slug: 'weber_all_natural',
        unit: ' 1 pc(s)',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A spice is a seed, fruit, root, bark, or other plant substance primarily used for flavoring, coloring or preserving food. Spices are distinguished from herbs, which are the leaves, flowers, or stems of plants used for flavoring or as a garnish.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/weber_all_natural.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/weber_all_natural.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/weber_all_natural.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/weber_all_natural.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/weber_all_natural.jpg',
            },
        ],
        categories: [
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
            },
            {
                id: 80,
                title: 'Spices',
                slug: 'milr_cream',
            },
        ],
    },
    {
        id: 357,
        title: 'Garry Best Taste Bread',
        slug: 'best_taste_Bread_garry',
        unit: ' 1 lb',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bread is a staple food prepared from a dough of flour and water, usually by baking. Throughout recorded history it has been a prominent food in large parts of the world and is one of the oldest man-made foods, having been of significant importance since the dawn of agriculture.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/best_taste_Bread_garry.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/best_taste_Bread_garry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/best_taste_Bread_garry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/best_taste_Bread_garry.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/best_taste_Bread_garry.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 83,
                title: 'Bread',
                slug: 'bread',
            },
        ],
    },
    {
        id: 358,
        title: 'Everyday Essentials Wholemeal Bread',
        slug: 'Everyday_Essentials_Wholemeal_Bread',
        unit: '1 lb',
        price: 2.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bread is a staple food prepared from a dough of flour and water, usually by baking. Throughout recorded history it has been a prominent food in large parts of the world and is one of the oldest man-made foods, having been of significant importance since the dawn of agriculture.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Everyday_Essentials_Wholemeal_Bread.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Everyday_Essentials_Wholemeal_Bread.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Everyday_Essentials_Wholemeal_Bread.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Everyday_Essentials_Wholemeal_Bread.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Everyday_Essentials_Wholemeal_Bread.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 83,
                title: 'Bread',
                slug: 'bread',
            },
        ],
    },
    {
        id: 359,
        title: 'Farmhouse Multigrain Batch Loaf',
        slug: 'Farmhouse_Multigrain_Batch_loaf_purple_pack',
        unit: ' 1 lb',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bread is a staple food prepared from a dough of flour and water, usually by baking. Throughout recorded history it has been a prominent food in large parts of the world and is one of the oldest man-made foods, having been of significant importance since the dawn of agriculture.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Farmhouse_Multigrain_Batch_loaf_purple_pack.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Farmhouse_Multigrain_Batch_loaf_purple_pack.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Farmhouse_Multigrain_Batch_loaf_purple_pack.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Farmhouse_Multigrain_Batch_loaf_purple_pack.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Farmhouse_Multigrain_Batch_loaf_purple_pack.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 83,
                title: 'Bread',
                slug: 'bread',
            },
        ],
    },
    {
        id: 360,
        title: 'Roberts Seeded Bloomer',
        slug: 'roberts_Seriously_Seeded_Bloomer',
        unit: ' 1 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bread is a staple food prepared from a dough of flour and water, usually by baking. Throughout recorded history it has been a prominent food in large parts of the world and is one of the oldest man-made foods, having been of significant importance since the dawn of agriculture.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/roberts_Seriously_Seeded_Bloomer.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/roberts_Seriously_Seeded_Bloomer.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/roberts_Seriously_Seeded_Bloomer.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/roberts_Seriously_Seeded_Bloomer.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/roberts_Seriously_Seeded_Bloomer.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 83,
                title: 'Bread',
                slug: 'bread',
            },
        ],
    },
    {
        id: 360,
        title: 'The Village Bakery White Bread',
        slug: 'the_village_bakery_Both_In_One_Loaf_white_wholemeal',
        unit: ' 1 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bread is a staple food prepared from a dough of flour and water, usually by baking. Throughout recorded history it has been a prominent food in large parts of the world and is one of the oldest man-made foods, having been of significant importance since the dawn of agriculture.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_Both_In_One_Loaf_white_wholemeal.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_Both_In_One_Loaf_white_wholemeal.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_Both_In_One_Loaf_white_wholemeal.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_Both_In_One_Loaf_white_wholemeal.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_Both_In_One_Loaf_white_wholemeal.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 83,
                title: 'Bread',
                slug: 'bread',
            },
        ],
    },
    {
        id: 361,
        title: 'The Village Bakery Low Fat Bread',
        slug: 'the_village_bakery_danish_low_fat',
        unit: ' 1 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bread is a staple food prepared from a dough of flour and water, usually by baking. Throughout recorded history it has been a prominent food in large parts of the world and is one of the oldest man-made foods, having been of significant importance since the dawn of agriculture.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_danish_low_fat.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_danish_low_fat.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_danish_low_fat.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_danish_low_fat.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_danish_low_fat.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 83,
                title: 'Bread',
                slug: 'bread',
            },
        ],
    },
    {
        id: 362,
        title: 'The Village Bakery Soft White Bread',
        slug: 'the_village_bakery_danish_Soft_White_Toastie',
        unit: ' 1 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bread is a staple food prepared from a dough of flour and water, usually by baking. Throughout recorded history it has been a prominent food in large parts of the world and is one of the oldest man-made foods, having been of significant importance since the dawn of agriculture.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_danish_Soft_White_Toastie.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_danish_Soft_White_Toastie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_danish_Soft_White_Toastie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_danish_Soft_White_Toastie.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_danish_Soft_White_Toastie.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 83,
                title: 'Bread',
                slug: 'bread',
            },
        ],
    },
    {
        id: 363,
        title: 'The Village Bakery Soft Wholemeal Bread',
        slug: 'the_village_bakery_Soft_Wholemeal_Bread',
        unit: ' 1 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bread is a staple food prepared from a dough of flour and water, usually by baking. Throughout recorded history it has been a prominent food in large parts of the world and is one of the oldest man-made foods, having been of significant importance since the dawn of agriculture.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_Soft_Wholemeal_Bread.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_Soft_Wholemeal_Bread.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_Soft_Wholemeal_Bread.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_Soft_Wholemeal_Bread.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_village_bakery_Soft_Wholemeal_Bread.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 83,
                title: 'Bread',
                slug: 'bread',
            },
        ],
    },
    {
        id: 364,
        title: 'Warburtons Wholemeal Bread',
        slug: 'Warburtons_Wholemeal_mild_soft',
        unit: ' 1 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bread is a staple food prepared from a dough of flour and water, usually by baking. Throughout recorded history it has been a prominent food in large parts of the world and is one of the oldest man-made foods, having been of significant importance since the dawn of agriculture.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Warburtons_Wholemeal_mild_soft.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Warburtons_Wholemeal_mild_soft.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Warburtons_Wholemeal_mild_soft.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Warburtons_Wholemeal_mild_soft.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Warburtons_Wholemeal_mild_soft.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 83,
                title: 'Bread',
                slug: 'bread',
            },
        ],
    },
    {
        id: 365,
        title: 'Fibre 1 Crunchy Original',
        slug: 'fibre1_crunchy_original',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A cereal is any grass cultivated for the edible components of its grain, composed of the endosperm, germ, and bran. The term may also refer to the resulting grain itself.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/fibre1_crunchy_original.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/fibre1_crunchy_original.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/fibre1_crunchy_original.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/fibre1_crunchy_original.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/fibre1_crunchy_original.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 84,
                title: 'Cereal',
                slug: 'cereal',
            },
        ],
    },
    {
        id: 366,
        title: 'Trix',
        slug: 'general_mills_with_whole_grain_first_ingredient_trix_wildberry_red_swirls',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 3.6,
        discountInPercent: 10,
        description: 'A cereal is any grass cultivated for the edible components of its grain, composed of the endosperm, germ, and bran. The term may also refer to the resulting grain itself.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/general_mills_with_whole_grain_first_ingredient_trix_wildberry_red_swirls.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/general_mills_with_whole_grain_first_ingredient_trix_wildberry_red_swirls.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/general_mills_with_whole_grain_first_ingredient_trix_wildberry_red_swirls.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/general_mills_with_whole_grain_first_ingredient_trix_wildberry_red_swirls.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/general_mills_with_whole_grain_first_ingredient_trix_wildberry_red_swirls.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 84,
                title: 'Cereal',
                slug: 'cereal',
            },
        ],
    },
    {
        id: 367,
        title: 'Fiber One',
        slug: 'general_mills_with_whole_grain_guaranted_fiber_one_honey_clusters',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 3.6,
        discountInPercent: 10,
        description: 'A cereal is any grass cultivated for the edible components of its grain, composed of the endosperm, germ, and bran. The term may also refer to the resulting grain itself.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/general_mills_with_whole_grain_guaranted_fiber_one_honey_clusters.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/general_mills_with_whole_grain_guaranted_fiber_one_honey_clusters.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/general_mills_with_whole_grain_guaranted_fiber_one_honey_clusters.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/general_mills_with_whole_grain_guaranted_fiber_one_honey_clusters.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/general_mills_with_whole_grain_guaranted_fiber_one_honey_clusters.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 84,
                title: 'Cereal',
                slug: 'cereal',
            },
        ],
    },
    {
        id: 368,
        title: 'Nestle Corn Flakes',
        slug: 'nestle_made_with_whole_grain_corn_flakes',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A cereal is any grass cultivated for the edible components of its grain, composed of the endosperm, germ, and bran. The term may also refer to the resulting grain itself.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_made_with_whole_grain_corn_flakes.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_made_with_whole_grain_corn_flakes.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_made_with_whole_grain_corn_flakes.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_made_with_whole_grain_corn_flakes.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nestle_made_with_whole_grain_corn_flakes.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 84,
                title: 'Cereal',
                slug: 'cereal',
            },
        ],
    },
    {
        id: 369,
        title: 'Post Honey Comb',
        slug: 'post_honey_comb_cereal_12.5oz',
        unit: ' 1 pc(s)',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A cereal is any grass cultivated for the edible components of its grain, composed of the endosperm, germ, and bran. The term may also refer to the resulting grain itself.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/post_honey_comb_cereal_12.5oz.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/post_honey_comb_cereal_12.5oz.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/post_honey_comb_cereal_12.5oz.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/post_honey_comb_cereal_12.5oz.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/post_honey_comb_cereal_12.5oz.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 84,
                title: 'Cereal',
                slug: 'cereal',
            },
        ],
    },
    {
        id: 370,
        title: 'Beechworty Creamy Honey',
        slug: 'beechworth_honey_bee_creamy_creamy_honey',
        unit: ' 2 lb',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Honey is a sweet, viscous food substance made by bees and some related insects. Bees produce honey from the sugary secretions of plants (floral nectar) or from secretions of other insects (such as honeydew), by regurgitation, enzymatic activity, and water evaporation',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/beechworth_honey_bee_creamy_creamy_honey.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/beechworth_honey_bee_creamy_creamy_honey.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/beechworth_honey_bee_creamy_creamy_honey.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/beechworth_honey_bee_creamy_creamy_honey.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/beechworth_honey_bee_creamy_creamy_honey.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 85,
                title: 'Honey',
                slug: 'honey',
            },
        ],
    },
    {
        id: 371,
        title: 'Beekeeper S Raw Honey',
        slug: "beekeeper's_naturals_wildflower_raw_honey",
        unit: ' 2 lb',
        price: 12,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Honey is a sweet, viscous food substance made by bees and some related insects. Bees produce honey from the sugary secretions of plants (floral nectar) or from secretions of other insects (such as honeydew), by regurgitation, enzymatic activity, and water evaporation',
        type: 'grocery',
        image: "https://s3.amazonaws.com/redqteam.com/pickbazar/beekeeper's_naturals_wildflower_raw_honey.jpg",
        gallery: [
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/beekeeper's_naturals_wildflower_raw_honey.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/beekeeper's_naturals_wildflower_raw_honey.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/beekeeper's_naturals_wildflower_raw_honey.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/beekeeper's_naturals_wildflower_raw_honey.jpg",
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 85,
                title: 'Honey',
                slug: 'honey',
            },
        ],
    },
    {
        id: 3371,
        title: 'Coffee Blossom Honey',
        slug: 'coffee_blossom_honey',
        unit: ' 2 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Honey is a sweet, viscous food substance made by bees and some related insects. Bees produce honey from the sugary secretions of plants (floral nectar) or from secretions of other insects (such as honeydew), by regurgitation, enzymatic activity, and water evaporation',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coffee_blossom_honey.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coffee_blossom_honey.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coffee_blossom_honey.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coffee_blossom_honey.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coffee_blossom_honey.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 85,
                title: 'Honey',
                slug: 'honey',
            },
        ],
    },
    {
        id: 372,
        title: 'Urban Bee Honey',
        slug: 'Urban_Bee_Honey_fireweed',
        unit: ' 2 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Honey is a sweet, viscous food substance made by bees and some related insects. Bees produce honey from the sugary secretions of plants (floral nectar) or from secretions of other insects (such as honeydew), by regurgitation, enzymatic activity, and water evaporation',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Urban_Bee_Honey_fireweed.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Urban_Bee_Honey_fireweed.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Urban_Bee_Honey_fireweed.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Urban_Bee_Honey_fireweed.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Urban_Bee_Honey_fireweed.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 85,
                title: 'Honey',
                slug: 'honey',
            },
        ],
    },
    {
        id: 373,
        title: 'Chivers Mixed Fruit Jam',
        slug: 'chivers_mixed_fruit_jam',
        unit: ' 2 lb',
        price: 6,
        salePrice: 5.4,
        discountInPercent: 10,
        description: 'Jam typically contains both the juice and flesh of a fruit or vegetable, although one cookbook defines it as a cooked and jelled puree. The term jam refers to a product made of whole fruit cut into pieces or crushed, then heated with water and sugar to activate its pectin before being put into containers:',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chivers_mixed_fruit_jam.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chivers_mixed_fruit_jam.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chivers_mixed_fruit_jam.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chivers_mixed_fruit_jam.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/chivers_mixed_fruit_jam.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 86,
                title: 'Jam',
                slug: 'jam',
            },
        ],
    },
    {
        id: 374,
        title: 'Red Jacket Raspberry Jam',
        slug: 'red_jacket_raspberry_jam',
        unit: ' 2 lb',
        price: 6,
        salePrice: 5.4,
        discountInPercent: 10,
        description: 'Jam typically contains both the juice and flesh of a fruit or vegetable, although one cookbook defines it as a cooked and jelled puree. The term jam refers to a product made of whole fruit cut into pieces or crushed, then heated with water and sugar to activate its pectin before being put into containers:',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/red_jacket_raspberry_jam.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/red_jacket_raspberry_jam.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/red_jacket_raspberry_jam.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/red_jacket_raspberry_jam.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/red_jacket_raspberry_jam.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 86,
                title: 'Jam',
                slug: 'jam',
            },
        ],
    },
    {
        id: 375,
        title: 'Gin Jam',
        slug: 'Gin_Jam',
        unit: ' 2 lb',
        price: 6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Jam typically contains both the juice and flesh of a fruit or vegetable, although one cookbook defines it as a cooked and jelled puree. The term jam refers to a product made of whole fruit cut into pieces or crushed, then heated with water and sugar to activate its pectin before being put into containers:',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Gin_Jam.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Gin_Jam.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Gin_Jam.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Gin_Jam.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Gin_Jam.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 86,
                title: 'Jam',
                slug: 'jam',
            },
        ],
    },
    {
        id: 375,
        title: 'Best Foods Mayonnaise',
        slug: 'best_foods_mayonnaise1',
        unit: ' 2 lb',
        price: 6,
        salePrice: 5.4,
        discountInPercent: 10,
        description: 'Mayonnaise , informally mayo  is a thick cold condiment or dressing commonly used in sandwiches and composed salads or on chips (French fries)',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/best_foods_mayonnaise1.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/best_foods_mayonnaise1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/best_foods_mayonnaise1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/best_foods_mayonnaise1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/best_foods_mayonnaise1.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 87,
                title: 'Mayonnaise',
                slug: 'mayonnaise',
            },
        ],
    },
    {
        id: 376,
        title: 'Ballymaloe Mayonnaise',
        slug: 'Ballymaloe_Mayonnaise_240g',
        unit: ' 2 lb',
        price: 6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Mayonnaise , informally mayo  is a thick cold condiment or dressing commonly used in sandwiches and composed salads or on chips (French fries)',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Ballymaloe_Mayonnaise_240g.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Ballymaloe_Mayonnaise_240g.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Ballymaloe_Mayonnaise_240g.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Ballymaloe_Mayonnaise_240g.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Ballymaloe_Mayonnaise_240g.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 87,
                title: 'Mayonnaise',
                slug: 'mayonnaise',
            },
        ],
    },
    {
        id: 377,
        title: 'Heinz Mayonnaise',
        slug: 'Heinz_Mayonnaise',
        unit: ' 2 lb',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Mayonnaise , informally mayo  is a thick cold condiment or dressing commonly used in sandwiches and composed salads or on chips (French fries)',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Heinz_Mayonnaise.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Heinz_Mayonnaise.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Heinz_Mayonnaise.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Heinz_Mayonnaise.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Heinz_Mayonnaise.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 87,
                title: 'Mayonnaise',
                slug: 'mayonnaise',
            },
        ],
    },
    {
        id: 378,
        title: 'Kraft Mayonnaise',
        slug: 'kraft_mayonnaise1',
        unit: ' 2 lb',
        price: 7,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Mayonnaise , informally mayo  is a thick cold condiment or dressing commonly used in sandwiches and composed salads or on chips (French fries)',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kraft_mayonnaise1.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kraft_mayonnaise1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kraft_mayonnaise1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kraft_mayonnaise1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/kraft_mayonnaise1.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 87,
                title: 'Mayonnaise',
                slug: 'mayonnaise',
            },
        ],
    },
    {
        id: 379,
        title: 'Fitwell Oat',
        slug: 'FITWELL_OAT_ENRICH',
        unit: ' 2 lb',
        price: 7,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Oats are any grass cultivated for the edible components of its grain, composed of the endosperm, germ, and bran. The term may also refer to the resulting grain itself.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/FITWELL_OAT_ENRICH.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/FITWELL_OAT_ENRICH.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/FITWELL_OAT_ENRICH.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/FITWELL_OAT_ENRICH.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/FITWELL_OAT_ENRICH.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 88,
                title: 'Oats',
                slug: 'oats',
            },
        ],
    },
    {
        id: 380,
        title: 'Quaker Large Flake Oat',
        slug: 'quaker_large_flake_oats',
        unit: ' 2 lb',
        price: 7,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Oats are any grass cultivated for the edible components of its grain, composed of the endosperm, germ, and bran. The term may also refer to the resulting grain itself.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/quaker_large_flake_oats.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/quaker_large_flake_oats.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/quaker_large_flake_oats.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/quaker_large_flake_oats.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/quaker_large_flake_oats.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 88,
                title: 'Oats',
                slug: 'oats',
            },
        ],
    },
    {
        id: 381,
        title: 'Mornflake Jumbo Oat',
        slug: 'mornflake_jumbo_oats',
        unit: ' 2 lb',
        price: 8,
        salePrice: 7.2,
        discountInPercent: 10,
        description: 'Oats are any grass cultivated for the edible components of its grain, composed of the endosperm, germ, and bran. The term may also refer to the resulting grain itself.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mornflake_jumbo_oats.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mornflake_jumbo_oats.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mornflake_jumbo_oats.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mornflake_jumbo_oats.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mornflake_jumbo_oats.jpg',
            },
        ],
        categories: [
            {
                id: 82,
                title: 'Breakfast',
                slug: 'breakfast',
            },
            {
                id: 88,
                title: 'Oats',
                slug: 'oats',
            },
        ],
    },
    {
        id: 382,
        title: 'Javarama Cafe Coffee',
        slug: 'javarama_classic',
        unit: ' 2 lb',
        price: 4,
        salePrice: 3.6,
        discountInPercent: 10,
        description: 'Coffee is a brewed drink prepared from roasted coffee beans, the seeds of berries from certain Coffea species. The genus Coffea is native to tropical Africa (specifically having its origin in Ethiopia and Sudan) and Madagascar, the Comoros, Mauritius, and Réunion in the Indian Ocean.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/javarama_classic.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/javarama_classic.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/javarama_classic.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/javarama_classic.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/javarama_classic.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 91,
                title: 'Coffee',
                slug: 'coffee',
            },
        ],
    },
    {
        id: 383,
        title: 'Starbucks House Blend',
        slug: 'starbucks_house_blend',
        unit: ' 2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Coffee is a brewed drink prepared from roasted coffee beans, the seeds of berries from certain Coffea species. The genus Coffea is native to tropical Africa (specifically having its origin in Ethiopia and Sudan) and Madagascar, the Comoros, Mauritius, and Réunion in the Indian Ocean.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/starbucks_house_blend.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/starbucks_house_blend.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/starbucks_house_blend.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/starbucks_house_blend.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/starbucks_house_blend.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 91,
                title: 'Coffee',
                slug: 'coffee',
            },
        ],
    },
    {
        id: 384,
        title: 'Starbucks Vanilla Latte',
        slug: 'starbucks_vanilla_latte',
        unit: ' 2 lb',
        price: 4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Coffee is a brewed drink prepared from roasted coffee beans, the seeds of berries from certain Coffea species. The genus Coffea is native to tropical Africa (specifically having its origin in Ethiopia and Sudan) and Madagascar, the Comoros, Mauritius, and Réunion in the Indian Ocean.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/starbucks_vanilla_latte.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/starbucks_vanilla_latte.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/starbucks_vanilla_latte.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/starbucks_vanilla_latte.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/starbucks_vanilla_latte.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 91,
                title: 'Coffee',
                slug: 'coffee',
            },
        ],
    },
    {
        id: 385,
        title: 'Teeccino Herbal Coffee Alternative',
        slug: 'teeccino_herbal_coffee_alternative_almond_amaretto',
        unit: ' 1 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Coffee is a brewed drink prepared from roasted coffee beans, the seeds of berries from certain Coffea species. The genus Coffea is native to tropical Africa (specifically having its origin in Ethiopia and Sudan) and Madagascar, the Comoros, Mauritius, and Réunion in the Indian Ocean.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/teeccino_herbal_coffee_alternative_almond_amaretto.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/teeccino_herbal_coffee_alternative_almond_amaretto.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/teeccino_herbal_coffee_alternative_almond_amaretto.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/teeccino_herbal_coffee_alternative_almond_amaretto.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/teeccino_herbal_coffee_alternative_almond_amaretto.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 91,
                title: 'Coffee',
                slug: 'coffee',
            },
        ],
    },
    {
        id: 386,
        title: 'Dragon Energy',
        slug: 'dragon',
        unit: ' 200 ml',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'An energy drink is a type of drink containing sugar and stimulant compounds, usually caffeine, which is marketed as providing mental and physical stimulation (marketed as energy, but distinct from food energy).',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dragon.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dragon.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dragon.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dragon.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dragon.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 92,
                title: 'Energy Drinks',
                slug: 'energy_drinks',
            },
        ],
    },
    {
        id: 387,
        title: 'Monster Energy',
        slug: 'Monster',
        unit: ' 500 ml',
        price: 1.8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'An energy drink is a type of drink containing sugar and stimulant compounds, usually caffeine, which is marketed as providing mental and physical stimulation (marketed as energy, but distinct from food energy).',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Monster.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Monster.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Monster.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Monster.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Monster.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 92,
                title: 'Energy Drinks',
                slug: 'energy_drinks',
            },
        ],
    },
    {
        id: 388,
        title: 'Quake Energy',
        slug: 'Quake',
        unit: ' 250 ml',
        price: 1.5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'An energy drink is a type of drink containing sugar and stimulant compounds, usually caffeine, which is marketed as providing mental and physical stimulation (marketed as energy, but distinct from food energy).',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Quake.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Quake.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Quake.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Quake.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Quake.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 92,
                title: 'Energy Drinks',
                slug: 'energy_drinks',
            },
        ],
    },
    {
        id: 389,
        title: 'Red Bull Energy Drink',
        slug: 'red_bull',
        unit: ' 200 ml',
        price: 2.2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'An energy drink is a type of drink containing sugar and stimulant compounds, usually caffeine, which is marketed as providing mental and physical stimulation (marketed as energy, but distinct from food energy).',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/red_bull.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/red_bull.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/red_bull.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/red_bull.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/red_bull.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 92,
                title: 'Energy Drinks',
                slug: 'energy_drinks',
            },
        ],
    },
    {
        id: 390,
        title: 'Iceland Tropical Juice Drink',
        slug: 'iceland_tropical_juice_drink',
        unit: ' 1 ltr',
        price: 2.2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Juice is a drink made from the extraction or pressing of the natural liquid contained in fruit and vegetables. It can also refer to liquids that are flavored with concentrate or other biological food sources, such as meat or seafood, such as clam juice. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/iceland_tropical_juice_drink.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/iceland_tropical_juice_drink.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/iceland_tropical_juice_drink.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/iceland_tropical_juice_drink.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/iceland_tropical_juice_drink.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 93,
                title: 'Juice',
                slug: 'juice',
            },
        ],
    },
    {
        id: 391,
        title: 'Minute Maid Orange',
        slug: 'minute_maid_orange',
        unit: ' 500 ml',
        price: 2.8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Juice is a drink made from the extraction or pressing of the natural liquid contained in fruit and vegetables. It can also refer to liquids that are flavored with concentrate or other biological food sources, such as meat or seafood, such as clam juice. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/minute_maid_orange.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/minute_maid_orange.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/minute_maid_orange.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/minute_maid_orange.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/minute_maid_orange.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 93,
                title: 'Juice',
                slug: 'juice',
            },
        ],
    },
    {
        id: 392,
        title: 'Nongmo Simple Apple',
        slug: 'nongmo_simply_apple',
        unit: ' 1 ltr',
        price: 2.8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Juice is a drink made from the extraction or pressing of the natural liquid contained in fruit and vegetables. It can also refer to liquids that are flavored with concentrate or other biological food sources, such as meat or seafood, such as clam juice. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nongmo_simply_apple.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nongmo_simply_apple.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nongmo_simply_apple.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nongmo_simply_apple.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nongmo_simply_apple.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 93,
                title: 'Juice',
                slug: 'juice',
            },
        ],
    },
    {
        id: 393,
        title: 'Tropicana Apple',
        slug: 'tropicana_apple',
        unit: ' 1 ltr',
        price: 2.4,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Juice is a drink made from the extraction or pressing of the natural liquid contained in fruit and vegetables. It can also refer to liquids that are flavored with concentrate or other biological food sources, such as meat or seafood, such as clam juice. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tropicana_apple.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tropicana_apple.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tropicana_apple.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tropicana_apple.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tropicana_apple.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 93,
                title: 'Juice',
                slug: 'juice',
            },
        ],
    },
    {
        id: 394,
        title: 'Tropicana Orange',
        slug: 'tropicana_orange_no_pulp',
        unit: ' 1.5 ltr',
        price: 2.5,
        salePrice: 2.25,
        discountInPercent: 10,
        description: 'Juice is a drink made from the extraction or pressing of the natural liquid contained in fruit and vegetables. It can also refer to liquids that are flavored with concentrate or other biological food sources, such as meat or seafood, such as clam juice. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tropicana_orange_no_pulp.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tropicana_orange_no_pulp.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tropicana_orange_no_pulp.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tropicana_orange_no_pulp.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/tropicana_orange_no_pulp.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 93,
                title: 'Juice',
                slug: 'juice',
            },
        ],
    },
    {
        id: 395,
        title: '7 Up Can',
        slug: '7up_lemon_lime_355ml',
        unit: ' 250 ml',
        price: 1,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/7up_lemon_lime_355ml.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/7up_lemon_lime_355ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/7up_lemon_lime_355ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/7up_lemon_lime_355ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/7up_lemon_lime_355ml.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 94,
                title: 'Fizzy Drinks',
                slug: 'fizzy_drinks',
            },
        ],
    },
    {
        id: 396,
        title: 'Coca Cola Botle',
        slug: 'Coca_Cola_200ml_Bottle',
        unit: ' 200 ml',
        price: 1,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Coca_Cola_200ml_Bottle.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Coca_Cola_200ml_Bottle.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Coca_Cola_200ml_Bottle.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Coca_Cola_200ml_Bottle.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Coca_Cola_200ml_Bottle.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 94,
                title: 'Fizzy Drinks',
                slug: 'fizzy_drinks',
            },
        ],
    },
    {
        id: 397,
        title: 'Coca Cola Zero',
        slug: 'coca_cola_zero',
        unit: ' .300 ml',
        price: 1,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coca_cola_zero.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coca_cola_zero.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coca_cola_zero.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coca_cola_zero.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coca_cola_zero.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 94,
                title: 'Fizzy Drinks',
                slug: 'fizzy_drinks',
            },
        ],
    },
    {
        id: 398,
        title: 'Diet Coke',
        slug: 'coke_diet_355ml',
        unit: ' 330 ml',
        price: 1.2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coke_diet_355ml.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coke_diet_355ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coke_diet_355ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coke_diet_355ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/coke_diet_355ml.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 94,
                title: 'Fizzy Drinks',
                slug: 'fizzy_drinks',
            },
        ],
    },
    {
        id: 399,
        title: 'Dr Pepper Diet',
        slug: 'dr_pepper_diet',
        unit: ' 330 ml',
        price: 1.2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dr_pepper_diet.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dr_pepper_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dr_pepper_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dr_pepper_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dr_pepper_diet.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 94,
                title: 'Fizzy Drinks',
                slug: 'fizzy_drinks',
            },
        ],
    },
    {
        id: 400,
        title: 'Fanta',
        slug: 'Fanta_orange_can_250ml',
        unit: ' 500 ml',
        price: 1.2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fanta_orange_can_250ml.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fanta_orange_can_250ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fanta_orange_can_250ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fanta_orange_can_250ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Fanta_orange_can_250ml.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 94,
                title: 'Fizzy Drinks',
                slug: 'fizzy_drinks',
            },
        ],
    },
    {
        id: 401,
        title: 'Diet Mountain Dew',
        slug: 'mountain_dew_diet',
        unit: ' 330 ml',
        price: 1.85,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mountain_dew_diet.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mountain_dew_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mountain_dew_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mountain_dew_diet.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/mountain_dew_diet.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 94,
                title: 'Fizzy Drinks',
                slug: 'fizzy_drinks',
            },
        ],
    },
    {
        id: 402,
        title: 'Aunt Jemina Original Syrup',
        slug: 'aunt_jemima_original_syrup',
        unit: ' 500 ml',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/aunt_jemima_original_syrup.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/aunt_jemima_original_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/aunt_jemima_original_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/aunt_jemima_original_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/aunt_jemima_original_syrup.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 95,
                title: 'Syrup Powder',
                slug: 'syrup_powder',
            },
        ],
    },
    {
        id: 402,
        title: 'Canadian Maple Syrup',
        slug: 'canadian_maple_syrup',
        unit: ' 500 ml',
        price: 5,
        salePrice: 40,
        discountInPercent: 20,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/canadian_maple_syrup.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/canadian_maple_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/canadian_maple_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/canadian_maple_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/canadian_maple_syrup.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 95,
                title: 'Syrup Powder',
                slug: 'syrup_powder',
            },
        ],
    },
    {
        id: 403,
        title: 'Canadian Maple Syrup',
        slug: 'canadian_maple_syrup',
        unit: ' 500 ml',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/canadian_maple_syrup.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/canadian_maple_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/canadian_maple_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/canadian_maple_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/canadian_maple_syrup.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 95,
                title: 'Syrup Powder',
                slug: 'syrup_powder',
            },
        ],
    },
    {
        id: 404,
        title: 'Gatorate Thirst Quencher Powder Drink',
        slug: 'gatorade_thirst_quencher_powder_packs_orange',
        unit: ' 250 ml',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gatorade_thirst_quencher_powder_packs_orange.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gatorade_thirst_quencher_powder_packs_orange.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gatorade_thirst_quencher_powder_packs_orange.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gatorade_thirst_quencher_powder_packs_orange.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gatorade_thirst_quencher_powder_packs_orange.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 95,
                title: 'Syrup Powder',
                slug: 'syrup_powder',
            },
        ],
    },
    {
        id: 405,
        title: 'Horlicks',
        slug: 'horlicks_classic_malt',
        unit: ' .5 lb',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/horlicks_classic_malt.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/horlicks_classic_malt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/horlicks_classic_malt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/horlicks_classic_malt.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/horlicks_classic_malt.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 95,
                title: 'Syrup Powder',
                slug: 'syrup_powder',
            },
        ],
    },
    {
        id: 406,
        title: 'Servivita Chocolate Syrup',
        slug: 'servivita_chocolate_syrup',
        unit: '500 ml',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A soft drink is a drink that usually contains carbonated water, a sweetener, and a natural or artificial flavoring. The sweetener may be a sugar, high-fructose corn syrup, fruit juice, a sugar substitute, or some combination of these.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/servivita_chocolate_syrup.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/servivita_chocolate_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/servivita_chocolate_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/servivita_chocolate_syrup.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/servivita_chocolate_syrup.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 95,
                title: 'Syrup Powder',
                slug: 'syrup_powder',
            },
        ],
    },
    {
        id: 407,
        title: 'Ava Juice Macha Tea',
        slug: 'ava_juice_Detox_Matcha',
        unit: ' .5 lb',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'Tea is an aromatic beverage commonly prepared by pouring hot or boiling water over cured leaves of the Camellia sinensis, an evergreen shrub native to East Asia. After water, it is the most widely consumed drink in the world.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/ava_juice_Detox_Matcha.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/ava_juice_Detox_Matcha.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/ava_juice_Detox_Matcha.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/ava_juice_Detox_Matcha.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/ava_juice_Detox_Matcha.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 96,
                title: 'Tea',
                slug: 'tea',
            },
        ],
    },
    {
        id: 408,
        title: 'Boh Instant Tea Mix',
        slug: 'boh_3in1_instant_tea_mix',
        unit: ' .5 lb',
        price: 5,
        salePrice: 4,
        discountInPercent: 20,
        description: 'Tea is an aromatic beverage commonly prepared by pouring hot or boiling water over cured leaves of the Camellia sinensis, an evergreen shrub native to East Asia. After water, it is the most widely consumed drink in the world.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/boh_3in1_instant_tea_mix.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/boh_3in1_instant_tea_mix.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/boh_3in1_instant_tea_mix.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/boh_3in1_instant_tea_mix.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/boh_3in1_instant_tea_mix.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 96,
                title: 'Tea',
                slug: 'tea',
            },
        ],
    },
    {
        id: 409,
        title: 'Crystal Light Lemon Iced Tea',
        slug: 'crystal_light_lemon_iced_tea',
        unit: ' .5 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Tea is an aromatic beverage commonly prepared by pouring hot or boiling water over cured leaves of the Camellia sinensis, an evergreen shrub native to East Asia. After water, it is the most widely consumed drink in the world.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crystal_light_lemon_iced_tea.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crystal_light_lemon_iced_tea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crystal_light_lemon_iced_tea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crystal_light_lemon_iced_tea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/crystal_light_lemon_iced_tea.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 96,
                title: 'Tea',
                slug: 'tea',
            },
        ],
    },
    {
        id: 410,
        title: 'Imperial Long Jing Tea',
        slug: 'imperial_tea_longjing_tea',
        unit: ' .5 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Tea is an aromatic beverage commonly prepared by pouring hot or boiling water over cured leaves of the Camellia sinensis, an evergreen shrub native to East Asia. After water, it is the most widely consumed drink in the world.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/imperial_tea_longjing_tea.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/imperial_tea_longjing_tea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/imperial_tea_longjing_tea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/imperial_tea_longjing_tea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/imperial_tea_longjing_tea.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 96,
                title: 'Tea',
                slug: 'tea',
            },
        ],
    },
    {
        id: 411,
        title: 'Luzianne Iced Tea',
        slug: 'luzianne_iced_tea',
        unit: ' .5 lb',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Tea is an aromatic beverage commonly prepared by pouring hot or boiling water over cured leaves of the Camellia sinensis, an evergreen shrub native to East Asia. After water, it is the most widely consumed drink in the world.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/luzianne_iced_tea.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/luzianne_iced_tea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/luzianne_iced_tea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/luzianne_iced_tea.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/luzianne_iced_tea.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 96,
                title: 'Tea',
                slug: 'tea',
            },
        ],
    },
    {
        id: 412,
        title: 'Elkali Natural Alkalike Srping Water',
        slug: 'Elkali_natural_alkaline_spring_water',
        unit: ' 5 ltr',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: "Water is a transparent, tasteless, odorless, and nearly colorless chemical substance, which is the main constituent of Earth's streams, lakes, and oceans, and the fluids of most living organisms. It is vital for all known forms of life, even though it provides no calories or organic nutrients.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Elkali_natural_alkaline_spring_water.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Elkali_natural_alkaline_spring_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Elkali_natural_alkaline_spring_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Elkali_natural_alkaline_spring_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Elkali_natural_alkaline_spring_water.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 97,
                title: 'Water',
                slug: 'water',
            },
        ],
    },
    {
        id: 413,
        title: 'Glaceau Smart Water',
        slug: 'glaceau_smart_water',
        unit: ' 2 ltr',
        price: 2.5,
        salePrice: 0,
        discountInPercent: 0,
        description: "Water is a transparent, tasteless, odorless, and nearly colorless chemical substance, which is the main constituent of Earth's streams, lakes, and oceans, and the fluids of most living organisms. It is vital for all known forms of life, even though it provides no calories or organic nutrients.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/glaceau_smart_water.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/glaceau_smart_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/glaceau_smart_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/glaceau_smart_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/glaceau_smart_water.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 97,
                title: 'Water',
                slug: 'water',
            },
        ],
    },
    {
        id: 414,
        title: 'Glastonbury Still Spring Water',
        slug: 'glastonbury_still_spring_water',
        unit: ' 2 ltr',
        price: 2.5,
        salePrice: 0,
        discountInPercent: 0,
        description: "Water is a transparent, tasteless, odorless, and nearly colorless chemical substance, which is the main constituent of Earth's streams, lakes, and oceans, and the fluids of most living organisms. It is vital for all known forms of life, even though it provides no calories or organic nutrients.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/glastonbury_still_spring_water.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/glastonbury_still_spring_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/glastonbury_still_spring_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/glastonbury_still_spring_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/glastonbury_still_spring_water.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 97,
                title: 'Water',
                slug: 'water',
            },
        ],
    },
    {
        id: 415,
        title: 'Poland Spring Water',
        slug: 'poland_spring_natural_spring_water',
        unit: ' 2 ltr',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: "Water is a transparent, tasteless, odorless, and nearly colorless chemical substance, which is the main constituent of Earth's streams, lakes, and oceans, and the fluids of most living organisms. It is vital for all known forms of life, even though it provides no calories or organic nutrients.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/poland_spring_natural_spring_water.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/poland_spring_natural_spring_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/poland_spring_natural_spring_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/poland_spring_natural_spring_water.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/poland_spring_natural_spring_water.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 97,
                title: 'Water',
                slug: 'water',
            },
        ],
    },
    {
        id: 416,
        title: 'Westfield Pure Spring Water',
        slug: 'westfield_pure_spring_water_300ml',
        unit: ' 2 ltr',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: "Water is a transparent, tasteless, odorless, and nearly colorless chemical substance, which is the main constituent of Earth's streams, lakes, and oceans, and the fluids of most living organisms. It is vital for all known forms of life, even though it provides no calories or organic nutrients.",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/westfield_pure_spring_water_300ml.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/westfield_pure_spring_water_300ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/westfield_pure_spring_water_300ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/westfield_pure_spring_water_300ml.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/westfield_pure_spring_water_300ml.jpg',
            },
        ],
        categories: [
            {
                id: 90,
                title: 'Beverage',
                slug: 'beverage',
            },
            {
                id: 97,
                title: 'Water',
                slug: 'water',
            },
        ],
    },
    {
        id: 417,
        title: 'Attitude Bubble Bath',
        slug: 'attitude_night_bubble_bath',
        unit: ' 200 ml',
        price: 20,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bath gel is a specialized liquid product used for cleaning the body during baths. Not to be confused with liquid soaps, bath gels, in fact, do not contain saponified oil. Instead, it uses synthetic detergents derived from either petroleum or plant sources.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/attitude_night_bubble_bath.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/attitude_night_bubble_bath.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/attitude_night_bubble_bath.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/attitude_night_bubble_bath.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/attitude_night_bubble_bath.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 99,
                title: 'Bath',
                slug: 'bath',
            },
        ],
    },
    {
        id: 418,
        title: 'Aveeno Baby Shampoo',
        slug: 'aveeno_baby_shampoo',
        unit: ' 200 ml',
        price: 20,
        salePrice: 10,
        discountInPercent: 50,
        description: 'Bath gel is a specialized liquid product used for cleaning the body during baths. Not to be confused with liquid soaps, bath gels, in fact, do not contain saponified oil. Instead, it uses synthetic detergents derived from either petroleum or plant sources.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/aveeno_baby_shampoo.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/aveeno_baby_shampoo.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/aveeno_baby_shampoo.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/aveeno_baby_shampoo.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/aveeno_baby_shampoo.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 99,
                title: 'Bath',
                slug: 'bath',
            },
        ],
    },
    {
        id: 419,
        title: 'Way Moisturizing Hair Bath',
        slug: 'moisturizing_hair_bath_g',
        unit: ' 200 ml',
        price: 20,
        salePrice: 16,
        discountInPercent: 20,
        description: 'Bath gel is a specialized liquid product used for cleaning the body during baths. Not to be confused with liquid soaps, bath gels, in fact, do not contain saponified oil. Instead, it uses synthetic detergents derived from either petroleum or plant sources.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/moisturizing_hair_bath_g.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/moisturizing_hair_bath_g.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/moisturizing_hair_bath_g.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/moisturizing_hair_bath_g.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/moisturizing_hair_bath_g.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 99,
                title: 'Bath',
                slug: 'bath',
            },
        ],
    },
    {
        id: 420,
        title: 'Neutrogena Body Oil',
        slug: 'neutrogena_Body_Oil',
        unit: '200 ml',
        price: 20,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bath gel is a specialized liquid product used for cleaning the body during baths. Not to be confused with liquid soaps, bath gels, in fact, do not contain saponified oil. Instead, it uses synthetic detergents derived from either petroleum or plant sources.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/neutrogena_Body_Oil.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/neutrogena_Body_Oil.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/neutrogena_Body_Oil.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/neutrogena_Body_Oil.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/neutrogena_Body_Oil.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 99,
                title: 'Bath',
                slug: 'bath',
            },
        ],
    },
    {
        id: 421,
        title: 'Eo Body Lotion',
        slug: 'eo_body_lotion',
        unit: ' 200 ml',
        price: 20,
        salePrice: 16,
        discountInPercent: 20,
        description: 'Bath gel is a specialized liquid product used for cleaning the body during baths. Not to be confused with liquid soaps, bath gels, in fact, do not contain saponified oil. Instead, it uses synthetic detergents derived from either petroleum or plant sources.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/eo_body_lotion.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/eo_body_lotion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/eo_body_lotion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/eo_body_lotion.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/eo_body_lotion.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 99,
                title: 'Bath',
                slug: 'bath',
            },
        ],
    },
    {
        id: 422,
        title: 'Gnc Aloe Vera Cream',
        slug: 'gnc_aloe_vera_moisturizing_cream',
        unit: ' 2 lb',
        price: 20,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bath gel is a specialized liquid product used for cleaning the body during baths. Not to be confused with liquid soaps, bath gels, in fact, do not contain saponified oil. Instead, it uses synthetic detergents derived from either petroleum or plant sources.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gnc_aloe_vera_moisturizing_cream.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gnc_aloe_vera_moisturizing_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gnc_aloe_vera_moisturizing_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gnc_aloe_vera_moisturizing_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gnc_aloe_vera_moisturizing_cream.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 100,
                title: 'Cream',
                slug: 'cream',
            },
        ],
    },
    {
        id: 423,
        title: 'Loreal Age Perfect Cream',
        slug: 'loreal_paris_age_perfect',
        unit: ' 2 lb',
        price: 20,
        salePrice: 15,
        discountInPercent: 25,
        description: 'Bath gel is a specialized liquid product used for cleaning the body during baths. Not to be confused with liquid soaps, bath gels, in fact, do not contain saponified oil. Instead, it uses synthetic detergents derived from either petroleum or plant sources.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/loreal_paris_age_perfect.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/loreal_paris_age_perfect.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/loreal_paris_age_perfect.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/loreal_paris_age_perfect.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/loreal_paris_age_perfect.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 100,
                title: 'Cream',
                slug: 'cream',
            },
        ],
    },
    {
        id: 424,
        title: 'Wasp Shiseido Cream',
        slug: 'waso_shiseido_mega_hydrating_cream',
        unit: ' 2 lb',
        price: 20,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bath gel is a specialized liquid product used for cleaning the body during baths. Not to be confused with liquid soaps, bath gels, in fact, do not contain saponified oil. Instead, it uses synthetic detergents derived from either petroleum or plant sources.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/waso_shiseido_mega_hydrating_cream.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/waso_shiseido_mega_hydrating_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/waso_shiseido_mega_hydrating_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/waso_shiseido_mega_hydrating_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/waso_shiseido_mega_hydrating_cream.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 100,
                title: 'Cream',
                slug: 'cream',
            },
        ],
    },
    {
        id: 425,
        title: 'Gnc Women S Progesterone Cream',
        slug: "gnc_women's_progesterone_cream",
        unit: ' 2 lb',
        price: 20,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Bath gel is a specialized liquid product used for cleaning the body during baths. Not to be confused with liquid soaps, bath gels, in fact, do not contain saponified oil. Instead, it uses synthetic detergents derived from either petroleum or plant sources.',
        type: 'grocery',
        image: "https://s3.amazonaws.com/redqteam.com/pickbazar/gnc_women's_progesterone_cream.jpg",
        gallery: [
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/gnc_women's_progesterone_cream.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/gnc_women's_progesterone_cream.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/gnc_women's_progesterone_cream.jpg",
            },
            {
                url: "https://s3.amazonaws.com/redqteam.com/pickbazar/gnc_women's_progesterone_cream.jpg",
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 100,
                title: 'Cream',
                slug: 'cream',
            },
        ],
    },
    {
        id: 426,
        title: 'Bionsen Deo Roll On',
        slug: 'bionsen_DEO_ROLL_ON',
        unit: ' 2 lb',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A deodorant is a substance applied to the body to prevent or mask (hide) body odor due to bacterial breakdown of perspiration in the armpits, groin, and feet, and in some cases vaginal secretions. A subclass of deodorants, called antiperspirants,',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bionsen_DEO_ROLL_ON.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bionsen_DEO_ROLL_ON.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bionsen_DEO_ROLL_ON.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bionsen_DEO_ROLL_ON.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/bionsen_DEO_ROLL_ON.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 101,
                title: 'Deodorant',
                slug: 'deodarent',
            },
        ],
    },
    {
        id: 427,
        title: 'Vichy Deodorant',
        slug: 'vichy_deodorant',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A deodorant is a substance applied to the body to prevent or mask (hide) body odor due to bacterial breakdown of perspiration in the armpits, groin, and feet, and in some cases vaginal secretions. A subclass of deodorants, called antiperspirants,',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/vichy_deodorant.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/vichy_deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/vichy_deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/vichy_deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/vichy_deodorant.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 101,
                title: 'Deodorant',
                slug: 'deodarent',
            },
        ],
    },
    {
        id: 428,
        title: 'Type A Deodorant',
        slug: 'type_A_ashley_graham_deodorant',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A deodorant is a substance applied to the body to prevent or mask (hide) body odor due to bacterial breakdown of perspiration in the armpits, groin, and feet, and in some cases vaginal secretions. A subclass of deodorants, called antiperspirants,',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/type_A_ashley_graham_deodorant.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/type_A_ashley_graham_deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/type_A_ashley_graham_deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/type_A_ashley_graham_deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/type_A_ashley_graham_deodorant.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 101,
                title: 'Deodorant',
                slug: 'deodarent',
            },
        ],
    },
    {
        id: 429,
        title: 'The Post Deodorant',
        slug: 'the_post_deodorant',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'A deodorant is a substance applied to the body to prevent or mask (hide) body odor due to bacterial breakdown of perspiration in the armpits, groin, and feet, and in some cases vaginal secretions. A subclass of deodorants, called antiperspirants,',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_post_deodorant.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_post_deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_post_deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_post_deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/the_post_deodorant.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 101,
                title: 'Deodorant',
                slug: 'deodarent',
            },
        ],
    },
    {
        id: 430,
        title: 'Baldini Deo',
        slug: 'taoasis_baldini_bio_deo_sauge_et_orange',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 1.8,
        discountInPercent: 10,
        description: 'A deodorant is a substance applied to the body to prevent or mask (hide) body odor due to bacterial breakdown of perspiration in the armpits, groin, and feet, and in some cases vaginal secretions. A subclass of deodorants, called antiperspirants,',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taoasis_baldini_bio_deo_sauge_et_orange.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taoasis_baldini_bio_deo_sauge_et_orange.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taoasis_baldini_bio_deo_sauge_et_orange.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taoasis_baldini_bio_deo_sauge_et_orange.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/taoasis_baldini_bio_deo_sauge_et_orange.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 101,
                title: 'Deodorant',
                slug: 'deodarent',
            },
        ],
    },
    {
        id: 431,
        title: 'Spadet Deodorant',
        slug: 'spadet_Deodorant',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 1.8,
        discountInPercent: 10,
        description: 'A deodorant is a substance applied to the body to prevent or mask (hide) body odor due to bacterial breakdown of perspiration in the armpits, groin, and feet, and in some cases vaginal secretions. A subclass of deodorants, called antiperspirants,',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/spadet_Deodorant.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/spadet_Deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/spadet_Deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/spadet_Deodorant.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/spadet_Deodorant.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 101,
                title: 'Deodorant',
                slug: 'deodarent',
            },
        ],
    },
    {
        id: 432,
        title: 'Face Replublic Sun Gel',
        slug: 'face_republic_Sun_gel',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The term cleanser refers to a product that cleans or removes dirt or other substances. A cleanser could be a detergent, and there are many types of cleansers that are produced with a specific objective or focus.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/face_republic_Sun_gel.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/face_republic_Sun_gel.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/face_republic_Sun_gel.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/face_republic_Sun_gel.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/face_republic_Sun_gel.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 102,
                title: 'Face Care',
                slug: 'face_care',
            },
        ],
    },
    {
        id: 433,
        title: 'Frei Ol',
        slug: 'frei_ol_urea2in1',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 1.8,
        discountInPercent: 10,
        description: 'The term cleanser refers to a product that cleans or removes dirt or other substances. A cleanser could be a detergent, and there are many types of cleansers that are produced with a specific objective or focus.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/frei_ol_urea2in1.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/frei_ol_urea2in1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/frei_ol_urea2in1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/frei_ol_urea2in1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/frei_ol_urea2in1.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 102,
                title: 'Face Care',
                slug: 'face_care',
            },
        ],
    },
    {
        id: 434,
        title: 'Garnier Pure Active',
        slug: 'garnier_pure_active',
        unit: ' 1 pc(s)',
        price: 2,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The term cleanser refers to a product that cleans or removes dirt or other substances. A cleanser could be a detergent, and there are many types of cleansers that are produced with a specific objective or focus.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/garnier_pure_active.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/garnier_pure_active.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/garnier_pure_active.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/garnier_pure_active.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/garnier_pure_active.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 102,
                title: 'Face Care',
                slug: 'face_care',
            },
        ],
    },
    {
        id: 435,
        title: 'Nuace Men Facial Wash',
        slug: 'nuage_men_facial_wash',
        unit: ' 1 pc(s)',
        price: 3,
        salePrice: 0,
        discountInPercent: 0,
        description: 'The term cleanser refers to a product that cleans or removes dirt or other substances. A cleanser could be a detergent, and there are many types of cleansers that are produced with a specific objective or focus.',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nuage_men_facial_wash.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nuage_men_facial_wash.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nuage_men_facial_wash.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nuage_men_facial_wash.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nuage_men_facial_wash.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 102,
                title: 'Face Care',
                slug: 'face_care',
            },
        ],
    },
    {
        id: 436,
        title: 'Absolute Plus Meat Dental Set',
        slug: 'absolute_plus_Meat_Dental_Set_',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: "Oral hygiene is the practice of keeping one's mouth clean and free of disease and other problems (e.g. bad breath) by regular brushing of the teeth (dental hygiene) and cleaning between the teeth. It is important that oral hygiene be carried out on a regular basis to enable prevention of dental disease and bad breath. ",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/absolute_plus_Meat_Dental_Set_.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/absolute_plus_Meat_Dental_Set_.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/absolute_plus_Meat_Dental_Set_.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/absolute_plus_Meat_Dental_Set_.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/absolute_plus_Meat_Dental_Set_.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 105,
                title: 'Oral Care',
                slug: 'Oral_care',
            },
        ],
    },
    {
        id: 437,
        title: 'Dentek Oral Care Kit',
        slug: 'dentek_professional_oral_care_kit1',
        unit: ' 1 pc(s)',
        price: 10,
        salePrice: 0,
        discountInPercent: 0,
        description: "Oral hygiene is the practice of keeping one's mouth clean and free of disease and other problems (e.g. bad breath) by regular brushing of the teeth (dental hygiene) and cleaning between the teeth. It is important that oral hygiene be carried out on a regular basis to enable prevention of dental disease and bad breath. ",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dentek_professional_oral_care_kit1.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dentek_professional_oral_care_kit1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dentek_professional_oral_care_kit1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dentek_professional_oral_care_kit1.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/dentek_professional_oral_care_kit1.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 105,
                title: 'Oral Care',
                slug: 'Oral_care',
            },
        ],
    },
    {
        id: 438,
        title: 'Gum Oral Care Cleaning Kit',
        slug: 'gum_oral_care_cleaning_kit',
        unit: ' 1 pc(s)',
        price: 15,
        salePrice: 0,
        discountInPercent: 0,
        description: "Oral hygiene is the practice of keeping one's mouth clean and free of disease and other problems (e.g. bad breath) by regular brushing of the teeth (dental hygiene) and cleaning between the teeth. It is important that oral hygiene be carried out on a regular basis to enable prevention of dental disease and bad breath. ",
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gum_oral_care_cleaning_kit.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gum_oral_care_cleaning_kit.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gum_oral_care_cleaning_kit.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gum_oral_care_cleaning_kit.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/gum_oral_care_cleaning_kit.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 105,
                title: 'Oral Care',
                slug: 'Oral_care',
            },
        ],
    },
    {
        id: 439,
        title: 'Duke Cannon Shaving Cream',
        slug: 'Duke_Cannon_SHAVE_CREAM',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Shaving is the removal of hair, by using a razor or any other kind of bladed implement, to slice it down—to the level of the skin or otherwise. Shaving is most commonly practiced by men to remove their facial hair and by women to remove their leg and underarm hair. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Duke_Cannon_SHAVE_CREAM.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Duke_Cannon_SHAVE_CREAM.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Duke_Cannon_SHAVE_CREAM.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Duke_Cannon_SHAVE_CREAM.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Duke_Cannon_SHAVE_CREAM.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 106,
                title: 'Shaving Needs',
                slug: 'shaving_needs',
            },
        ],
    },
    {
        id: 440,
        title: 'Nair Hair Removal Cream',
        slug: 'nair_hair_remover',
        unit: ' 1 pc(s)',
        price: 8,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Shaving is the removal of hair, by using a razor or any other kind of bladed implement, to slice it down—to the level of the skin or otherwise. Shaving is most commonly practiced by men to remove their facial hair and by women to remove their leg and underarm hair. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nair_hair_remover.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nair_hair_remover.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nair_hair_remover.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nair_hair_remover.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/nair_hair_remover.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 106,
                title: 'Shaving Needs',
                slug: 'shaving_needs',
            },
        ],
    },
    {
        id: 441,
        title: 'Longmarket Barber Aftershave Balm',
        slug: 'Longmarket_Barber_Aftershave_Balm',
        unit: ' 1 pc(s)',
        price: 6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Shaving is the removal of hair, by using a razor or any other kind of bladed implement, to slice it down—to the level of the skin or otherwise. Shaving is most commonly practiced by men to remove their facial hair and by women to remove their leg and underarm hair. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Longmarket_Barber_Aftershave_Balm.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Longmarket_Barber_Aftershave_Balm.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Longmarket_Barber_Aftershave_Balm.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Longmarket_Barber_Aftershave_Balm.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Longmarket_Barber_Aftershave_Balm.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 106,
                title: 'Shaving Needs',
                slug: 'shaving_needs',
            },
        ],
    },
    {
        id: 442,
        title: 'Espa Men Dual Action',
        slug: 'espa_men_dual_action_shave_mud',
        unit: ' 1 pc(s)',
        price: 6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Shaving is the removal of hair, by using a razor or any other kind of bladed implement, to slice it down—to the level of the skin or otherwise. Shaving is most commonly practiced by men to remove their facial hair and by women to remove their leg and underarm hair. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/espa_men_dual_action_shave_mud.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/espa_men_dual_action_shave_mud.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/espa_men_dual_action_shave_mud.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/espa_men_dual_action_shave_mud.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/espa_men_dual_action_shave_mud.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 106,
                title: 'Shaving Needs',
                slug: 'shaving_needs',
            },
        ],
    },
    {
        id: 443,
        title: 'Human Nature Shaving Cream',
        slug: 'human_nature_shaving_cream',
        unit: ' 1 pc(s)',
        price: 6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Shaving is the removal of hair, by using a razor or any other kind of bladed implement, to slice it down—to the level of the skin or otherwise. Shaving is most commonly practiced by men to remove their facial hair and by women to remove their leg and underarm hair. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/human_nature_shaving_cream.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/human_nature_shaving_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/human_nature_shaving_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/human_nature_shaving_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/human_nature_shaving_cream.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 106,
                title: 'Shaving Needs',
                slug: 'shaving_needs',
            },
        ],
    },
    {
        id: 444,
        title: 'Pacific Natural Shaving Cream',
        slug: "harry'pacific_shaving_co._natural_shaving_cream",
        unit: ' 1 pc(s)',
        price: 6,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Shaving is the removal of hair, by using a razor or any other kind of bladed implement, to slice it down—to the level of the skin or otherwise. Shaving is most commonly practiced by men to remove their facial hair and by women to remove their leg and underarm hair. ',
        type: 'grocery',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pacific_shaving_co._natural_shaving_cream.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pacific_shaving_co._natural_shaving_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pacific_shaving_co._natural_shaving_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pacific_shaving_co._natural_shaving_cream.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/pacific_shaving_co._natural_shaving_cream.jpg',
            },
        ],
        categories: [
            {
                id: 98,
                title: 'Health Beauty',
                slug: 'health_beauty',
            },
            {
                id: 106,
                title: 'Shaving Needs',
                slug: 'shaving_needs',
            },
        ],
    },
    {
        id: 445,
        title: 'Duke Cannon Shaving Cream',
        slug: 'Duke_Cannon_SHAVE_CREAM',
        unit: ' 1 pc(s)',
        price: 5,
        salePrice: 0,
        discountInPercent: 0,
        description: 'Shaving is the removal of hair, by using a razor or any other kind of bladed implement, to slice it down—to the level of the skin or otherwise. Shaving is most commonly practiced by men to remove their facial hair and by women to remove their leg and underarm hair. ',
        type: 'makeup',
        image: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Duke_Cannon_SHAVE_CREAM.jpg',
        gallery: [
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Duke_Cannon_SHAVE_CREAM.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Duke_Cannon_SHAVE_CREAM.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Duke_Cannon_SHAVE_CREAM.jpg',
            },
            {
                url: 'https://s3.amazonaws.com/redqteam.com/pickbazar/Duke_Cannon_SHAVE_CREAM.jpg',
            },
        ],
        categories: [
            {
                id: 107,
                title: 'Shaving Needs',
                slug: 'shaving_needs',
            },
        ],
    },
]
