export const categories = [
    {
        id: 1,
        title: 'Grocery',
        slug: '',
        products: [],
        type: 'grocery',
        icon: 'FruitsVegetable',
        children: [
            {
                id: 2,
                title: 'Fruits',
                slug: 'fruits',
                products: [],
                type: 'grocery',
            },
            {
                id: 3,
                title: 'vegetables',
                slug: 'vegetables',
                products: [],
                type: 'grocery',
            },
            {
                id: 48,
                title: 'Snacks',
                slug: 'snacks',
                products: [],
                type: 'grocery',
            },
            {
                id: 49,
                title: 'Biscuits',
                slug: 'biscuits',
                products: [],
                type: 'grocery',
            },
            {
                id: 55,
                title: 'Sauce',
                slug: 'sauce',
                products: [],
                type: 'grocery',
            },
            {
                id: 76,
                title: 'Cooking',
                slug: 'cooking',
                products: [],
                type: 'grocery',
            },
            {
                id: 78,
                title: 'Rice',
                slug: 'rice',
                products: [],
                type: 'grocery',
            },
        ],
    },
    {
        id: 2,
        title: 'Fruits',
        slug: 'fruits',
        products: [],
        type: 'grocery',
    },
    {
        id: 3,
        title: 'vegetables',
        slug: 'vegetables',
        products: [],
        type: 'grocery',
    },
    {
        id: 48,
        title: 'Snacks',
        slug: 'snacks',
        products: [],
        type: 'grocery',
    },
    {
        id: 49,
        title: 'Biscuits',
        slug: 'biscuits',
        products: [],
        type: 'grocery',
    },
    {
        id: 55,
        title: 'Sauce',
        slug: 'sauce',
        products: [],
        type: 'grocery',
    },
    {
        id: 76,
        title: 'Cooking',
        slug: 'cooking',
        products: [],
        type: 'grocery',
    },
    {
        id: 78,
        title: 'Rice',
        slug: 'rice',
        products: [],
        type: 'grocery',
    },
    // {
    //     id: 9,
    //     title: 'Meat & Fish',
    //     slug: 'meat-and-fish',
    //     products: [],
    //     type: 'grocery',
    //     icon: 'MeatFish',
    //     children: [
    //         {
    //             id: 12,
    //             title: 'Fresh Fish',
    //             slug: 'fresh-fish',
    //             products: [],
    //             type: 'grocery',
    //         },
    //         {
    //             id: 13,
    //             title: 'Meat',
    //             slug: 'meat',
    //             products: [],
    //             type: 'grocery',
    //         },
    //     ]
    // },
];
