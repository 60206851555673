import React from 'react';
export const HomeCleaning = ({
  color = 'currentColor',
  width = '18px',
  height = '18px',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 18 18'
    >
      <g transform='translate(-1.125 -1.125)' fill={color}>
        <path data-name='Path 7' d='M1.125 18.54H1.7v.58h-.58z' />
        <path data-name='Path 8' d='M18.54 18.54h.58v.58h-.58z' />
        <circle
          data-name='Ellipse 1'
          cx='.489'
          cy='.489'
          r='.489'
          transform='translate(14.28 9.345)'
        />
        <path
          data-name='Path 9'
          d='M9.834 10.968V9.543H8.409a1.744 1.744 0 001.425 1.425z'
        />
        <circle
          data-name='Ellipse 2'
          cx='.489'
          cy='.489'
          r='.489'
          transform='translate(4.99 12.247)'
        />
        <path
          data-name='Path 10'
          d='M9.834 7.537a1.744 1.744 0 00-1.425 1.425h1.425z'
        />
        <path data-name='Path 11' d='M2.867 15.64h3.486v.58H2.867z' />
        <path data-name='Path 12' d='M13.898 15.64h3.491v.58h-3.491z' />
        <path
          data-name='Path 13'
          d='M16.802 16.801H14.48v1.742h-.58v-1.742h-.58v-1.742h3.486v-6.66l-6.68-2.637-6.678 2.637v6.66h3.486v1.742h-.583v1.742h-.578v-1.742H3.448v1.742H2.286v.58h15.677v-.58h-1.161zm-3.486-7.257a1.163 1.163 0 001.164-1.165h.58a1.163 1.163 0 001.162 1.162v.58a1.163 1.163 0 00-1.159 1.162h-.58a1.163 1.163 0 00-1.162-1.162zm-3.191-2.613a2.322 2.322 0 11-2.328 2.322 2.322 2.322 0 012.328-2.322zm-4.352 7.258h-.584a1.163 1.163 0 00-1.162-1.162v-.58a1.163 1.163 0 001.162-1.162h.584a1.163 1.163 0 001.158 1.162v.58a1.163 1.163 0 00-1.158 1.162zm6.39 4.354v-3.77a2.032 2.032 0 10-4.064 0v3.77h-.584v-3.77a2.613 2.613 0 115.225 0v3.77zm-3.49-2.322v-.58h.59v.58z'
        />
        <path
          data-name='Path 14'
          d='M11.839 9.543h-1.425v1.425a1.744 1.744 0 001.425-1.425z'
        />
        <path
          data-name='Path 15'
          d='M18.049 6.598l-7.925-3.13-7.925 3.13a.772.772 0 00-.494.722.76.76 0 00.337.637.787.787 0 00.742.082l7.337-2.9 7.337 2.9a.787.787 0 00.742-.082.76.76 0 00.337-.637.772.772 0 00-.494-.719z'
        />
        <path
          data-name='Path 16'
          d='M10.414 7.537v1.425h1.425a1.744 1.744 0 00-1.425-1.425z'
        />
        <path
          data-name='Path 17'
          d='M17.962 2.286h-.58a1.163 1.163 0 01-1.162 1.162v.58a1.163 1.163 0 011.162 1.162h.58a1.163 1.163 0 011.162-1.162v-.58a1.163 1.163 0 01-1.162-1.162z'
        />
        <path
          data-name='Path 18'
          d='M4.028 4.027h.58a1.163 1.163 0 011.161-1.161v-.58a1.163 1.163 0 01-1.16-1.161h-.58a1.163 1.163 0 01-1.162 1.161v.58a1.163 1.163 0 011.161 1.161z'
        />
      </g>
    </svg>
  );
};
