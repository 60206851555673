import React from 'react';
// import Grocery from './Grocery';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../siteSettings/siteTheme/default';
import { AppProvider } from '../contexts/app/app.provider';
import { AuthProvider } from '../contexts/auth/auth.provider';
import { LanguageProvider } from '../contexts/language/language.provider';
import { CartProvider } from '../contexts/cart/use-cart';
import { useMedia } from '../utils/useMedia';
import AppLayout from './Layout';

import CategoryPage from './CategoryPage';

import 'rc-drawer/assets/index.css';
import 'rc-table/assets/index.css';
import 'rc-collapse/assets/index.css';
import 'react-multi-carousel/lib/styles.css';
import './multiCarousel/multi-carousel.style.css';
import '@redq/reuse-modal/lib/index.css';
import { GlobalStyle } from '../assets/styles/global.style';

import { messages } from '../siteSettings/siteTranslation/messages';
import 'typeface-lato';
import 'typeface-poppins';

function App() {
  const mobile = useMedia('(max-width: 580px)');
  const tablet = useMedia('(max-width: 991px)');
  const desktop = useMedia('(min-width: 992px)');

  return (
    <ThemeProvider theme={defaultTheme}>
      <LanguageProvider messages={messages}>
        <CartProvider>
          <AppProvider>
            <AuthProvider>
              <AppLayout>
                <CategoryPage deviceType={{ mobile, tablet, desktop }} />
              </AppLayout>
              <GlobalStyle />
            </AuthProvider>
          </AppProvider>
        </CartProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
