import React from 'react';
import { Button } from '../../Button';
import { FormattedMessage } from 'react-intl';
import Popover from '../../PopOver';
import { AuthorizedMenu } from './AuthorizerdMenu';

// interface Props {
//   isAuthenticated: boolean;
//   onJoin: () => void;
//   onLogout: () => void;
//   avatar: string;
// }

const AuthMenu = ({ isAuthenticated, onJoin, onLogout, avatar }) => {
  return !isAuthenticated ? (
    <Button variant="primary" onClick={onJoin}>
      <FormattedMessage id="joinButton" defaultMessage="join" />
    </Button>
  ) : (
    <Popover
      direction="right"
      className="user-pages-dropdown"
      handler={<img src={avatar} alt="user" />}
      content={<AuthorizedMenu onLogout={onLogout} />}
    />
  );
};
export default AuthMenu;
