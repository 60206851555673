import React from 'react';
// import { useRouter } from 'next/router';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// import { useQuery } from '@apollo/client';
import Sticky from 'react-stickynode';
import { Scrollbars } from 'react-custom-scrollbars';
import Popover from '../../components/PopOver';
import { ArrowDropDown } from '../../assets/icons/ArrowDropDown';
import { CategoryIcon } from '../../assets/icons/CategoryIcon';
import { useLocale } from '../../contexts/language/language.provider';
import { useAppState } from '../../contexts/app/app.provider';
import {
  SidebarMobileLoader,
  SidebarLoader,
} from '../PlaceHolder';
import {
  CategoryWrapper,
  TreeWrapper,
  PopoverHandler,
  PopoverWrapper,
  SidebarWrapper,
  RequestMedicine,
} from './sidebar.style';

import { TreeMenu } from '../treeMenu';

// import { GET_CATEGORIES } from 'graphql/query/category.query';

import { REQUEST_MEDICINE_MENU_ITEM } from '../../siteSettings/siteNavigation';
import { categories } from "../../products/categories";

// type SidebarCategoryProps = {
//   deviceType: {
//     mobile: string;
//     tablet: string;
//     desktop: boolean;
//   };
//   type: string;
// };

const SidebarCategory = ({
  deviceType: { mobile, tablet, desktop },
  type,
}) => {
  //   const router = useRouter();
  // const { data, loading } = useQuery(GET_CATEGORIES, {
  //   variables: { type },
  // });
  //   const { pathname, query } = router;
  //   const selectedQueries = query.category;
  const history = useHistory();
  const selectedQueries = 'category';

  const { isRtl } = useLocale();

  const onCategoryClick = (slug) => {
    history.push({
      pathname: '/',
      search: `?category=${slug}`
    })
    // const { type, ...rest } = query;
    // if (type) {
    //   router.push(
    //     {
    //       pathname,
    //       query: { ...rest, category: slug },
    //     },
    //     {
    //       pathname: `/${type}`,
    //       query: { ...rest, category: slug },
    //     }
    //   );
    // } else {
    //   router.push({
    //     pathname,
    //     query: { ...rest, category: slug },
    //   });
    // }
  };
  const isSidebarSticky = useAppState('isSidebarSticky');

  if (!categories) {
    if (mobile || tablet) {
      return <SidebarMobileLoader />;
    }
    return <SidebarLoader />;
  }
  return (
    <CategoryWrapper>
      <PopoverWrapper>
        <Popover
          handler={
            <PopoverHandler>
              <div>
                <CategoryIcon />
                Select your Category
              </div>
              <div>
                <ArrowDropDown />
              </div>
            </PopoverHandler>
          }
          className="category-popover"
          content={
            <>
              {type === 'medicine' && (
                <Link href={REQUEST_MEDICINE_MENU_ITEM.href}>
                  <RequestMedicine>
                    <FormattedMessage
                      id={REQUEST_MEDICINE_MENU_ITEM.id}
                      defaultMessage={REQUEST_MEDICINE_MENU_ITEM.defaultMessage}
                    />
                  </RequestMedicine>
                </Link>
              )}
              <TreeMenu
                data={categories}
                onClick={onCategoryClick}
                active={selectedQueries}
              />
            </>
          }
        />
      </PopoverWrapper>

      <SidebarWrapper style={{ paddingTop: type === 'medicine' ? 0 : 45 }}>
        <Sticky enabled={isSidebarSticky} top={type === 'medicine' ? 89 : 110}>
          {type === 'medicine' && (
            <Link href={REQUEST_MEDICINE_MENU_ITEM.href}>
              <RequestMedicine>
                <FormattedMessage
                  id={REQUEST_MEDICINE_MENU_ITEM.id}
                  defaultMessage={REQUEST_MEDICINE_MENU_ITEM.defaultMessage}
                />
              </RequestMedicine>
            </Link>
          )}

          <Scrollbars
            universal
            autoHide
            autoHeight
            autoHeightMax={688}
            renderView={(props) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  marginLeft: isRtl ? props.style.marginRight : 0,
                  marginRight: isRtl ? 0 : props.style.marginRight,
                }}
              />
            )}
          >
            <TreeWrapper>
              <TreeMenu
                data={categories}
                onClick={onCategoryClick}
                active={selectedQueries}
              />
            </TreeWrapper>
          </Scrollbars>
        </Sticky>
      </SidebarWrapper>
    </CategoryWrapper>
  );
};

export default SidebarCategory;

