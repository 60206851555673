import React from 'react';
export const Facebook = ({
  color = 'currentColor',
  width = '17px',
  height = '17px',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 17 17'
    >
      <path
        data-name='_ionicons_svg_logo-facebook (1)'
        d='M80.061,64H64.939a.939.939,0,0,0-.939.939V80.061a.939.939,0,0,0,.939.939H72.5V74.271H70.468V71.792H72.5V69.959a3.23,3.23,0,0,1,3.484-3.391c.939,0,1.948.071,2.183.1v2.293H76.6c-1.067,0-1.271.5-1.271,1.248v1.58h2.541l-.332,2.479H75.333V81h4.728A.939.939,0,0,0,81,80.061V64.939A.939.939,0,0,0,80.061,64Z'
        transform='translate(-64 -64)'
        fill={color}
      />
    </svg>
  );
};
