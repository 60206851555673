import React from 'react';
// import { GetStaticProps } from 'next';
// import dynamic from 'next/dynamic';
// import { useRouter } from 'next/router';
import {Modal} from '@redq/reuse-modal';
import StoreNav from '../StoreNav';
import Carousel from '../Carousel';
import {Banner} from '../Banner';
import {
  MainContentArea,
  SidebarSection,
  ContentSection,
  OfferSection,
  MobileCarouselDropdown,
} from '../../assets/styles/pages.style';
// Static Data Import Here
import {siteOffers} from '../../siteSettings/siteOffers';
import {sitePages} from '../../siteSettings/sitePages';
import {SEO} from '../SEO';
import {useRefScroll} from '../../utils/useRefScroll';
// import { initializeApollo } from 'utils/apollo';
// import { GET_PRODUCTS } from 'graphql/query/products.query';
// import { GET_CATEGORIES } from 'graphql/query/category.query';
import {CATEGORY_MENU_ITEMS} from '../../siteSettings/siteNavigation';
// const Sidebar = dynamic(() => import('layouts/sidebar/sidebar'));
import Sidebar from '../SideBar';
// const Products = dynamic(() =>
//   import('components/product-grid/product-list/product-list')
// );
import Products from '../ProductList';
// const CartPopUp = dynamic(() => import('features/carts/cart-popup'), {
//   ssr: false,
// });

const MyContext = React.createContext();
const CategoryPage = ({deviceType}) => {
//   const { query } = useRouter();
  const {elRef: targetRef, scroll} = useRefScroll({
    percentOfElement: 0,
    percentOfContainer: 0,
    offsetPX: -110,
  });
//   React.useEffect(() => {
//     if (query.text || query.category) {
//       scroll();
//     }
//   }, [query.text, query.category]);
  const PAGE_TYPE = 'grocery';
  const page = sitePages[PAGE_TYPE];
  // let searchedValue = '';
  let [searchedValue, changeSearchedValue] = React.useState('');
  // let searchedValue  = '';
  // const [password, setPassword] = React.useState('');
  const onSubmit = (value) => {
    changeSearchedValue(value);
  }

  return (
      <>
        <SEO title={page?.page_title} description={page?.page_description}/>

        <Modal>
          <Banner
              onSubmit={onSubmit}
              intlTitleId={page?.banner_title_id}
              intlDescriptionId={page?.banner_description_id}
              imageUrl={page?.banner_image_url}
          />
          <MobileCarouselDropdown>
            <StoreNav items={CATEGORY_MENU_ITEMS}/>
            <Sidebar type={PAGE_TYPE} deviceType={deviceType}/>
          </MobileCarouselDropdown>
          <OfferSection>
            <div style={{margin: '0 -10px'}}>
              <Carousel deviceType={deviceType} data={siteOffers}/>
            </div>
          </OfferSection>
          <MainContentArea>
            <SidebarSection>
              <Sidebar type={PAGE_TYPE} deviceType={deviceType}/>
            </SidebarSection>
            <ContentSection>
              <div ref={targetRef}>
                <Products searchedValue={searchedValue}
                          type={PAGE_TYPE}
                          deviceType={deviceType}
                          fetchLimit={20}
                />
              </div>
            </ContentSection>
          </MainContentArea>
          {/* <CartPopUp deviceType={deviceType} /> */}
        </Modal>
      </>
  );
};
export const getStaticProps = async ({params}) => {
  // const apolloClient = initializeApollo();
  // await apolloClient.query({
  //   query: GET_PRODUCTS,
  //   variables: {
  //     type: params.type,
  //     offset: 0,
  //     limit: 20,
  //   },
  // });
  // await apolloClient.query({
  //   query: GET_CATEGORIES,
  //   variables: {
  //     type: params.type,
  //   },
  // });

  return {
    props: {
      // initialApolloState: apolloClient.cache.extract(),
    },
    revalidate: 1,
  };
};

export async function getStaticPaths() {
  return {
    paths: [
      {params: {type: 'grocery'}},
      {params: {type: 'makeup'}},
      {params: {type: 'bags'}},
      {params: {type: 'book'}},
      {params: {type: 'medicine'}},
      {params: {type: 'furniture'}},
      {params: {type: 'clothing'}},
    ],
    fallback: false,
  };
}

export default CategoryPage;
