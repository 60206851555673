import React from 'react';
import {SearchBox} from '../../components/SearchBox';
import {useAppState, useAppDispatch} from '../../contexts/app/app.provider';
// import { useRouter } from 'next/router';
import {useIntl} from 'react-intl';

// interface Props {
//   minimal?: boolean;
//   showButtonText?: boolean;
//   onSubmit?: () => void;
//   [key: string]: unknown;
// }

const Search = ({onSubmit, ...props}) => {
  const searchTerm = useAppState('searchTerm');
  // const searchTerm = '';
  const dispatch = useAppDispatch();
  // const router = useRouter();
  const intl = useIntl();

  const handleOnChange = (e) => {
    const {value} = e.target;
    dispatch({type: 'SET_SEARCH_TERM', payload: value});
    if (onSubmit && !e.target) {
      onSubmit(searchTerm);
    }
  };
  // const { pathname, query } = router;
  const query = {type: 'restaurant'}
  const onSearch = (e) => {
    if (onSubmit) {
      onSubmit(searchTerm);
    }
    e.preventDefault();
    // const { type, ...rest } = query;
    // if (type) {
    // router.push(
    //   {
    //     pathname,
    //     query: { ...rest, text: searchTerm },
    //   },
    //   {
    //     pathname: `/${type}`,
    //     query: { ...rest, text: searchTerm },
    //   }
    // );
    // } else {
    // router.push({
    //   pathname,
    //   query: { ...rest, text: searchTerm },
    // });
    // }
    // dispatch({ type: 'SET_SEARCH_TERM', payload: '' });
  };
  return (
      <SearchBox
          onEnter={onSearch}
          onChange={handleOnChange}
          value={searchTerm}
          name="search"
          placeholder={intl.formatMessage({
            id: 'searchPlaceholder',
            defaultMessage: 'Search your products from here',
          })}
          categoryType={query.type || 'grocery'}
          buttonText={intl.formatMessage({
            id: 'searchButtonText',
            defaultMessage: 'Search',
          })}
          {...props}
      />
  );
};

export default Search;
